// extracted by mini-css-extract-plugin
export var avatar = "OutcomeIcon-module--avatar--ed6f1";
export var blue = "#0eb3f2";
export var emphasis = "OutcomeIcon-module--emphasis--2f3cd";
export var green = "#57d33b";
export var greenLink = "OutcomeIcon-module--green-link--bcff0";
export var grey = "var(--tone-mid)";
export var icon = "OutcomeIcon-module--icon--a15b5";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";