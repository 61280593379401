import cx from "classnames";
import type { EventStatusProps } from "components/layout/EventStatus";
import { EventStatus } from "components/layout/EventStatus";
import type {
  ClampShape,
  ClampVariant,
} from "components/layout/EventStatus/EventStatus";
import type { StreamButtonProps } from "components/layout/StreamButton";
import { StreamButton } from "components/layout/StreamButton";
import { useCountdown } from "hooks/useCountdown";
import React from "react";
import type { StreamType } from "types/ContentTypes";

import * as styles from "./StatusWithStream.module.scss";

type StatusWithStreamProps = {
  clampShape?: ClampShape;
  clampVariant?: ClampVariant;
  className?: string;
  shouldHideStream?: boolean;
} & EventStatusProps &
  Omit<StreamButtonProps<StreamType>, "startTime" | "status">;

function StatusWithStream({
  title,
  url,
  sport,
  scheduledStartTime,
  status,
  eventId,
  isPlaceholder,
  clampShape,
  clampVariant,
  className,
  shouldHideStream = false,
}: StatusWithStreamProps) {
  const [_, secondsToNow] = useCountdown(scheduledStartTime);
  const earlyBirdStream = secondsToNow <= 30 * 60; // 30 mins
  return (
    <div className={cx(styles.container, className)}>
      {url && earlyBirdStream && !shouldHideStream && (
        <StreamButton
          title={title}
          sport={sport}
          url={url}
          startTime={scheduledStartTime}
          status={status}
          eventId={eventId}
          isPlaceholder={isPlaceholder}
        />
      )}
      <EventStatus
        status={status}
        shape={clampShape}
        variant={clampVariant}
        scheduledStartTime={scheduledStartTime}
      />
    </div>
  );
}

export { StatusWithStream };
