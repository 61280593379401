import { LinkButton } from "components/LinkButton";
import { PageMessage } from "components/PageMessage";
import { useUserEventFilters } from "hooks";
import React, { type PropsWithChildren } from "react";
import { ReactComponent as ErrorIcon } from "components/assets/account-error.svg";
import { useHomeUrl } from "hooks/firestore/v2/user/usePreferences";

type Props = {
  filters: string[];
  loading?: boolean;
};

export const userCanViewEvent = (
  filters: string[], // List of required filters for the event
  userEventFilters: string[], // List of filters that the user has
) => {
  if (!userEventFilters || userEventFilters.length === 0) return true;
  return filters?.some((filter) => userEventFilters?.includes(filter));
};

export default ({
  children,
  filters,
  loading = false,
}: PropsWithChildren<Props>) => {
  const userEventFilters = useUserEventFilters();
  const homeUrl = useHomeUrl();

  if (userCanViewEvent(filters, userEventFilters) || loading) {
    return children;
  }

  return (
    // For now we only cater for SA residents.
    // Later we can customize the messaging based on the failed filter
    <PageMessage
      image={<ErrorIcon />}
      title="SA Not Permitted"
      subTitle="As a South Australian resident you are not permitted to view this page"
    >
      <LinkButton to={homeUrl}>Return Home</LinkButton>
    </PageMessage>
  );
};
