import React from "react";
import cx from "classnames";
import type { PickemsEntry } from "hooks/firestore/pickems/types";
import { constructCurrency } from "common";
import { useFiatCurrency } from "hooks/firestore/v2/user/useFiatCurrency";

import * as styles from "./Summary.module.scss";

type PickemSummaryProps = {
  entry: PickemsEntry;
};

const PickemSummary = ({ entry }: PickemSummaryProps) => {
  const currency = useFiatCurrency();
  const hasWon =
    entry.entryStatusText === "WON" || entry.entryStatusText === "PLACED";

  return (
    <div
      className={cx(styles.summary, {
        [styles.isWon]: hasWon,
      })}
    >
      <div>
        <span className={styles.label}>Place</span>
        <span className={styles.value}>{entry.place || "-"}</span>
        <div className={styles.circle}>@</div>
      </div>
      <div>
        <span className={styles.label}>Points</span>
        <span className={styles.value}>{entry.points}</span>
        <div className={styles.circle}>=</div>
      </div>
      <div>
        <span className={styles.label}>Payout</span>
        <span className={styles.value}>
          {constructCurrency(entry.payOut, {
            currency,
          })}
        </span>
      </div>
    </div>
  );
};

export { PickemSummary };
