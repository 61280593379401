import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";
import { currency } from "hooks/firestore/v2/models/currency";

export const accountBalance = z.object({
  AVAILABLE: z.number(),
  HOLDING: z.number(),
  PROMOTION_USER: z.number().optional(),
  currency: currency,
});

export type AccountBalance = zInfer<typeof accountBalance>;
