import React, { useCallback, useEffect, useState } from "react";
import * as uuid from "uuid";
import {
  useDispatch,
  useHasPermission,
  useIsLoggedIn,
  useSelector,
} from "hooks";
import { Icon, OddsChange, OddsChangeType } from "library";
import {
  selectIsMultiOutcomeInBetslip,
  toggleSelection,
} from "sections/Betting/Betslip/betslipSlice";
import { SubOutcome } from "sections/Betting/Betslip/legacy/SubOutcome";
import Odds from "sections/Betting/Odds";
import { requestSameRaceMultiOdds } from "utilities/api";
import cx from "classnames";
import type { Event } from "hooks/firestore/betting/useBetting";
import * as styles from "./SGMShare.module.scss";
import OutcomeIcon from "./OutcomeIcon";
import type { SubOutcomesType } from "types/BetTypes";
import { usePreferences } from "hooks/firestore/v2/user/usePreferences";

const SGMShare = ({
  subOutcomes,
  event,
  addToSlip = true,
  active,
  loading,
}: {
  subOutcomes: {
    outcomeId: string;
    outcomeName: string;
  }[];
  event: Event;
  addToSlip: boolean;
  active: boolean;
  loading: boolean;
}) => {
  const dispatch = useDispatch();
  const submitSGMBet = useHasPermission("submitSGMBet");
  const [addedToBetSlip, setAddedToBetSlip] = useState(false);

  const { preferences: userPreferences } = usePreferences();

  const token = useSelector((state) => state?.auth?.token);

  const isLoggedIn = useIsLoggedIn();

  const [odds, setOdds] = useState(0);

  const [loadingOdds, setLoadingOdds] = useState(false);

  const selectIsCurrentSelectionInBetslip = useSelector(
    selectIsMultiOutcomeInBetslip,
  );
  const isCurrentSelectionInBetslip = selectIsCurrentSelectionInBetslip(
    subOutcomes.map((x) => x.outcomeId),
  );

  const addToBetSlip = useCallback(() => {
    if (odds === 0) return;
    dispatch(
      toggleSelection(
        event,
        {
          type: "match",
          outcomeId: uuid.v4(),
          sourceId: event.eventId,
          odds,
          eventId: event.eventId,
          stake: 0,
          subSelections: subOutcomes.map((x) => {
            const market = event.markets.find(
              (market) => market.outcomes[x.outcomeId],
            );
            return {
              outcomeId: x.outcomeId,
              marketId: market?.id,
              name: market?.outcomes[x.outcomeId]?.name,
              marketName: market?.name,
            } as SubOutcomesType;
          }),
        },
        "sports",
      ),
    );
  }, [event, odds, subOutcomes, dispatch]);

  useEffect(() => {
    if (!addedToBetSlip && addToSlip && event.status) {
      if (!loading && event?.markets?.length > 0 && submitSGMBet && odds > 0) {
        addToBetSlip();
        setAddedToBetSlip(true);
      }
    }
  }, [event, loading, submitSGMBet, odds, addToSlip, addedToBetSlip]);

  const handleOddsChanged = useCallback(async () => {
    if (isLoggedIn) {
      if (token === null) return;
      if (active) {
        setLoadingOdds(true);
        try {
          const res = await requestSameRaceMultiOdds({
            token: token,
            payload: {
              bets: {
                one: {
                  selections: [
                    { outcomeIds: subOutcomes.map((x) => x.outcomeId) },
                  ],
                },
              },
            },
          });
          if (res?.bets?.one?.odds)
            setOdds(res?.bets?.one?.odds?.toFixed(2) as any);
        } catch (e) {
          console.log(e);
          setOdds(0);
        } finally {
          setLoadingOdds(false);
        }
      }
    } else {
      setOdds(0);
    }
  }, [isLoggedIn, token, event?.status, subOutcomes]);

  useEffect(() => {
    handleOddsChanged();
  }, [userPreferences, token, isLoggedIn, loading]);

  if (loading || event?.markets.length === 0) return null;

  if (!submitSGMBet) return null;

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <div className={styles.name}>
            <div className={styles.iconContainer}>
              <Icon type="sgmIcon" className={styles.icon} />
            </div>
            <div className={styles.game}>
              Same Game Multi <br />
              <span className={styles.subLabel}>{subOutcomes.length} legs</span>
            </div>
          </div>
          <div
            className={cx(styles.price, {
              [styles.active]: isCurrentSelectionInBetslip,
              [styles.disabled]: !active || loadingOdds,
            })}
            onClick={addToBetSlip}
          >
            {!loadingOdds ? (
              <OddsChange odds={odds} oddChangeType={OddsChangeType.sports}>
                <Odds
                  base={Number(odds)}
                  zero={`-`}
                  format={userPreferences.oddsFormat.toLowerCase()}
                />
              </OddsChange>
            ) : (
              <div>...</div>
            )}
          </div>
        </div>
        <div className={styles.selections}>
          {subOutcomes?.map(({ outcomeId }) => {
            const market = event.markets.find(
              (market) => market.outcomes[outcomeId],
            );

            const outcome = market?.outcomes[outcomeId];
            if (!outcome) return null;

            return (
              <div key={outcomeId}>
                <div>
                  <div className={styles.competitorContainer}>
                    <OutcomeIcon
                      outcome={outcome}
                      competitor={event.competitors?.[outcome.competitorId]}
                      isSelected={false}
                      size={40}
                    />
                  </div>

                  <div className={styles.game}>
                    {outcome.name}
                    <br />
                    <span className={styles.subLabel}>{market.name}</span>
                    <SubOutcome
                      subOutcome={{
                        marketId: market.id,
                        marketName: market.name,
                        name: outcome.name,
                        outcomeId: outcomeId,
                      }}
                      eventId={event.eventId}
                      oddsChanged={handleOddsChanged}
                      sourceType={"match"}
                      showOutcomes={false}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SGMShare;
