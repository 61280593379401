import { useSelector } from "hooks/useSelector";
import { useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";
import { useProfile } from "hooks/firestore/v2/user/useProfile";

export const useIsVerified = (): boolean => {
  const userId = useSelector((state) => state.auth?.userId);

  const [cachedVerificationStatus, setCachedVerificationStatus] =
    useLocalStorage<any>(
      `userVerificationStatus:${userId ?? `anonymous`}`,
      true,
    );

  const { profile, isLoading } = useProfile();

  useEffect(() => {
    if (isLoading || !profile) return;

    setCachedVerificationStatus(
      ["VERIFIED", "VERIFIED_ADMIN"].includes(profile?.verificationStatus),
    );
  }, [isLoading, profile?.verificationStatus]);

  return cachedVerificationStatus;
};
