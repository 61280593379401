import useCalculatePlayerOffset from "hooks/useCalculatePlayerOffset";
import useCalculateSmartBannerOffset from "hooks/useCalculateSmartBannerOffset";
import { useSelector } from "./useSelector";

const useHeaderOffset = (includeNavHeight = false) => {
  const playerHeight = useCalculatePlayerOffset();
  const bannerHeight = useCalculateSmartBannerOffset();
  const statusHeight = useSelector(
    (state) => state.utilities.ui.statusMessageHeight,
  );
  const navHeight = useSelector(
    (state) => state.utilities.ui.navigationHeaderHeight,
  );

  return (
    playerHeight +
    bannerHeight +
    statusHeight +
    (includeNavHeight ? navHeight : 0)
  );
};

const useStatusMessageOffset = () => {
  const playerHeight = useCalculatePlayerOffset();
  const bannerHeight = useCalculateSmartBannerOffset();

  return playerHeight + bannerHeight;
};

export { useHeaderOffset, useStatusMessageOffset };
