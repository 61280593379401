// extracted by mini-css-extract-plugin
export var announcementCard = "AnnouncementCards-module--announcementCard--39325";
export var announcementCards = "AnnouncementCards-module--announcementCards--44116";
export var blue = "#0eb3f2";
export var content = "AnnouncementCards-module--content--99d6e";
export var danger = "AnnouncementCards-module--danger--d9844";
export var emphasis = "AnnouncementCards-module--emphasis--027c1";
export var green = "#57d33b";
export var greenLink = "AnnouncementCards-module--green-link--96f90";
export var grey = "var(--tone-mid)";
export var info = "AnnouncementCards-module--info--e8671";
export var message = "AnnouncementCards-module--message--9aead";
export var neutral = "AnnouncementCards-module--neutral--11e44";
export var noDots = "AnnouncementCards-module--noDots--d7877";
export var noSpacing = "AnnouncementCards-module--noSpacing--cee90";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pointer = "AnnouncementCards-module--pointer--1b346";
export var red = "#ff2741";
export var success = "AnnouncementCards-module--success--4bc51";
export var title = "AnnouncementCards-module--title--0d9ad";
export var warning = "AnnouncementCards-module--warning--027e9";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";