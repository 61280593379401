import * as React from "react";
import * as ToastPrimitives from "@radix-ui/react-toast";
import { type VariantProps, cva } from "class-variance-authority";
import cx from "classnames";
import { ReactComponent as DangerIcon } from "components/assets/danger.svg";
import { ReactComponent as CheckIcon } from "components/assets/success.svg";
import { ReactComponent as InfoIcon } from "components/assets/info.svg";
import { ReactComponent as WarningIcon } from "components/assets/warning.svg";
import { ReactComponent as StarIcon } from "assets/icons/icon-star.svg";
// TODO: Migrate assets to assets folder once migrated all icons
import { ReactComponent as CloseIcon } from "assets/icons/remove.svg";
import { ReactComponent as TipsIcon } from "components/assets/icon-comments.svg";
import { Button } from "components/Button";
import type { buttonVariants } from "components/Button/Button";

import * as styles from "./Toast.module.scss";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cx(styles.viewport, className)}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(styles.toast, {
  variants: {
    variant: {
      danger: styles.danger,
      info: styles.info,
      success: styles.success,
      warning: styles.warning,
      promotion: styles.promotion,
      tips: styles.tips,
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

const variantIconMap = {
  danger: DangerIcon,
  warning: WarningIcon,
  info: InfoIcon,
  success: CheckIcon,
  promotion: StarIcon,
  tips: TipsIcon,
};

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, children, ...props }, ref) => {
  const Icon = variantIconMap[variant || "info"];

  return (
    <ToastPrimitives.Root
      data-variant={variant || "info"}
      ref={ref}
      className={cx(toastVariants({ variant }), styles.toastRoot, className)}
      {...props}
    >
      <Icon className={styles.icon} />
      {children}
    </ToastPrimitives.Root>
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action> &
    VariantProps<typeof buttonVariants>
>(({ className, children, variant, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cx(className)}
    asChild
    data-variant={variant}
    {...props}
  >
    <Button
      size={`md`}
      className={styles.action}
      variant={variant || "primary"}
    >
      {children}
    </Button>
  </ToastPrimitives.Action>
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cx(className)}
    toast-close=""
    onClick={(e) => {
      e.stopPropagation();
      props.onClick?.(e);
    }}
    {...props}
    asChild
  >
    <Button className={styles.close} variant={"blurred"}>
      <CloseIcon />
    </Button>
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cx(styles.title, className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cx(styles.description, className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};

export type { ToastProps, ToastActionElement };
