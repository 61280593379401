import React, { useEffect } from "react";
import * as Yup from "yup";
import {
  differenceInHours,
  isFuture,
  isValid,
  parseISO,
  sub,
  format,
} from "date-fns";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAccountActions } from "hooks/firestore/user/useAccounts";
import { VerificationDocumentType } from "hooks";
import { navigate } from "library";
import { Typography } from "components/Typography";
import { Form } from "components/Form";
import { Link } from "components/Link";
import { Input } from "components/Input";
import { NativeSelect } from "components/NativeSelect";
import { DateInput } from "components/DateInput";
import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { useProfile } from "hooks/firestore/v2/user/useProfile";

const schema = Yup.object().shape({
  number: Yup.string()
    .required("A valid Medicare number is required")
    .length(10, "A valid Medicare number is required"),
  dateOfBirth: Yup.string()
    .required("A valid date of birth is required")
    .test("age", "You must be at least 18 years old", (dob) => {
      const date = parseISO(dob);
      return (
        isValid(date) &&
        differenceInHours(sub(new Date(), { years: 18 }), date) >= 0
      );
    }),
  cardColour: Yup.string().required("Must be a valid Medicare card colour"),

  nameOnCard: Yup.string().required("Name is a required field"),
  nameLine2: Yup.string(),
  nameLine3: Yup.string(),
  nameLine4: Yup.string(),
  expiryDate: Yup.string()
    .required("A valid expiry date is required")
    .test(
      "Expiry",
      "Your expiry date is invalid or your card has expired",
      function (expiry) {
        const date = parseISO(expiry);
        return isValid(date) && isFuture(date);
      },
    ),
  individualReferenceNumber: Yup.string().required(
    "Individual reference number is required",
  ),

  consent: Yup.bool()
    .required("You must consent to Picklebet checking the information above.")
    .oneOf(
      [true],
      "You must consent to Picklebet checking the information above.",
    ),
  isMultiRow: Yup.string().oneOf(["yes", "no"]),
});

type FormValues = Yup.InferType<typeof schema>;

const refNumberOptions = Array.from({ length: 10 }, (_, i) => i + 1).map(
  (i) => ({
    label: i.toString(),
    value: i.toString(),
  }),
);

type MedicareCardProps = {
  prefix?: string;
} & RouteComponentProps;

const MedicareCard = ({ prefix = "/account" }: MedicareCardProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      cardColour: "G",
      individualReferenceNumber: "1",
      isMultiRow: "no",
    },
  });

  const { profile } = useProfile();

  useEffect(() => {
    if (profile) {
      setValue("dateOfBirth", profile.dateOfBirth);
    }
  }, [profile]);

  const { doSubmitVerification } = useAccountActions();
  const consent = watch("consent", false);
  const isMultiRow = watch("isMultiRow", "no");
  const isGreenCard = watch("cardColour", "G") === "G";

  return (
    <>
      <Form
        onSubmit={handleSubmit(async ({ consent, ...fields }) => {
          const isSuccess = await doSubmitVerification({
            terms: consent,
            type: VerificationDocumentType.MEDICARE_CARD,
            fields: {
              ...fields,
              number: fields.number.replace(/\s/g, ""),
              expiryDate: format(
                parseISO(fields.expiryDate),
                fields.cardColour === "G" ? "MM/yyyy" : "dd/MM/yy",
              ),
            },
          });

          if (isSuccess) {
            navigate("/account/verification");
          }
        })}
        fixedWidth
      >
        <Typography variant="h4" noSpacing>
          Medicare Card
        </Typography>

        <Typography variant="body">
          Please enter your identity information, exactly as it appears on your
          Australian Medicare card.
        </Typography>

        <Typography variant={`body`}>
          Don’t have a Medicare card? Select from a list of{" "}
          <Link to={`${prefix}/verification`}>Verification options</Link>.
        </Typography>

        <Input
          maxLength={10}
          {...register("number")}
          label={`Medicare Number`}
          errorMessage={errors?.number?.message}
        />

        <NativeSelect
          {...register("individualReferenceNumber")}
          label={`Individual Reference Number`}
          options={refNumberOptions}
          errorMessage={errors?.individualReferenceNumber?.message}
        />

        <NativeSelect
          {...register("isMultiRow")}
          label={`Does your name run across more than one row on the card`}
          options={[
            { label: "Yes", value: "yes" },
            { label: "No", value: "no" },
          ]}
          errorMessage={errors?.isMultiRow?.message}
        />

        <Input
          maxLength={27}
          label={`Name (as it appears on the card)`}
          placeholder={"Exactly as it appears on your card"}
          {...register("nameOnCard")}
          errorMessage={errors?.nameOnCard?.message}
        />

        {isMultiRow === "yes" && (
          <>
            <Input
              maxLength={27}
              label={`Name Line 2`}
              {...register("nameLine2")}
              errorMessage={errors?.nameLine2?.message}
            />

            <Input
              maxLength={27}
              label={`Name Line 3`}
              {...register("nameLine3")}
              errorMessage={errors?.nameLine3?.message}
            />

            <Input
              maxLength={27}
              label={`Name Line 4`}
              {...register("nameLine4")}
              errorMessage={errors?.nameLine4?.message}
            />
          </>
        )}

        <NativeSelect
          {...register("cardColour")}
          label={`Card Colour`}
          options={[
            { label: "Green", value: "G" },
            { label: "Blue", value: "B" },
            { label: "Yellow", value: "Y" },
          ]}
          errorMessage={errors?.cardColour?.message}
        />

        <Controller
          control={control}
          name={"expiryDate"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"expiryDate"}
              {...field}
              errorMessage={errors.expiryDate?.message}
              hideDays={isGreenCard}
              label={`Card Expiry`}
            />
          )}
        />

        <Controller
          control={control}
          name={"dateOfBirth"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"dateOfBirth"}
              {...field}
              errorMessage={errors.dateOfBirth?.message}
              label={`Date of Birth`}
            />
          )}
        />

        <Controller
          control={control}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Checkbox
              id={`consent`}
              label={`I consent to Picklebet checking the information above with the
            issuer of Official Record Holder.`}
              checked={consent}
              onCheckedChange={(checked) => onChange?.(checked === true)}
              hasError={!!errors?.consent?.message}
              {...field}
            />
          )}
          name={`consent`}
        />

        <Button type={`submit`} loading={isSubmitting}>
          Verify Card
        </Button>
      </Form>
    </>
  );
};

export { MedicareCard };
