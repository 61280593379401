import BigNumber from "bignumber.js";
import { parseISO } from "date-fns";
import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
  type DocumentData,
  type DocumentReference,
  type FirestoreDataConverter,
  type FirestoreError,
  type QueryConstraint,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
} from "firebase/firestore";
import {
  useCollectionData,
  useDocumentData,
  useIsLoggedIn,
  useSelector,
} from "hooks";
import { combinations as calculateCombinations } from "mathjs";
import { stringifyUrl } from "query-string";
import { useEffect, useMemo, useState } from "react";
import type {
  BetEntry,
  Entries,
  Entry,
  PickType,
} from "sections/Entries/types";
import { getFirestore } from "store/getFirebase";
import useSWR from "swr";
import useSWRInfinite from "swr/infinite";
import { useIsClient } from "usehooks-ts";
import { isOutright } from "utilities/sharedBettingUtilities";
import { startCase, values } from "lodash";
import { isExoticMarketType } from "sections/Betting/Race/components/Exotics/Exotics.utils";
import { shouldShowStreamIcon } from "utilities/display";
import type { PickemsEntry } from "../pickems/types";
import type { Nullable } from "types/utilities";

const statusMap = {
  WON: "success",
  PLACED: "success",
  CANCELLED: "neutral",
  LOST: "neutral",
};

const getEntryStatus = (status: keyof typeof statusMap) => {
  return statusMap[status]
    ? {
        variant: statusMap[status],
        text: status,
      }
    : {};
};

const getBetStatus = (status: string, result: string, place: number) => {
  if (status === "SETTLED" && result === "WIN") {
    return {
      variant: "success",
      text: "WON",
    };
  }

  if (status === "SETTLED" && result === "LOSE") {
    return {
      variant: "neutral",
      text: "LOST",
    };
  }

  if (status === "REJECTED" || status === "CANCELLED") {
    return {
      variant: "danger",
      text: status,
    };
  }

  if (result === "VOID" && status === "VOID") {
    return {
      variant: "neutral",
      text: result,
    };
  }

  if (place > 0) {
    return {
      variant: "warning",
      text: "PLACED",
    };
  }
  return {};
};

const colorMap = {
  WIN: "success",
  LOSE: "neutral",
  UNDECIDED: "warning",
  VOID: "neutral",
};

const getBetPickStatus = (
  selectionResult: keyof typeof colorMap,
  marketStatus: string,
  marketBettingType: string,
) => {
  if (selectionResult === "UNDECIDED") {
    if (
      ["DEACTIVATED", "SUSPENDED", "JUMPED"].includes(marketStatus) ||
      marketBettingType?.toLowerCase() === "live"
    ) {
      return {
        message: "LIVE",
        variant: "warning",
      };
    }

    return null;
  }

  return {
    message: selectionResult,
    variant: colorMap[selectionResult] || "neutral",
  };
};

const selectionToUrl = ({ event }): string | undefined => {
  if (event.eventType === "RACE") {
    return `/racing/betting/race/${event.attributes.meetingId}/${event.id}/`;
  }

  const hubPrefix = event.hub === "/sports" ? "sports" : "";

  if (event.eventType === "MATCH") {
    return `${hubPrefix}/betting/match/${event.eventId}`;
  }

  if (isOutright(event.eventType)) {
    return `${hubPrefix}/betting/outright/${event.eventId}`;
  }
};

const isEntryDecided = (status: string): boolean => {
  return ["SETTLED", "REJECTED", "CANCELLED", "VOID"].includes(status);
};

const getParentName = (selection, eventAttributes) => {
  const isRaceEvent = selection.event.eventType === "RACE";
  const raceName = `${eventAttributes.venueName} - R${eventAttributes.raceNumber}`;

  if (selection?.type === "SameEventMulti") {
    return isRaceEvent ? raceName : selection?.event?.attributes?.seasonName;
  }

  return isRaceEvent
    ? raceName
    : eventAttributes.seasonName ?? eventAttributes.tournamentName ?? null;
};

export const convertBetEntry = (entry: DocumentData): Nullable<BetEntry> => {
  if (!entry.data) {
    return null;
  }

  const entryStatus = getBetStatus(
    entry.data.status,
    entry.data.result,
    entry.data.place,
  );

  const decided = isEntryDecided(entry.data.status);
  //selectionsMap has same data as selections but is a map rather than array.
  //So that api can directly publish updates to selection data (status) without republishing all data, this is not possible with arrays.
  // TODO: Currently this is not typed, and it used to spit out many errors, so we are using any here for now.
  const selections: any[] = Object.values(
    entry.data.selectionsMap || entry.data.selections || [],
  );

  let legIndex = 0;

  const calculateTotalLegsIncludingSubOutcomes = () => {
    let totalLegs = 0;
    selections.forEach((selection) => {
      const outcomes = Object.entries(selection?.outcomes || {});
      if (outcomes?.length > 1) {
        totalLegs += outcomes?.length;
      } else {
        totalLegs += 1;
      }
    });
    return totalLegs;
  };

  const isSP = selections.some((selection) => {
    return selection.odds === 1 && selection.event.eventType === "RACE";
  });

  const isMulti = selections.length > 1;

  const isMultiOutcome = !isMulti && selections[0].type === "SameEventMulti";
  const isSGM = isMultiOutcome && selections[0].event.eventType === "MATCH";
  const isSRM = isMultiOutcome && !isSGM;
  const isCombo = !!entry.data.selectionsRequiredToWin;

  const legsIncludingSubOutcomes = calculateTotalLegsIncludingSubOutcomes();
  const selectionsCount = selections.length;

  const combinationsCount = isCombo
    ? calculateCombinations(selectionsCount, entry.data.selectionsRequiredToWin)
    : 0;

  const marketType = values(selections[0]?.outcomes)[0]?.marketType;
  const isExotic = isExoticMarketType(marketType);

  const name = isExotic
    ? `Exotic - ${startCase(marketType.toLowerCase())}`
    : isCombo
      ? `Combo Multi`
      : isSRM
        ? "Same Race Multi"
        : isSGM
          ? "Same Game Multi"
          : isMulti
            ? "Multi"
            : "Single";

  let odds = new BigNumber(1);
  odds = selections.reduce((acc, selection) => {
    if (selection.isVoid) return acc;
    // when we calculate total odds use settlement odds where possible
    const actualOdds = selection.settlementOdds ?? selection.odds;
    return acc.multipliedBy(actualOdds);
  }, odds);

  // floor the number to 2 decimal places
  odds = odds.dp(2, BigNumber.ROUND_FLOOR);

  return {
    id: `B-${entry.data.entryId}`,
    name,
    createdAt: parseISO(entry.data.createdAt),
    updatedAt: parseISO(entry.updatedAt),
    status: entry.data.status,
    entryStatusText: entryStatus.text,
    entryStatusVariant: entryStatus.variant,
    stake: entry.data.stake,
    currency: entry.data.currency,
    decided,
    payOut: decided ? entry.data.paidOut : entry.data.potentialPayout,
    isPromo: entry.data.stakeSource === "PROMOTION_USER",
    entryType: "bet",
    isSP,
    odds: odds.toNumber(),
    isSRM,
    isSGM,
    legsIncludingSubOutcomes,
    automationProgressions: entry?.data?.automationProgressions,
    isCombo,
    isExotic,
    selectionsRequiredToWin: entry.data.selectionsRequiredToWin,
    combinationsCount,
    picks: selections
      .sort((a, b) => {
        // render won ones first
        if (a.result === "WIN" && b.result !== "WIN") {
          return -1;
        }
        if (a.result !== "WIN" && b.result === "WIN") {
          return 1;
        }

        const isAOutright = isOutright(a.event.eventType);
        const isBOutright = isOutright(a.event.eventType);

        // if selection is an outright, we should be
        // using nextBetStop as the start time
        const aStartTime =
          isAOutright && a.marketNextBetStop
            ? a.marketNextBetStop
            : a.event.scheduledStartTime;
        const bStartTime =
          isBOutright && b.marketNextBetStop
            ? b.marketNextBetStop
            : b.event.scheduledStartTime;

        // order by start time
        if (aStartTime < bStartTime) {
          return -1;
        }
        if (aStartTime > bStartTime) {
          return 1;
        }
      })
      .map((selection) => {
        const teamNames = selection.event.eventName?.split(/ vs.? /, 2);

        const eventAttributes = selection.event.attributes || {};

        const parentName = getParentName(selection, eventAttributes);

        // NOTE: hide the round for overwatch and starcraft temporarily -> https://puntaa.atlassian.net/browse/PKB-1586
        const matchMode =
          ["OVERWATCH", "STARCRAFT"].includes(selection.event.sport) &&
          eventAttributes.matchMode === "bo1"
            ? null
            : eventAttributes.matchMode;

        const pickStatus = getBetPickStatus(
          selection.result,
          selection.marketStatus ??
            (Object.values(selection?.outcomes || {})?.[0] as any)
              ?.marketStatus,
          selection.marketBettingType,
        );

        const url = selectionToUrl(selection);

        legIndex++;

        const isSelectionOutright = isOutright(selection.event.eventType);

        const startTime =
          isSelectionOutright && selection.marketNextBetStop
            ? selection.marketNextBetStop
            : selection.event.scheduledStartTime;

        return {
          index: legIndex,
          competitor: selection.competitor ?? {
            silksUrl: eventAttributes?.silksUrl,
            number: Number(selection?.attributes?.runnerNumber),
            type: "RACER",
            abbreviation: selection?.attributes?.runnerNumber,
            name: selection?.outcomeName,
            id: selection.competitorId,
          },
          exoticCombinations: selection.exoticCombinations,
          silksUrl: eventAttributes?.silksUrl,
          teamNames: teamNames?.length === 2 ? teamNames : undefined,
          eventName: selection.event.eventName,
          parentName,
          marketId: selection.marketId,
          eventId: selection.event.eventId,
          marketName: selection.marketName,
          marketStatus: selection.marketStatus,
          odds: selection.odds,
          outcomeName: selection.outcomeName,
          outcomeType: selection.outcomeType,
          settlementOdds: selection.settlementOdds,
          matchMode,
          scheduledStartTime: parseISO(startTime),
          statusText: pickStatus?.message,
          statusVariant: pickStatus?.variant,
          streamUri: selection.event?.streamUri,
          url,
          isPlaceholder: eventAttributes?.streamExpected ?? false,
          deadHeatFactor: selection.deadHeatFactor,
          deductions: selection.deductions,
          eventType: selection.event?.eventType,
          outcomeId: selection.outcomeId,
          sport: selection.event?.sport?.toLowerCase(),
          outcomes: Object.entries(selection?.outcomes || {}).reduce(
            (acc, [outcomeId, outcome], i) => {
              const outcomeStatus = getBetPickStatus(
                (outcome as any)?.outcomeResult,
                (outcome as any)?.marketStatus,
                selection?.marketBettingType,
              );

              if (i > 0) legIndex++;
              acc[outcomeId] = {
                ...(outcome as any),
                statusText: outcomeStatus?.message,
                statusVariant: outcomeStatus?.variant,
                index: legIndex,
                type: (outcome as any)?.outcomeType,
              };
              return acc;
            },
            {},
          ),
          isMultiOutcome: Object.keys(selection?.outcomes || {}).length > 1,
          isSP: selection.odds === 1 && selection.event.eventType === "RACE",
        } as PickType;
      }),
    referenceId: entry.referenceId,
    userId: entry.userId,
  };
};

export const convertPickemEntry = (
  entry: DocumentData,
  snapshotId: string,
): PickemsEntry => {
  const decided = isEntryDecided(entry.data.status);
  const status = getEntryStatus(entry.data.status);

  return {
    contestId: snapshotId,
    createdAt: parseISO(entry.data.createdAt),
    updatedAt: parseISO(entry.updatedAt),
    currency: entry.data.currency,
    decided,
    entryStatusText: status?.text,
    entryStatusVariant: status?.variant,
    entryType: "pickem",
    id: `P-${entry.data.contestNumber}-${entry.entryId}`,
    name: entry.data.contestTitle ?? "Pick'ems Contest",
    payOut: entry.data.paidOut || entry.data.potentialPayout || 0,
    picks: Object.entries(entry.data.selectionsMap || {})
      .sort((a: any, b: any) =>
        a[1]?.event?.scheduledStartTime < b[1]?.event?.scheduledStartTime
          ? -1
          : 1,
      )
      .map(([_, selection]: any, index) => {
        const {
          competitor,
          event,
          points,
          result,
          outcomeId,
          outcomeName,
          outcomeType,
        } = selection;

        const teamNames = event.eventName?.split(/ vs.? /, 2);
        const parentName = getParentName(selection, event.attributes || {});

        return {
          competitor,
          eventId: event.eventId,
          eventName: event.eventName,
          index: `Pick ${index + 1}`,
          isPlaceholder: event?.attributes?.streamExpected ?? false,
          odds: points,
          outcomeName: outcomeName ?? competitor?.name,
          outcomeId,
          outcomeType,
          result,
          scheduledStartTime: parseISO(event.scheduledStartTime),
          shortIndex: `${index + 1}`,
          streamUri: event?.streamUri,
          teamNames: teamNames?.length === 2 ? teamNames : undefined,
          sport: event?.sport?.toLowerCase(),
          parentName,
          marketStatus: selection.marketStatus,
        } as any;
      }),
    place: entry.data.place,
    points: entry.data.points,
    status: entry.data.status,
  };
};

const mapApiEntry = (entry: any): Nullable<BetEntry | PickemsEntry> => {
  return entry.entryType === "BET"
    ? convertBetEntry(entry)
    : convertPickemEntry(entry, entry.referenceId);
};

const converter: FirestoreDataConverter<Nullable<BetEntry | PickemsEntry>> = {
  // we are not saving in firestore no need to transform
  toFirestore: (data: any): DocumentData => data,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Nullable<BetEntry | PickemsEntry> => {
    const entry = snapshot.data(options);

    return entry.entryType === "BET"
      ? convertBetEntry(entry)
      : convertPickemEntry(entry, snapshot.id);
  },
};

const sortEntries = (entries: Entries, tab: string | string[]): Entries => {
  if (typeof tab !== "string") {
    // we are including more than one tab, skip sorting

    return entries;
  }

  const findClosestStartTime = (entry: Entry): Date | null => {
    return entry.picks
      .filter((pick) =>
        entry.entryType === "bet"
          ? !pick.statusText
          : pick.result === "UNDECIDED",
      ) // Filter our picks that have a status
      .reduce((closestStartTime: Date | null, pick: PickType): Date | null => {
        return !closestStartTime || pick.scheduledStartTime < closestStartTime
          ? pick.scheduledStartTime
          : closestStartTime;
      }, null);
  };

  let sorted = [...entries].sort((a, b) => {
    // find the earliest start time out of all selections for both a and b
    const aStartTime = findClosestStartTime(a);
    const bStartTime = findClosestStartTime(b);

    if (!aStartTime || !bStartTime) {
      return 0;
    }

    return aStartTime > bStartTime ? 1 : -1;
  });

  if (tab === "live") {
    // live entries should be sorted by start time and also entries with statusText "LIVE" should be first
    sorted = sorted.sort((a, b) => {
      const aPick = a.picks.find((pick) => pick.statusText === "LIVE");
      const bPick = b.picks.find((pick) => pick.statusText === "LIVE");

      if (aPick && bPick) {
        return aPick.scheduledStartTime < bPick.scheduledStartTime ? -1 : 1;
      }
      if (aPick) {
        return -1;
      }
      if (bPick) {
        return 1;
      }

      return 0;
    });
  }

  return sorted;
};

const useEntries = (
  tab: string | string[],
  page: number,
  perPage = 15,
  limitToEntryType: "bet" | "pickem" | null = null,
): [Entries, boolean] => {
  const [entries, setEntries] = useState<Entries>(undefined);
  const [loading, setLoading] = useState(true);

  const tabList = typeof tab === "string" ? [tab] : tab;

  const userId = useSelector((state) => state.auth.userId);

  const orderByConstraint = orderBy("entryId", "asc");
  const queries: QueryConstraint[] = [
    where("status", "in", tabList),
    orderByConstraint,
  ];

  if (typeof limitToEntryType === "string") {
    queries.push(where("entryType", "==", limitToEntryType.toUpperCase()));
  }

  if (Number.isFinite(perPage)) {
    queries.push(limit(page * perPage));
  }

  const ref = userId
    ? collection(getFirestore(), "users", userId, "entries").withConverter(
        converter,
      )
    : undefined;

  const [entriesRaw, entriesLoading, error] = useCollectionData(
    ref ? query(ref, ...queries) : undefined,
    ref?.path,
  );

  if (error) {
    // NOTE: this will cause missing indexes end up in sentry
    console.error(error);
  }

  useEffect(() => {
    if (entriesLoading) {
      if (page === 1) {
        setLoading(true);
      }
      return;
    }

    setEntries(sortEntries(entriesRaw || [], tab));
    setLoading(false);
  }, [entriesRaw, entriesLoading]);

  return [entries, loading];
};

export const useEventEntries = (
  eventId: string,
  eventStatus: string,
): [Entries, boolean] => {
  const [entries, setEntries] = useState<Entries>([]);
  const [loading, setLoading] = useState(true);
  const userId = useSelector((state) => state.auth.userId);

  const queries: QueryConstraint[] = [
    where("data.eventIds", "array-contains", eventId),
  ];

  const ref =
    userId && eventId
      ? collection(getFirestore(), "users", userId, "entries").withConverter(
          converter,
        )
      : undefined;

  // pending or upcoming entries
  const [entriesRaw, entriesLoading, error] = useCollectionData(
    ref ? query(ref, ...queries) : undefined,
    ref?.path,
  );

  // settled entries
  const {
    entries: staticEntriesRaw,
    isLoading: staticEntriesLoading,
    fetchData,
  } = useStaticEntriesByEventId(100, eventId);

  if (error) {
    // NOTE: this will cause missing indexes end up in sentry
    console.error(error);
  }

  useEffect(() => {
    fetchData();
  }, [eventStatus, eventId]);

  useEffect(() => {
    if (entriesLoading || staticEntriesLoading) {
      return;
    }

    const mergedEntries = [...(entriesRaw ?? []), ...(staticEntriesRaw ?? [])];

    const uniqueEntries = Array.from(
      mergedEntries
        .reduce(
          (acc, entry) => acc.set(entry.id, entry as BetEntry),
          new Map<string, BetEntry>(),
        )
        .values(),
    );

    setEntries(sortEntries(uniqueEntries, ["live", "upcoming"]));
  }, [entriesRaw, entriesLoading, staticEntriesRaw, staticEntriesLoading]);

  useEffect(() => {
    setLoading(entriesLoading || staticEntriesLoading);
  }, [entriesLoading, staticEntriesLoading]);

  return [entries, loading];
};

export const useStaticEntries = (
  limit: number,
): {
  entries: Entries;
  isLoading: boolean;
  getNext: () => void;
  reset: () => void;
  hasMore: boolean;
} => {
  const isLoggedIn = useIsLoggedIn();
  const isClient = useIsClient();

  const getKey = (pageIndex: number, previousPageData: any[]) => {
    const path = `${process.env.GATSBY_API_URI}/entries/historical/`;
    const params: {
      limit: number;
      startAt?: string;
    } = {
      limit,
    };

    if (pageIndex > 0 && previousPageData.length > 0) {
      params.startAt = previousPageData[previousPageData.length - 1].updatedAt;
    }

    return stringifyUrl({
      url: path,
      query: params,
    });
  };

  const { data, size, setSize, isLoading } = useSWRInfinite(
    isClient && isLoggedIn ? getKey : null,
  );
  const isLoadingOnClient = isClient ? isLoading : true;
  const entries = data?.filter((page) => !page.errors).flat() || [];
  const hasMore = entries?.length > 0 && entries?.length % limit === 0;

  const reset = () => setSize(1);
  const getNext = () => setSize(size + 1);

  const mappedEntries = useMemo(() => {
    return (
      entries
        .map(mapApiEntry)
        .sort((a, b) => (a?.updatedAt > b?.updatedAt ? -1 : 1)) || []
    );
  }, [entries]);

  return {
    entries: mappedEntries,
    getNext,
    reset,
    hasMore,
    isLoading: isLoadingOnClient,
  };
};

export const useStaticEntriesByEventId = (limit: number, eventId: string) => {
  const { userId } = useSelector((state) => state.auth);

  const {
    data,
    isLoading,
    mutate: fetchData,
    error,
  } = useSWR(
    userId
      ? stringifyUrl({
          url: `${process.env.GATSBY_API_URI}/entries/historical/`,
          query: {
            limit,
            eventId,
          },
        })
      : null,
  );

  const entries: Entries = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return (
      (Array.isArray(data) ? data : [])
        ?.map(mapApiEntry)
        ?.sort((a, b) => (a?.updatedAt > b?.updatedAt ? -1 : 1)) ?? []
    );
  }, [data, error]);

  return {
    entries,
    isLoading,
    fetchData,
  } as const;
};

export const useStaticEntry = (
  entryId: string,
): [BetEntry | PickemsEntry | null, boolean] => {
  const { userId } = useSelector((state) => state.auth);

  const { data, isLoading } = useSWR(
    userId && entryId
      ? `${process.env.GATSBY_API_URI}/entries/${entryId}`
      : null,
  );

  const entry = useMemo(() => {
    return !isLoading && data ? mapApiEntry(data) : null;
  }, [data, isLoading]);

  return [entry, isLoading];
};

export const useEntry = <T>(entryId): [T, boolean, FirestoreError] => {
  const userId = useSelector((state) => state.auth.userId);

  const ref =
    userId && entryId
      ? (doc(getFirestore(), "users", userId, "entries", entryId).withConverter(
          converter,
        ) as unknown as DocumentReference<T>)
      : undefined;
  const [entry, loading, error] = useDocumentData(ref);

  return [entry, loading, error];
};

export const useIsStreamAvailable = (): boolean => {
  const [entries] = useEntries("live", 1, Number.POSITIVE_INFINITY, "bet");

  return (entries || []).some((entry) =>
    entry.picks.some(
      (pick) => pick.streamUri && shouldShowStreamIcon(pick.scheduledStartTime),
    ),
  );
};

export const useLiveEntriesCount = (limitToType = null): number => {
  const [entries] = useEntries(
    "live",
    1,
    Number.POSITIVE_INFINITY,
    limitToType,
  );

  return (entries || []).length;
};

export const useUpcomingEntriesCount = (limitToType = null): number => {
  const [entries] = useEntries(
    "upcoming",
    1,
    Number.POSITIVE_INFINITY,
    limitToType,
  );

  return (entries || []).length;
};

export const usePendingBetsCount = (
  limitToType: null | "bet" | "pickem" = null,
): number => {
  const live = useLiveEntriesCount(limitToType);
  const upcoming = useUpcomingEntriesCount(limitToType);

  return live + upcoming;
};

export default useEntries;
