// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "SelfSizingText-module--emphasis--8c140";
export var extraSmall = "SelfSizingText-module--extraSmall--579f8";
export var green = "#57d33b";
export var greenLink = "SelfSizingText-module--green-link--aef56";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var small = "SelfSizingText-module--small--edd90";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";