import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { user, type User } from "hooks/firestore/v2/models/user";

const useUser = () => {
  const { data, error, isLoading } = useFirestore("/users/{userId}", user);

  return {
    user: data,
    error,
    isLoading,
  } as const;
};

export { useUser };
