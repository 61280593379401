import React from "react";
import { useBetslip, useSelector } from "hooks";
import { Empty } from "./Empty";
import { BetCard } from "./BetCard";
import { Combinations } from "./Combinations";
import { selectPossibleCombinations } from "../betslipSlice";
import { PermissionsGate } from "components/layout/PermissionsGate";

const Multi = () => {
  const {
    props: {
      selections,
      multiStake,
      betslipIsActive,
      betslipIsReviewingMessages,
      betslipIsChangingSettings,
    },
  } = useBetslip();

  const combinations = useSelector(selectPossibleCombinations);
  const shouldHideMultiBet =
    // Check if there is an empty stake
    multiStake === 0 &&
    // hide if Betslip is not active or changing settings
    ((!betslipIsActive && !betslipIsChangingSettings) ||
      // or we hide if Betslip is reviewing messages
      betslipIsReviewingMessages);

  if (!selections.length) {
    return <Empty />;
  }

  return (
    <>
      {shouldHideMultiBet ? null : <BetCard outcomeIds={selections} />}
      {combinations.length > 0 && (
        <PermissionsGate permissions={["submitComboMultiBet"]}>
          <Combinations />
        </PermissionsGate>
      )}
    </>
  );
};

export { Multi };
