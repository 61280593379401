import React from "react";
import cx from "classnames";
import { type VariantProps, cva } from "class-variance-authority";
import { Slot } from "@radix-ui/react-slot";
import { motion } from "framer-motion";

import * as styles from "./Clamp.module.scss";

type ClampProps = {
  className?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  asChild?: boolean;
  animateIn?: boolean;
} & VariantProps<typeof clampVariants>;

const clampVariants = cva(styles.clamp, {
  variants: {
    variant: {
      success: styles.success,
      danger: styles.danger,
      dangerSoft: styles.dangerSoft,
      warning: styles.warning,
      neutral: styles.neutral,
      grey: styles.grey,
      light: styles.light,
      dark: styles.dark,
      disabled: styles.disabled,
      info: styles.info,
    },
    shape: {
      default: styles.shapeDefault,
      rounded: styles.shapeRound,
    },
  },
  defaultVariants: {
    variant: "neutral",
    shape: "default",
  },
});

const Clamp = ({
  children,
  variant,
  shape,
  onClick,
  className,
  asChild = false,
  animateIn = false,
}: ClampProps) => {
  const Component = asChild ? Slot : "div";
  const Content = (
    <Component
      onClick={onClick}
      className={cx(clampVariants({ variant, shape }), className)}
    >
      {children}
    </Component>
  );

  if (animateIn) {
    return (
      <motion.div
        animate={{
          scale: [0.5, 1.1, 1],
          opacity: [0.1, 1],
        }}
        transition={{
          repeat: 0,
          ease: "easeInOut",
          duration: 0.3,
        }}
      >
        {Content}
      </motion.div>
    );
  }

  return Content;
};

export { Clamp, clampVariants };
