import { z } from "zod";
import type { infer as zInfer } from "zod";
import { timestamp } from "hooks/firestore/v2/models/timestamp";

export const notification = z.object({
  id: z.string(),
  dismissed: z.boolean(),
  message: z.string(),
  externalReferenceId: z.string(),
  externalReferenceType: z.enum([
    "CONTEST",
    "WITHDRAWAL",
    "ADMIN",
    "USER",
    "TRANSACTION",
    "PROMOTION_CLAIM",
    "BET",
    "SKRILL_DEPOSIT",
    "DEPOSIT",
    "ROUND",
    "PICKEMS_CONTEST",
    "PICKEMS_MARKET",
  ]),
  type: z.enum([
    "WELCOME",
    "WELCOME_FANTASY",
    "WELCOME_BETTING",
    "VERIFICATION_REMINDER",
    "VERIFICATION_SUCCESS",
    "CLOSE_ACCOUNT",
    "LOCK_ACCOUNT",
    "DEPOSIT",
    "ADMIN_DEPOSIT",
    "DEPOSIT_FAILED",
    "WITHDRAWAL_PROCESSED",
    "WITHDRAWAL_DECLINED",
    "WITHDRAWAL_PARTIALLY_DECLINED",
    "SKRILL_WITHDRAWAL_CANCELLED",
    "PROMOTION_PAID",
    "PASSWORD_CHANGED",
    "PASSWORD_RESET_TOKEN",
    "PROMOTION_CLAIM_FAILED",
    "PROMOTION_CLAIM_UNAUTHORIZED",
    "VERIFY_EMAIL",
    "BET_CANCELLED",
    "BET_WON",
    "BET_LEG_VOIDED",
    "BET_LEG_UNVOIDED",
    "PROMOTION_BET_STAKE_REFUND",
    "PROMOTION_BET_WINNINGS_BOOST",
    "PROMOTION_DEPOSIT_MATCH",
    "DEPOSIT_LIMIT_REMINDER",
    "CREDIT_CARD_VERIFICATION_REQUIRED",
    "DEPOSIT_LIMIT_RAISE_REQUEST_RECEIVED",
    "DEPOSIT_LIMIT_RAISED",
    "EMAIL_UNSUBSCRIBED",
    "COOLDOWN_COMPLETE",
    "PICKEMS_NEW_MARKETS",
    "PICKEMS_MARKET_EXPIRING",
    "PICKEMS_WON_CONTEST",
    "PICKEMS_CONTEST_CANCELLED",
    "PICKEMS_ADHOC",
    "DEPOSIT_REFUND",
    "BET_ROLLBACK",

    // deprecated
    "MW_DEPOSIT",
    "CANCELLED_CONTEST",
    "CONTEST_STARTING_REMINDER",
    "AUD_BANK_WITHDRAWAL_PARTIALLY_DECLINED",
    "AUD_BANK_WITHDRAWAL",
    "AUD_DEPOSIT",
    "DRAFT_ROSTER_REMINDER",
    "WON_CONTEST",
    "AUD_PROMOTIONS",
    "CSGO_DAILY_ROSTER_CHANGE_REQUIRED",
    "AUD_BANK_WITHDRAWAL_DECLINED",
    "NEW_VERSION_AVAILABLE",
    "LOL_DAILY_ROSTER_CHANGE_REQUIRED",
  ]),
  attributes: z.record(z.string(), z.any()),
  createdAt: timestamp,
});

export type Notification = zInfer<typeof notification>;
