import { useMemo } from "react";
import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { eventCount } from "hooks/firestore/v2/models/eventCount";
import set from "lodash/set";
import useHasPermission from "hooks/useHasPermission";

type Category = "matches" | "outrights" | "pickems" | "racing";

const useEventCounts = () => {
  const { data, isLoading, error } = useFirestore(
    "generatedEventCounts",
    eventCount.array(),
    {
      keyPath: "type",
    },
  );

  const canViewLiveBets = useHasPermission("viewLiveMarkets");

  const eventCounts = useMemo(() => {
    if (!data) {
      return;
    }

    return data.reduce(
      (
        result: Record<Category, Record<string, number>>,
        currentEventCounts,
      ) => {
        const [category, ...sportParts] = currentEventCounts.type.split(
          "_",
        ) as [Category, string[]];
        const sport = sportParts.join("_").toLowerCase();

        const prematchCount = currentEventCounts.prematch || 0;
        const liveCount = currentEventCounts.live || 0;
        const count = canViewLiveBets
          ? prematchCount + liveCount
          : prematchCount;

        set(result, `${category}.${sport}`, count);

        const currentTotal = result[category]?.total || 0;
        set(result, `${category}.total`, currentTotal + count);

        return result;
      },
      {
        matches: {
          total: 0,
        },
        outrights: {
          total: 0,
        },
        pickems: {
          total: 0,
        },
        racing: {
          total: 0,
        },
      },
    );
  }, [data]);

  return {
    eventCounts,
    isLoading,
    error,
  };
};

type EventCounts = Exclude<
  ReturnType<typeof useEventCounts>["eventCounts"],
  undefined
>;

export { useEventCounts, type EventCounts };
