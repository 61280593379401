import { useState, useEffect } from "react";
import {
  isDisplayAtMost as checkIsDisplayAtMost,
  isDisplayAtLeast as checkIsDisplayAtLeast,
  getDisplayMode,
  type DisplayMode,
} from "utilities/display";
import useWindowSize from "hooks/events/useWindowSize";

export const useIsMobile = () => {
  return useDisplayAtMost("mobile");
};

export const useDisplayAtMost = (mode: DisplayMode) => {
  const [isDisplayAtMost, setIsDisplayAtMost] = useState(
    checkIsDisplayAtMost(mode),
  );
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsDisplayAtMost(checkIsDisplayAtMost(mode));
  }, [windowSize]);
  return isDisplayAtMost;
};

export const useDisplayAtLeast = (mode: DisplayMode) => {
  const [isDisplayAtLeast, setDisplayAtLeast] = useState(
    checkIsDisplayAtLeast(mode),
  );
  const windowSize = useWindowSize();

  useEffect(() => {
    setDisplayAtLeast(checkIsDisplayAtLeast(mode));
  }, [windowSize]);
  return isDisplayAtLeast;
};

export const useDisplayMode = () => {
  const [displayMode, setDisplayMode] = useState(getDisplayMode());
  const windowSize = useWindowSize();

  useEffect(() => {
    setDisplayMode(getDisplayMode());
  }, [windowSize]);
  return displayMode;
};
