import React from "react";
import { useBetslip, useDispatch } from "hooks";
import { AnimatePresence, motion } from "framer-motion";
import { Alert } from "./Alert";
import { BetslipUIStates } from "../betslipSlice";
import { Button } from "components/Button";
import { ReactComponent as SettingsIcon } from "components/assets/settings.svg";
import * as styles from "./BetslipFooterBulkActionBar.module.scss";

const BetslipFooterBulkActionBar = () => {
  const dispatch = useDispatch();
  const {
    props: { hasOddsChangeSelection, countSelectionsWithOddsChanges },
    actions: { setBetSlipState, handleAcceptBulkOddsUpdate },
  } = useBetslip();

  const handleShowChangeSettings = () => {
    dispatch(setBetSlipState(BetslipUIStates.changingSettings));
  };

  const handleBulkOddsUpdate = () => {
    dispatch(handleAcceptBulkOddsUpdate());
  };

  const oddsUpdatedText = `${countSelectionsWithOddsChanges > 1 ? "Multiple " : ""} Odds Updated (${countSelectionsWithOddsChanges})`;

  return (
    <AnimatePresence>
      {hasOddsChangeSelection && (
        <motion.div
          initial={{ transform: "translate3d(0, 0, 0)" }}
          animate={{ transform: "translate3d(0, -100%, 0)" }}
          exit={{ transform: "translate3d(0, 0, 0)" }}
          className={styles.bulkActionBar}
        >
          <Alert
            className={styles.alert}
            variant="info"
            icon={
              <Button
                variant="blurred"
                size="xs"
                className={styles.roundBtn}
                onClick={handleShowChangeSettings}
              >
                <SettingsIcon />
              </Button>
            }
          >
            <div className={`${styles.flex} ${styles.alignCenter}`}>
              <div className={`${styles.flex1}`}>{oddsUpdatedText}</div>
              <div>
                <Button variant="info" size="xs" onClick={handleBulkOddsUpdate}>
                  Accept All
                </Button>
              </div>
            </div>
          </Alert>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export { BetslipFooterBulkActionBar };
