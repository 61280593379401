import React, { useEffect, useId, useState } from "react";
import { Pill } from "components/Pill";
import type { TokenGroup } from "hooks/firestore/v2/user/useTokens";
import { useBetslip, useDispatch, useSelector } from "hooks";
import {
  selectUsedAllocationIds,
  setMultiTokenAllocationId,
  setTokenAllocationId,
} from "sections/Betting/Betslip/betslipSlice";
import { useApplicableTokens } from "sections/Betting/Betslip/hooks/useApplicableTokens";
import { RadioGroup, RadioGroupItem } from "components/Radio";

import { ReactComponent as WinningsBoostIcon } from "sections/Betting/Betslip/assets/winnings.svg";
import { ReactComponent as ReturnIcon } from "sections/Betting/Betslip/assets/return.svg";

import * as styles from "./Tokens.module.scss";

type TokenProps = {
  outcomeIds: string[];
  selectedAllocationId?: string;
};

const beautifyNumbersRegex = /(\$[0-9,]+(\.[0-9]{2})?)|([0-9]+(\.[0-9]+)?%)/g;

const beautifyNumbers = (text: string, className: string) => {
  return text.replace(beautifyNumbersRegex, (match) => {
    return `<span class="${styles.highlight} ${className}">${match}</span>`;
  });
};

const Tokens = ({ outcomeIds, selectedAllocationId }: TokenProps) => {
  const {
    props: { betType, betslipIsReviewingMessages, betslipIsSubmitting },
  } = useBetslip();
  const [hasAutoselected, setHasAutoselected] = useState(false);
  const dispatch = useDispatch();
  const isMulti = betType === "MULTI";
  const tokens = useApplicableTokens(outcomeIds);
  const groupCount = Object.keys(tokens).length;
  const [selectedGroup, setSelectedGroup] = useState<TokenGroup | null>(null);
  const selectedGroupTokens = selectedGroup
    ? tokens[selectedGroup]?.tokens
    : [];
  const uniqueId = useId();

  const usedAllocationIds = useSelector(selectUsedAllocationIds);
  const isReviewingOrSubmitting =
    betslipIsReviewingMessages || betslipIsSubmitting;

  const shouldHide = isReviewingOrSubmitting && !selectedAllocationId;

  const setAllocationId = (allocationId: string) => {
    if (isMulti) {
      dispatch(setMultiTokenAllocationId(allocationId));
      return;
    }

    const outcomeId = outcomeIds[0];
    dispatch(
      setTokenAllocationId({
        outcomeId,
        allocationId,
      }),
    );
  };

  useEffect(() => {
    if (!selectedAllocationId && selectedGroup) {
      // deselect the group if allocation id is removed
      setSelectedGroup(null);
    }
  }, [selectedAllocationId]);

  useEffect(() => {
    if (
      selectedGroup ||
      selectedAllocationId ||
      isReviewingOrSubmitting ||
      hasAutoselected
    )
      return;

    const eventBasedToken = Object.keys(tokens)
      .flatMap((group: TokenGroup) =>
        // TODO: Need to fix useApplicableTokens types
        // currently it returns any, and that screws up all the types down the chain
        (tokens as any)[group].tokens.map((token: any) => ({
          ...token,
          group,
        })),
      )
      .find((token) => token.attributes.eventBasedTokens);
    const firstAllocation = chooseFirstAvailableAllocation(
      eventBasedToken?.allocations || [],
      usedAllocationIds,
      true,
    );

    if (!eventBasedToken || !firstAllocation) return;

    setSelectedGroup(eventBasedToken.group);
    setAllocationId(firstAllocation.id);
    setHasAutoselected(true);
  }, [tokens]);

  const toggleAllocationId = (tokenId: string) => {
    if (selectedAllocationId === tokenId) {
      setAllocationId(null);
      return;
    }

    setAllocationId(tokenId);
  };

  const toggleSelectedGroup = (type: ActionType) => {
    if (type === selectedGroup) {
      setSelectedGroup(null);
    } else {
      setSelectedGroup(type);
    }
  };

  const chooseFirstAvailableAllocation = (
    allocations: Allocation[],
    usedAllocationIds: string[],
    allowEmpty = false,
  ) => {
    const firstAvailableAllocation = allocations.find(
      (allocation) => !usedAllocationIds.includes(allocation.id),
    );

    return allowEmpty
      ? firstAvailableAllocation
      : firstAvailableAllocation ?? allocations[0];
  };

  if (groupCount === 0 || shouldHide) {
    return null;
  }

  return (
    <>
      <div className={styles.tokens}>
        {Object.keys(tokens).map((type: ActionType) => {
          const group = tokens[type];
          const allocationsCount = group.tokens.reduce(
            (result, token) => result + token.allocations.length,
            0,
          );

          return (
            <Pill
              key={`apply-token-${type}`}
              disabled={isReviewingOrSubmitting}
              className={styles.token}
              onPressedChange={(isPressed) => {
                toggleSelectedGroup(type);

                if (!isPressed) {
                  // if the group is unselected, remove the allocation id
                  toggleAllocationId(selectedAllocationId);
                  return;
                }

                const firstAvailableAllocation = chooseFirstAvailableAllocation(
                  group.tokens[0].allocations,
                  usedAllocationIds,
                );

                toggleAllocationId(firstAvailableAllocation.id);
              }}
              pressed={type === selectedGroup}
            >
              <span className={styles.icon}>
                {type === "WINNINGS_BOOST" ? (
                  <WinningsBoostIcon />
                ) : (
                  <ReturnIcon />
                )}
              </span>
              {group.name}
            </Pill>
          );
        })}
      </div>
      {selectedAllocationId && selectedGroupTokens?.length > 0 && (
        <RadioGroup className={styles.tokenSelector}>
          {selectedGroupTokens.map((token) => {
            const firstAvailableAllocation = chooseFirstAvailableAllocation(
              token.allocations,
              usedAllocationIds,
            );

            return (
              <RadioGroupItem
                className={styles.item}
                key={`allocation-${uniqueId}-${token.automationId}`}
                id={`allocation-${uniqueId}-${token.automationId}`}
                label={
                  <>
                    {token.attributes.tokenInfoPrefix && (
                      <span className={styles.prefix}>
                        {token.attributes.tokenInfoPrefix}:{" "}
                      </span>
                    )}

                    <span
                      className={styles.label}
                      dangerouslySetInnerHTML={{
                        __html: beautifyNumbers(
                          token.actions[0].info,
                          selectedGroup === "WINNINGS_BOOST"
                            ? styles.orange
                            : styles.green,
                        ),
                      }}
                    />
                  </>
                }
                value={token.automationId}
                checked={token.allocations.some(
                  (allocation) => allocation.id === selectedAllocationId,
                )}
                onClick={() => toggleAllocationId(firstAvailableAllocation.id)}
              />
            );
          })}
        </RadioGroup>
      )}
    </>
  );
};

export { Tokens };
