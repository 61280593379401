import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";

export const publicProfile = z.object({
  id: z.string(),
  username: z.string(),
  avatarUri: z.string().optional(),
});

export type PublicProfile = zInfer<typeof publicProfile>;
