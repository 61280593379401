// extracted by mini-css-extract-plugin
export var avatar = "CompetitorAvatar-module--avatar--767ce";
export var blue = "#0eb3f2";
export var emphasis = "CompetitorAvatar-module--emphasis--b1613";
export var fallback = "CompetitorAvatar-module--fallback--d6267";
export var green = "#57d33b";
export var greenLink = "CompetitorAvatar-module--green-link--ca828";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";