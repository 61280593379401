import { get } from "lodash";
import { useCallback, useContext } from "react";
import {
  getValidCompetitorCount,
  hasSPMarkets,
  mapCompetitors,
} from "utilities/racingUtilities";

import type {
  ExoticMarketType,
  RaceCompetitorType,
  RaceMarketTypeTypes,
  RaceOutcomeType,
} from "./RacingTypes";
import { RaceContext } from "sections/Betting/Race";
import {
  type OrderColumn,
  OrderType,
  columnSelectors,
} from "sections/Betting/Race/hooks/useRaceOrder";

export type MappedCompetitorType = RaceCompetitorType & {
  markets: Record<
    string,
    {
      promotionIsAvailable?: boolean;
      outcomeId: string;
      outcome: Partial<RaceOutcomeType>;
      marketId: string;
      marketType: RaceMarketTypeTypes;
    }
  >;
};

export type MappedCompetitorsType = MappedCompetitorType[];

export const useSortedCompetitors = ({
  marketsRequired,
  showResulted = false,
}: {
  marketsRequired: Array<RaceMarketTypeTypes | ExoticMarketType>;
  showResulted?: boolean;
}) => {
  const { competitors, markets, raceOrder, setRaceOrder, forceSp } =
    useContext(RaceContext);

  const getMappedCompetitors = () => {
    if (!competitors) return [];

    const mapped = mapCompetitors({
      competitors,
      markets: markets || [],
      marketsRequired,
      showResulted,
      forceSp,
    });

    if (!raceOrder) return mapped;

    const { orderColumn, orderType } = raceOrder;
    const column = columnSelectors?.[orderColumn];

    return mapped.sort((a, b) => {
      const aValue = get(a, column);
      const bValue = get(b, column);

      if (a.scratched && !b.scratched) {
        return 1;
      }
      if (b.scratched && !a.scratched) {
        return -1;
      }

      if (aValue === bValue) return 0;

      // Attempt to parse both values as numbers
      const numA = parseFloat(aValue);
      const numB = parseFloat(bValue);

      // Check if both values are numbers and not NaN
      const areBothNumbers = !isNaN(numA) && !isNaN(numB);

      if (areBothNumbers) {
        // Numeric sort
        return orderType === "asc" ? numA - numB : numB - numA;
      } else {
        // Fallback to string sort if either value isn't a number
        const stringA = aValue.toString();
        const stringB = bValue.toString();
        return orderType === "asc"
          ? stringA.localeCompare(stringB)
          : stringB.localeCompare(stringA);
      }
    });
  };

  const mappedCompetitors = getMappedCompetitors();

  const isSP = hasSPMarkets(mappedCompetitors);

  // check that we have markets available as NON SP
  const hasActiveMarkets = marketsRequired.every((marketType) =>
    (markets || []).some((market) => market.marketType === marketType),
  );

  const changeOrder = useCallback(
    (orderColumn: OrderColumn) => {
      if (orderColumn === raceOrder.orderColumn) {
        setRaceOrder({
          orderColumn,
          orderType:
            raceOrder.orderType === OrderType.asc
              ? OrderType.desc
              : OrderType.asc,
        });
      } else {
        setRaceOrder({
          orderColumn,
          orderType: OrderType.asc,
        });
      }
    },
    [raceOrder],
  );

  return {
    mappedCompetitors,
    raceOrder,
    isSP,
    changeOrder,
    validCompetitorCount: getValidCompetitorCount(mappedCompetitors),
    hasActiveMarkets,
  };
};
