import React, { PropsWithChildren } from "react";
import cx from "classnames";

import * as styles from "./SelfSizingText.module.scss";

type SelfSizingTextProps = {
  className?: string;
  length: number;
};

const SelfSizingText = ({
  className = "",
  length,
  children,
}: SelfSizingTextProps & PropsWithChildren) => {
  const fontSizeClass = (length: number): string => {
    if (length < 24) {
      return "";
    }

    if (length >= 24 && length < 30) {
      return styles.small;
    }

    return styles.extraSmall;
  };

  return <div className={cx(className, fontSizeClass(length))}>{children}</div>;
};

export { SelfSizingText };
