import React from "react";
import cx from "classnames";
import type { Event as EventType } from "hooks/firestore/betting/useBetting";
import { TitleIcon } from "components/TitleIcon";
import Outcomes from "./Outcomes";
import { Icon, Link, StreamButton } from "library";
import StatusAndCountdown from "./StatusAndCountdown";
import type { ToggleSelectionPayload } from "hooks/firestore/betting/types";
import { useHasPermission } from "hooks";
import { PromoIcon } from "components/PromoIcon";
import type { Title } from "hooks/graphql/useTitles";
import { usePreferences } from "hooks/firestore/v2/user/usePreferences";

import * as styles from "./Event.module.scss";

type MatchProps = {
  match: EventType;
  title: Title;
  isRepeat: boolean;
  selections: string[]; // array of outcome ids
  toggleSelection: (pick: ToggleSelectionPayload) => void;
  oddsFormat?: string;
  isDisabled?: boolean; // for when we want outcomes to be unclickable but the status isn't suspended, e.g a pickems settled market where the status is resulted, won or lost
  showOdds?: boolean;
  showRound?: boolean;
};

const Match = ({
  match,
  title,
  isRepeat,
  selections,
  toggleSelection,
  oddsFormat,
  isDisabled,
  showOdds = false,
  showRound = true,
}: MatchProps) => {
  const { preferences: userPreferences } = usePreferences();

  const submitSGMBet = useHasPermission("submitSGMBet");

  const offersSgm = match?.attributes?.sgm && submitSGMBet;

  const activeMarketCount = offersSgm
    ? match?.activeMarketCount
    : match?.activeMarketCounts?.SingleOutcome;

  return (
    <div
      className={cx(styles.row, {
        [styles.repeat]: isRepeat,
      })}
    >
      <div className={styles.info}>
        {isRepeat ? null : (
          <>
            <div className={styles.icon}>
              <TitleIcon title={title} svg />
            </div>
            <div className={styles.description}>
              <div className={styles.game}>{title?.name}</div>
              <div className={styles.season}>
                {match.attributes?.tournamentName}
              </div>
              {showRound && (
                <div className={styles.round}>
                  {match.attributes?.roundName?.replace(
                    new RegExp("_", "g"),
                    " ",
                  )}
                  {match.attributes?.matchMode &&
                    " " + match.attributes.matchMode}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <Outcomes
        oddsFormat={
          oddsFormat ? oddsFormat : userPreferences.oddsFormat.toLowerCase()
        }
        eventId={match.eventId}
        marketId={match.mainMarket.id}
        outcomes={match.mainMarket.outcomes}
        competitors={match.competitors}
        isSuspended={match.status === "SUSPENDED" || isDisabled}
        selections={selections}
        toggleSelection={toggleSelection}
        showOdds={showOdds}
        status={match.mainMarket.status}
      />
      <div className={styles.eventDetails}>
        <div className={styles.eventCount}>
          <StatusAndCountdown
            className={cx(styles.starts, {
              [styles.hasExotics]: activeMarketCount > 1,
            })}
            status={match.status}
            scheduledStartTime={match.nextBetStop || match.scheduledStartTime}
          />
          {activeMarketCount > 1 && (
            <>
              <Link
                to={`${match.hub === "sports" ? "/sports" : ""}/betting/match/${
                  match.eventId
                }`}
              >
                <div
                  className={cx(styles.matchLink, {
                    [styles.hasStarted]:
                      match?.status?.toLowerCase() === "live",
                    [styles.isSuspended]:
                      match?.status?.toLowerCase() === "suspended",
                  })}
                >
                  +{activeMarketCount - 1}
                  <Icon type="chevronRight" inline />
                </div>
              </Link>
            </>
          )}
          <StreamButton
            title={match.eventName}
            sport={match.sport}
            url={match.streamUri}
            className={styles.streamButton}
            startTime={match.nextBetStop || match.scheduledStartTime}
            status={match.status}
            eventId={match.eventId}
            isPlaceholder={match?.attributes?.streamExpected}
          />
        </div>
        <div className={styles.promotional}>
          {match?.promotionIsAvailable && <PromoIcon />}
          {offersSgm && (
            <Link
              onClick={() => window.scrollTo(0, 0)}
              to={`${match.hub === "sports" ? "/sports" : ""}/betting/match/${
                match.eventId
              }/sgm/`}
            >
              <div
                className={cx(styles.matchLink, styles.hasSgm, {
                  [styles.isSgmReady]: match.isSGMReady,
                })}
              >
                SGM
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Match;
