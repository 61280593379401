import React from "react";
import * as styles from "./PromoIcon.module.scss";
import { useIsLoggedIn, type BettingTabs } from "hooks";
import type { CampaignsType } from "hooks/firestore/v2/models/bettingEvent";
import { shouldShowCampaignOnTab } from "sections/Betting/CampaignPromotions";
import cx from "classnames";
import { ReactComponent as SmallPromoIcon } from "components/assets/icon-star-small.svg";

type TabPromoIconProps = {
  tab: BettingTabs;
  campaigns?: CampaignsType[];
  eventHasPromoBanner: boolean;
};

export const TabPromoIcon = ({
  tab,
  campaigns,
  eventHasPromoBanner,
}: TabPromoIconProps) => {
  const isLoggedIn = useIsLoggedIn();
  if (!isLoggedIn || !campaigns || !eventHasPromoBanner) return null;

  return campaigns?.some((campaign) =>
    shouldShowCampaignOnTab(tab, campaign.bettingTabs),
  ) ? (
    <SmallPromoIcon className={cx(styles.tabPromoIcon)} />
  ) : null;
};
