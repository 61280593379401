import React from "react";
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "./index";
import { useToast } from "hooks/ui/useToast";
import { useSelector, useDisplayAtMost } from "hooks";
import cx from "classnames";

import {
  titleAndDescription,
  actions,
  hasModal,
  hasTipsOnly,
} from "./Toast.module.scss";

const Toaster = () => {
  const { toasts } = useToast();

  // on mobile, the tips get in the way of critical behaviours.
  // We adjust the z-index to allow users to log in, bet and use the menu
  const toastsAreOnlyTips =
    useDisplayAtMost("tablet") &&
    toasts.every((toast) => toast.variant === "tips");
  const hasModalOpened = useSelector((state) => !!state.utilities.ui.modal);

  return (
    <ToastProvider duration={5000}>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className={titleAndDescription}>
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            <div className={actions}>
              {action}
              <ToastClose />
            </div>
          </Toast>
        );
      })}
      <ToastViewport
        id={`toaster-viewport`}
        className={cx({
          [hasModal]: hasModalOpened,
          [hasTipsOnly]: toastsAreOnlyTips,
        })}
      />
    </ToastProvider>
  );
};

export { Toaster };
