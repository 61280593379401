import { z } from "zod";

export const currency = z.enum([
  "AUD",
  "NZD",
  "SKR",
  "USD",
  "ETH",
  "BTC",
  "EUR",
  "CAD",
]);
