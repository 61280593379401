import React from "react";
import type { MenuItem } from "./types";

import * as styles from "./CondensedMenu.module.scss";
import CondensedMenuItem from "./CondensedMenuItem";
import { useIsClient } from "usehooks-ts";
import { useHeaderOffset } from "hooks/useHeaderOffset";

type CondensedMenuProps = {
  items: MenuItem[];
};

const CondensedMenu = ({ items }: CondensedMenuProps) => {
  const isClient = useIsClient();
  const topOffset = useHeaderOffset(true);
  const height = `calc(100vh - ${topOffset}px)`;

  if (!isClient) {
    return null;
  }

  return (
    <div
      className={styles.menu}
      style={{ ...(topOffset && { top: `${topOffset}px`, height }) }}
    >
      <ul className={styles.items}>
        {items.map((item, index) => (
          <CondensedMenuItem key={`${item.name}-${index}`} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default CondensedMenu;
