import { initializeApp, type FirebaseApp, setLogLevel } from "firebase/app";
import {
  type Firestore,
  initializeFirestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getAuth, type Auth, connectAuthEmulator } from "firebase/auth";
import queryString from "query-string";

import { firebaseRawConfig } from "./firebaseConfig";
import { enableAnalytics } from "utilities/analyticsConfig";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  ReCaptchaEnterpriseProvider,
  type AppCheck,
  onTokenChanged,
  getToken,
} from "firebase/app-check";

let app: FirebaseApp;
let auth: Auth;
let firestore: Firestore;

let appCheck: AppCheck;

const isSSR = typeof window === "undefined";

if (firebaseRawConfig.appCheckDevToken && !isSSR) {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = firebaseRawConfig.appCheckDevToken;
}

const initialiseRealFirebase = () => {
  // we use appcheck in prod OR in dev when dev token is set
  const shouldUseAppCheck = Boolean(
    !isSSR &&
      (firebaseRawConfig.appCheckSiteKey ||
        firebaseRawConfig.appCheckEnterpriseSiteKey) &&
      firebaseRawConfig.appId,
  );

  console.info(`AppCheck ${shouldUseAppCheck ? "enabled" : "disabled"}`);

  app = initializeApp(firebaseRawConfig);

  if (shouldUseAppCheck) {
    const provider = firebaseRawConfig.appCheckEnterpriseSiteKey
      ? new ReCaptchaEnterpriseProvider(
          firebaseRawConfig.appCheckEnterpriseSiteKey,
        )
      : new ReCaptchaV3Provider(firebaseRawConfig.appCheckSiteKey ?? "");
    appCheck = initializeAppCheck(app, {
      provider,

      // Optional argument. If true, the SDK automatically refreshes App Check
      // tokens as needed.
      isTokenAutoRefreshEnabled: true,
    });
  }

  firestore = initializeFirestore(
    app,
    {
      // Ref. https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettingsexperimentalautodetectlongpolling
      experimentalAutoDetectLongPolling: true,
    },
    firebaseRawConfig.databaseId,
  );
  const queryParams =
    typeof window !== "undefined"
      ? queryString.parse(window.location.search)
      : {};

  // enable logging if required
  if (
    typeof queryParams?.logFb !== "undefined" ||
    process.env.GATSBY_LOG_FIREBASE === "true"
  ) {
    setLogLevel("debug");
  }

  auth = getAuth(app);

  if (appCheck) {
    onTokenChanged(appCheck, () => {
      // if appcheck is ON only enable analytics after appcheck is ready
      enableAnalytics();
    });
  } else {
    // if appcheck is OFF enable analytics immediately
    enableAnalytics();
  }
};

const initialiseEmulators = () => {
  console.log(
    "Running in emulator mode with project: " + firebaseRawConfig.projectId,
  );

  app = initializeApp(firebaseRawConfig);
  auth = getAuth(app);
  const authUrl = `http://${firebaseRawConfig.emulator.authHost}:${firebaseRawConfig.emulator.authPort}`;
  console.log("Auth emulator URL: " + authUrl);
  connectAuthEmulator(auth, authUrl);

  const firestore = initializeFirestore(
    app,
    {
      // Ref. https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettingsexperimentalautodetectlongpolling
      experimentalAutoDetectLongPolling: true,
    },
    firebaseRawConfig.databaseId,
  );
  connectFirestoreEmulator(
    firestore,
    firebaseRawConfig.emulator.firestoreHost,
    Number(firebaseRawConfig.emulator.firestorePort),
  );
  console.log(
    "Firestore emulator URL: " +
      firebaseRawConfig.emulator.firestoreHost +
      ":" +
      firebaseRawConfig.emulator.firestorePort,
  );
};

const initialiseFirebaseApplication = () => {
  if (process.env.JEST_WORKER_ID !== undefined) {
    return;
    //running unit tests
  }
  if (firebaseRawConfig.projectId?.startsWith("demo-")) {
    //The -demo prefix indicates that we are running against a demo project.
    //See: https://firebase.google.com/docs/emulator-suite/connect_firestore
    initialiseEmulators();
  } else {
    initialiseRealFirebase();
  }
};

const getFirestore: () => Firestore = () => {
  if (!firestore) {
    firestore = initializeFirestore(
      app,
      {
        // Ref. https://firebase.google.com/docs/reference/js/firestore_.firestoresettings.md#firestoresettingsexperimentalautodetectlongpolling
        experimentalAutoDetectLongPolling: true,
      },
      firebaseRawConfig.databaseId,
    );
  }

  return firestore;
};

let lastForceRefreshOnErrorTimestamp = 0;
const MINIMUM_DURATION_BETWEEN_FORCE_REFRESHES = 1000 * 60 * 5; //5 minutes

const getAppCheckToken = async (forceRefresh = false) => {
  if (!appCheck) {
    return;
  }

  try {
    const response = await getToken(appCheck, forceRefresh);
    return response?.token;
  } catch (e) {
    //We are getting errors where app check token attempts to exchange an expired recaptcha token after the app has been asleep for a while.
    //see https://github.com/firebase/firebase-js-sdk/issues/6708
    //As an attempt to work around this issue we will try to get a new token with force refresh if we get an error and it has been more than x minutes since we last tried with force refresh.

    if (forceRefresh) {
      //we already tried with force refresh, so just return null so that user can attempt to call api.
      //We can then switch enforcement on or off in api config.
      console.info("Getting App check token with force refresh failed", e);
      return null;
    }
    if (
      Date.now() - lastForceRefreshOnErrorTimestamp >
      MINIMUM_DURATION_BETWEEN_FORCE_REFRESHES
    ) {
      console.info(
        "Error getting app check token. Retrying get app check token with force refresh",
      );
      lastForceRefreshOnErrorTimestamp = Date.now();
      return await getAppCheckToken(true);
    } else {
      //Error getting app check token. Not retrying get app check token with force refresh because it has been less than 5 minutes since last attempt
      return null;
    }
  }
};

initialiseFirebaseApplication();

export { auth, getAppCheckToken, getFirestore };
