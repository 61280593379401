import "core-js/proposals/set-methods-v2";
import { useMemo } from "react";
import { useBetslip, useSelector } from "hooks";
import set from "lodash/set";
import { useTokens } from "hooks/firestore/v2/user/useTokens";
import isEmpty from "lodash/isEmpty";

const setIncludes = (
  set1: Set<string>,
  set2: Set<string>,
  includeAll = false,
) => {
  if (includeAll) {
    return set2.size > 0 && set1.isSupersetOf(set2);
  } else {
    return set1.intersection(set2).size > 0;
  }
};

const useApplicableTokens = (outcomeIds: string[]) => {
  const selectionMap = useSelector((state) => state.betslip.selections);

  const selections = useMemo(
    () =>
      Object.keys(selectionMap)
        .filter((outcomeId) => outcomeIds.includes(outcomeId))
        .map((outcomeId) => selectionMap[outcomeId]),
    [selectionMap],
  );

  const { tokens } = useTokens();
  const {
    props: { multiStake, isMultiUsingPromo, combinedOdds, betType },
  } = useBetslip();

  // Gather all the data required to calculate if tokens are applicable
  const isMulti = betType == "MULTI";
  const selection = isMulti ? null : selections[0];
  const isSEM = !!selection?.subOutcomes;
  const stake = isMulti ? multiStake : selection.stake * 100;
  const marketIds = useMemo(
    () =>
      new Set(
        selections
          .map((selection) =>
            [
              selection.marketId,
              ...(selection.subOutcomes || []).map(
                (subOutcome) => subOutcome.marketId,
              ),
            ].filter(Boolean),
          )
          .flat(),
      ),
    [selections],
  );
  const sports = useMemo(
    () => new Set(selections.map((selection) => selection.sport)),
    [selections],
  );
  const eventIds = useMemo(
    () => new Set(selections.map((selection) => selection.eventId)),
    [selections],
  );
  const isPromo = isMulti ? isMultiUsingPromo : selection?.isUsingPromo;
  const stakeSource = isPromo ? "PROMOTION_USER" : "AVAILABLE";
  const odds = isMulti ? combinedOdds : selection.odds;
  const subOutcomesCount = selection?.subOutcomes?.length || 0;

  const marketTypes = useMemo(
    () => new Set<string>(selections.map((selection) => selection.marketType)),
    [selections],
  );

  const seasonIds = useMemo(
    () => new Set<string>(selections.map((selection) => selection.seasonId)),
    [selections],
  );

  const tournamentIds = useMemo(
    () =>
      new Set<string>(selections.map((selection) => selection.tournamentId)),
    [selections],
  );

  const meetingIds = useMemo(
    () => new Set<string>(selections.map((selection) => selection.meetingId)),
    [selections],
  );

  return useMemo(() => {
    return Object.keys(tokens).reduce((groupedTokens, actionType) => {
      const filteredTokens = tokens[actionType].tokens
        .filter((automation) => {
          const requirements = automation.requirements.find((requirement) =>
            ["NON_SEM_BET_STAKE", "SEM_BET_STAKE"].includes(requirement.type),
          );

          if (!requirements) {
            return false;
          }

          if (isSEM && !requirements.type?.startsWith("SEM")) {
            return false;
          }

          if (!isSEM && !requirements.type?.startsWith("NON_SEM")) {
            return false;
          }

          if (
            selections.some((selection) =>
              requirements?.userDrivenAttributes?.excludedEventTypes?.includes(
                selection.eventType,
              ),
            )
          ) {
            return false;
          }

          if (requirements.userDrivenAttributes?.excludePromotionalMarkets) {
            if (
              isMulti &&
              selections.some((selection) => selection.hasPromotionalMarkets)
            ) {
              return false;
            }

            if (!isMulti && selection?.hasPromotionalMarkets) {
              return false;
            }
          }

          if (
            typeof requirements.userDrivenAttributes?.minimumStake !==
              "undefined" &&
            requirements.userDrivenAttributes?.minimumStake > stake
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.minimumSelections !==
              "undefined" &&
            requirements.userDrivenAttributes?.minimumSelections >
              outcomeIds.length
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.maximumSelections !==
              "undefined" &&
            requirements.userDrivenAttributes?.maximumSelections <
              outcomeIds.length
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.maximumSelections !==
              "undefined" &&
            requirements.userDrivenAttributes?.maximumSelections === 1 &&
            isMulti
          ) {
            return false;
          }

          if (
            typeof requirements?.userDrivenAttributes?.minimumOutcomes !==
              "undefined" &&
            requirements.userDrivenAttributes?.minimumOutcomes >
              subOutcomesCount
          ) {
            return false;
          }

          if (
            typeof requirements?.userDrivenAttributes?.maximumOutcomes !==
              "undefined" &&
            requirements.userDrivenAttributes?.maximumOutcomes <
              subOutcomesCount
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.maximumStake !==
              "undefined" &&
            requirements.userDrivenAttributes?.maximumStake < stake
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.stakeSource !==
              "undefined" &&
            requirements.userDrivenAttributes.stakeSource !== stakeSource
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.minimumOdds !==
              "undefined" &&
            requirements.userDrivenAttributes?.minimumOdds > odds
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.marketIds !==
              "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.marketIds),
              marketIds,
              requirements.userDrivenAttributes?.allMarketIdsMustMatch || false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.eventIds !==
              "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.eventIds),
              eventIds,
              requirements.userDrivenAttributes?.allEventIdsMustMatch || false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.meetingIds !==
              "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.meetingIds),
              meetingIds,
              requirements.userDrivenAttributes?.allMeetingIdsMustMatch ||
                false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.sports !== "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.sports),
              sports,
              requirements.userDrivenAttributes?.allSportsMustMatch || false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.marketTypeNames !==
              "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.marketTypeNames),
              marketTypes,
              requirements.userDrivenAttributes?.allMarketTypeNamesMustMatch ||
                false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.seasonIds !==
              "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.seasonIds),
              seasonIds,
              requirements.userDrivenAttributes?.allSeasonIdsMustMatch || false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.tournamentIds !==
              "undefined" &&
            !setIncludes(
              new Set(requirements.userDrivenAttributes.tournamentIds),
              tournamentIds,
              requirements.userDrivenAttributes?.allTournamentIdsMustMatch ||
                false,
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.minimumSelectionOdds !==
              "undefined" &&
            !selections.every(
              (selection) =>
                selection.odds >=
                Number(requirements.userDrivenAttributes?.minimumSelectionOdds),
            )
          ) {
            return false;
          }

          if (
            typeof requirements.userDrivenAttributes?.minimumExpectedResults !==
            "undefined"
          ) {
            if (isEmpty(selection?.expectedResults)) return false;

            // convert automation config to match the same shape as the
            // expectedResults fromt the event: { [resultType]: number }
            const automationRequirementConfig =
              requirements.userDrivenAttributes?.minimumExpectedResults.reduce<
                Record<string, number>
              >((acc, expected) => {
                if (!acc[expected.resultType]) {
                  acc[expected.resultType] = expected.minimum;
                }

                return acc;
              }, {});

            if (
              !Object.keys(selection.expectedResults).some((result) => {
                const minimum = automationRequirementConfig[result];
                return selection.expectedResults[result] >= minimum;
              })
            )
              return false;
          }

          return true;
        })
        // exclude allocations that are vetoed from this event id
        .map((automation) => ({
          ...automation,
          allocations: automation.allocations.filter((allocation) => {
            const hasVetoedEventIds = (
              allocation.attributes?.vetoedEventIds || []
            ).some((vetoedEventId) => eventIds.has(vetoedEventId));
            return !hasVetoedEventIds;
          }),
        }))
        // make sure we only include automations that have allocations
        .filter((automation) => automation.allocations.length > 0);

      if (filteredTokens.length > 0) {
        set(groupedTokens, actionType, {
          ...tokens[actionType],
          tokens: filteredTokens,
        });
      }

      return groupedTokens;
    }, {});
  }, [
    tokens,
    stake,
    isMulti,
    isSEM,
    stakeSource,
    odds,
    marketIds,
    eventIds,
    sports,
    marketTypes,
    seasonIds,
    tournamentIds,
  ]);
};

export { useApplicableTokens };
