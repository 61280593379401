import React from "react";
import cx from "classnames";
import OutcomeIcon from "sections/Betting/Event/OutcomeIcon";
import type { PickType } from "sections/Entries/types";

import * as styles from "./PickIcon.module.scss";
import { Silks } from "sections/Betting/Race/components";

type PickIconProps = {
  pick: PickType;
  className?: string;
};

const PickIcon = ({ pick, className }: PickIconProps) => {
  if (pick.competitor?.silksUrl) {
    return (
      <Silks
        competitorNumber={pick.competitor.number!}
        imageHeight={20}
        silksUrl={pick.competitor.silksUrl}
        skeletonSize="xs"
        className={styles.silkSpacing}
        fallbackClassName={styles.silkSpacing}
      />
    );
  }

  return (
    <OutcomeIcon
      className={cx(styles.outcomeIcon, className)}
      competitor={pick?.competitor}
      outcome={{
        active: false,
        competitorId: pick?.competitorId,
        name: pick?.outcomeName,
        odds: pick?.odds,
        result: pick?.result,
        type: pick?.outcomeType as any,
        abbreviation: pick?.competitor?.abbreviation,
      }}
      size={`20px`}
    />
  );
};

export { PickIcon };
