import React from "react";
import { Page } from "components/Page";
import { Tabs, TabsList, TabContent } from "components/Tabs";
import * as styles from "./Race.module.scss";
import { PermissionsRequired } from "library";
import { RaceMeetingDropDown } from "sections/Betting/components";
import {
  RaceInfo,
  RaceNumberTab,
  RaceTabs,
} from "sections/Betting/Race/components";
import { useRace } from "./hooks/useRace";
import RaceNumberTabSkeleton from "sections/Betting/Race/components/RaceNumberTab/RaceNumberTabSkeleton";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import { ReactComponent as RaceError } from "components/assets/racing-error.svg";
import { PageMessage } from "components/PageMessage";
import { ButtonGroup } from "components/ButtonGroup";
import { LinkButton } from "components/LinkButton";
import { useIsMobile, usePermissions } from "hooks";
import { RaceStatuses } from "sections/Betting/Race/hooks/RacingTypes";
import { useLocalStorage } from "usehooks-ts";
import { PermissionReason } from "utilities/Auth/authSlice";
import type { TabType } from "sections/Betting/Racing/hooks/useRaceRoute";
import { EventBlocked } from "sections/Betting/components/EventBlocked";

type RacePageContextType = ReturnType<typeof useRace>;

export const RaceContext = React.createContext<RacePageContextType>(undefined);

export const Race = ({
  meetingId,
  raceId,
}: RouteComponentProps<{ meetingId: string; raceId: string }>) => {
  const raceContext = useRace(meetingId, raceId);
  const isMobile = useIsMobile();
  const permissions = usePermissions();
  const [defaultRaceTab] = useLocalStorage<TabType>(
    "defaultRaceTab",
    permissions.submitTop4Bet === PermissionReason.GRANTED
      ? "top4"
      : "win-place",
  );

  if (
    (!raceContext?.raceMeeting ||
      !raceContext?.race ||
      raceContext?.race?.visible === false) &&
    !raceContext.loading
  ) {
    return (
      <PageMessage
        title="Race not found"
        image={<RaceError />}
        subTitle="There are currently no races which match your filters"
      >
        <ButtonGroup>
          <LinkButton
            variant={`primary`}
            size={isMobile ? `md` : `default`}
            to="/racing/betting/"
          >
            Next to Jump
          </LinkButton>
        </ButtonGroup>
      </PageMessage>
    );
  }

  return (
    <EventBlocked
      filters={raceContext?.raceMeeting?.filters}
      loading={raceContext?.loading}
    >
      <PermissionsRequired
        permissionsRequired={[
          "viewRacingMarkets",
          "viewBettingMarkets",
          ...(raceContext?.raceMeeting?.region === "ANZ" || raceContext?.loading
            ? []
            : ["submitInternationalRacingBet"]),
        ]}
        skeleton={<RaceNumberTabSkeleton />}
      >
        <Page
          variant={`full`}
          title={
            <RaceMeetingDropDown
              raceMeeting={
                raceContext?.raceMeeting
                  ? { ...raceContext.raceMeeting, linkTo: "race" }
                  : null
              }
            />
          }
          showSeparator={false}
          className={styles.layout}
        >
          <RaceContext.Provider value={raceContext}>
            <RaceInfo />
            {raceContext?.raceMeetingLoading ? (
              <RaceNumberTabSkeleton />
            ) : (
              <Tabs
                defaultValue={raceContext?.route?.raceId}
                value={raceContext?.route?.raceId}
                onValueChange={(value) => {
                  const destinationRaceStatus = raceContext.races.find(
                    (r) => r.id === value,
                  ).status;
                  if (
                    (destinationRaceStatus === RaceStatuses.OPEN ||
                      destinationRaceStatus === RaceStatuses.CLOSED) &&
                    (raceContext.route.tab === "results" ||
                      raceContext.route.tab === "deductions")
                  ) {
                    raceContext?.setRouteValue({
                      raceId: value,
                      tab: defaultRaceTab,
                    });
                  } else {
                    raceContext?.setRouteValue({ raceId: value });
                  }
                }}
              >
                <TabsList className={styles.tabs}>
                  {raceContext.races?.map((race) => (
                    <RaceNumberTab
                      {...race}
                      key={race.id}
                      value={`R${race.id}`}
                    />
                  ))}
                </TabsList>
                <TabContent value={`R${raceContext?.route.raceId}`} />
              </Tabs>
            )}
            <RaceTabs />
          </RaceContext.Provider>
        </Page>
      </PermissionsRequired>
    </EventBlocked>
  );
};
