// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var container = "StatusMessage-module--container--63b87";
export var content = "StatusMessage-module--content--8faf0";
export var cta = "StatusMessage-module--cta--b60a2";
export var danger = "StatusMessage-module--danger--6ce28";
export var description = "StatusMessage-module--description--c5668";
export var emphasis = "StatusMessage-module--emphasis--75538";
export var green = "#57d33b";
export var greenLink = "StatusMessage-module--green-link--f0199";
export var grey = "var(--tone-mid)";
export var hasButton = "StatusMessage-module--hasButton--4da3a";
export var icon = "StatusMessage-module--icon--2c506";
export var info = "StatusMessage-module--info--198f6";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var success = "StatusMessage-module--success--8d8ae";
export var warning = "StatusMessage-module--warning--011f4";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";