import { useEffect } from "react";
import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { depositLimit as depositLimitModel } from "hooks/firestore/v2/models/depositLimit";
import { message } from "utilities/message";
import { isPast, parseISO } from "date-fns";

const useDepositLimits = (currency: string) => {
  const {
    isLoading,
    data: limitsForAllCurrencies,
    error,
  } = useFirestore(`/users/{userId}/depositLimits`, depositLimitModel.array(), {
    keyPath: "currency",
  });

  const depositLimit = (limitsForAllCurrencies || []).find(
    (limit) => limit.currency === currency,
  );

  useEffect(() => {
    if (!depositLimit || !depositLimit.expiresAt) return;

    if (isPast(parseISO(depositLimit.expiresAt))) {
      // we do this to trigger limits update on backend
      // this makes sure that backend and firestore never gets out of sync
      message("get-deposit-limits", {});
    }
  }, [depositLimit]);

  return { depositLimit, error, isLoading };
};

export { useDepositLimits };
