import React from "react";
import cx from "classnames";
import Odds from "sections/Betting/Odds";
import { useSelections } from "hooks";
import type {
  RaceMarketTypeTypes,
  RaceOutcomeType,
} from "sections/Betting/Race/hooks/RacingTypes";
import { usePreferences } from "hooks/firestore/v2/user/usePreferences";
import type { OddsChangeType } from "library";
import { OddsChanges } from "components/OddsChanges";
import { ReactComponent as ArrowDown } from "components/assets/flucs-down.svg";
import { ReactComponent as ArrowUp } from "components/assets/flucs-up.svg";

import * as styles from "./RaceOutcome.module.scss";

export default ({
  outcomeId,
  outcome,
  disabled,
  className,
  handleToggleSelection,
  label,
  customSelections,
}: {
  outcomeId: string;
  outcome: Partial<RaceOutcomeType>;
  disabled: boolean;
  className?: string;
  handleToggleSelection: (outcomeId: string) => void;
  marketType: RaceMarketTypeTypes;
  validCompetitorCount: number;
  label?: string;
  customSelections?: string[];
  oddChangeType?: OddsChangeType;
  promotionIsAvailable?: boolean;
}) => {
  const {
    props: { selections },
  } = useSelections();

  const { preferences: userPreferences } = usePreferences();

  const selectedItems = customSelections || selections;

  // The outcome is disabled if:
  // 1. the API says so,
  // 2. We cannot find a market. getOutcome method sets active to false/
  // 3. the place market is disabled if there are only 4 competitors
  const outcomeIsDisabled = !outcome.active;

  const toggleSelection = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (outcomeIsDisabled) return;
    handleToggleSelection(outcomeId);
  };

  return (
    <OddsChanges
      odds={Number(outcome.odds)}
      animationLength={3000}
      onClick={toggleSelection}
      className={cx(
        "disable-select",
        styles.outcome,
        {
          [styles.active]: selectedItems.includes(outcomeId),
          [styles.disabled]: disabled || outcomeIsDisabled,
        },
        className,
      )}
    >
      {outcomeIsDisabled ? (
        <>
          <span className={styles.odds}>-</span>
        </>
      ) : (
        <>
          {Number(outcome.odds) === 1 ? (
            <>
              {label ? <span className={styles.label}>{label}</span> : null}
              <span
                className={cx(styles.odds, {
                  [styles.disabledOdds]: disabled || outcomeIsDisabled,
                })}
              >
                SP
              </span>
            </>
          ) : (
            <>
              {label ? <div className={styles.label}>{label}</div> : null}
              {/* Leaving this here for redesign as all the FE plumbing. At the moment its also not working 100% in firebase */}
              {/* https://puntaa.atlassian.net/wiki/spaces/SKRIL/pages/2564882488/Hows+does+the+campaign+promotion+icon+work - See markets  */}
              {/* {promotionIsAvailable && <PromoIcon />} */}
              <div className={styles.oddsText}>
                <Odds
                  base={Number(outcome.odds)}
                  zero={`-`}
                  format={userPreferences.oddsFormat.toLowerCase()}
                  className={cx(styles.odds, {
                    [styles.disabledOdds]: disabled || outcomeIsDisabled,
                  })}
                />
                <div className={styles.arrowUp}>
                  <ArrowUp />
                </div>

                <div className={styles.arrowDown}>
                  <ArrowDown />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </OddsChanges>
  );
};
