// extracted by mini-css-extract-plugin
export var alignCenter = "QuickPreferences-module--alignCenter--23ef8";
export var blue = "#0eb3f2";
export var button = "QuickPreferences-module--button--397ef";
export var emphasis = "QuickPreferences-module--emphasis--8b069";
export var flex = "QuickPreferences-module--flex--25285";
export var flex1 = "QuickPreferences-module--flex1--876ab";
export var footer = "QuickPreferences-module--footer--9229a";
export var green = "#57d33b";
export var greenLink = "QuickPreferences-module--green-link--f6e61";
export var grey = "var(--tone-mid)";
export var iconButton = "QuickPreferences-module--iconButton--1077f";
export var notchGap = "32px";
export var optionIcon = "QuickPreferences-module--optionIcon--7f637";
export var optionLabel = "QuickPreferences-module--optionLabel--d9de9";
export var orange = "#f8662b";
export var radio = "QuickPreferences-module--radio--6de71";
export var red = "#ff2741";
export var savedButton = "QuickPreferences-module--savedButton--a6bee";
export var title = "QuickPreferences-module--title--5b9ea";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";