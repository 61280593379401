import { usePermissions, useSelector, useUserEventFilters } from "hooks";
import type { Selection } from "./useSelection";

/**
 * check user can submit bets, user filters match, and permission is granted for all selections
 */
export const useIsPermittedToBet = (selection: Selection | null = null) => {
  const { submitRacingBet, submitEsportsBet, submitSportsBet, submitBet } =
    usePermissions();
  const userFilters = useUserEventFilters();
  const selections = selection
    ? { selection }
    : useSelector((state) => state.betslip.selections);

  // looking at the selections, what do they have permission to bet on?
  const hubPermissions = {
    racing: submitRacingBet === "GRANTED",
    esports: submitEsportsBet === "GRANTED",
    sports: submitSportsBet === "GRANTED",
  };

  const selectionsPermissions = Object.values(selections).map(
    (selection) => hubPermissions[selection.hub] || false,
  );

  // make sure all of these are true
  const hasPermissionToBetOnAllSelections = [
    submitBet === "GRANTED",
    ...selectionsPermissions,
  ].every((hasPermission) => hasPermission === true);

  // get all the filters from the selections (without duplicates)
  const selectionsFilters = [
    ...new Set(
      Object.values(selections)
        .map(({ filters }) => filters)
        .flat(),
    ),
  ];

  // must match all if present
  const fitsFilters =
    userFilters.length > 0
      ? userFilters.every((filter) => selectionsFilters.includes(filter))
      : true;

  return hasPermissionToBetOnAllSelections && fitsFilters;
};
