import React, { useContext } from "react";

import RaceOutcome from "../RaceOutcome/RaceOutcome";
import DeductionMarkets from "../DeductionMarkets/DeductionMarkets";
import * as styles from "./RaceOutcomes.module.scss";
import { useIsMobile } from "hooks";
import { sameRaceMultiLabelMap } from "../Top4/Top4";
import {
  RaceMarketTypeTypes,
  RaceOutcomeTypeTypes,
  RaceStatuses,
} from "sections/Betting/Race/hooks/RacingTypes";
import { OddsChangeType } from "library";
import cx from "classnames";
import { RaceContext } from "sections/Betting/Race";
import type { MappedCompetitorType } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { FlucsIcon } from "./FlucsIcon";
import { Fav } from "./Fav";

export type RaceOutcomesProps = {
  competitor: MappedCompetitorType;
  validCompetitorCount: number;
  isWinPlace?: boolean;
  oddChangeType?: OddsChangeType;
  toggleSelectionOverride?: (outcomeId: string) => void;
  customSelections?: string[];
  showLabels?: boolean;
  showPlaceOnMultiOutcome?: boolean;
  isShowingShortForm?: boolean;
};

export const RaceOutcomes = ({
  competitor,
  validCompetitorCount,
  isWinPlace = false,
  oddChangeType = OddsChangeType.racingMultiOutcome,
  toggleSelectionOverride,
  customSelections,
  showLabels = false,
  showPlaceOnMultiOutcome = false,
  isShowingShortForm,
}: RaceOutcomesProps) => {
  const isMobile = useIsMobile();
  const { race, toggleRace, topFlucs, favOdds } = useContext(RaceContext);
  const isResulted = race?.status && race?.status !== RaceStatuses.OPEN;

  const toggleSelection = (
    outcomeId: string,
    marketId: string,
    odds: string,
  ) => {
    if (toggleSelectionOverride) {
      toggleSelectionOverride(outcomeId);
    } else {
      toggleRace(outcomeId, marketId, odds);
    }
  };

  const isFavorite = Object.values(competitor?.markets || {}).some(
    (x) =>
      x.outcome.type === RaceOutcomeTypeTypes.Win && x.outcome.odds === favOdds,
  );

  const flucsPosition = topFlucs?.find(
    (x) => x.competitorId === competitor.id,
  )?.position;

  return (
    <div className={styles.outcomesContainer}>
      {isFavorite ? <Fav /> : null}
      {flucsPosition && (
        <FlucsIcon position={flucsPosition} isFavorite={isFavorite} />
      )}
      <div
        className={cx(styles.outcomes, {
          [styles.isShowingShortForm]: isShowingShortForm,
          [styles.isWinPlace]: isWinPlace,
          [styles.round]: !isWinPlace && !isMobile,
          [styles.square]: isMobile,
        })}
      >
        {competitor.deductions ? (
          <DeductionMarkets competitor={competitor} isWinPlace={isWinPlace} />
        ) : competitor.scratched && !competitor.deductions ? (
          <div
            className={cx(styles.scratching, {
              [styles.multi]: isWinPlace,
            })}
          >
            No Deductions
          </div>
        ) : (
          Object.values(competitor?.markets)?.map((m, index) => {
            if (
              m.marketType === RaceMarketTypeTypes.Place &&
              !showPlaceOnMultiOutcome
            ) {
              return null;
            }

            if (
              m.marketType === RaceMarketTypeTypes.Top2 &&
              validCompetitorCount < 4 &&
              isResulted
            ) {
              return null;
            }

            if (
              m.marketType === RaceMarketTypeTypes.Top3 &&
              validCompetitorCount < 5 &&
              isResulted
            ) {
              return null;
            }

            if (
              m.marketType === RaceMarketTypeTypes.Top4 &&
              validCompetitorCount < 6 &&
              isResulted
            ) {
              return null;
            }

            return (
              <RaceOutcome
                key={m.outcomeId ? m.outcomeId : `${competitor.id}-${index}`}
                handleToggleSelection={(outcomeId) =>
                  toggleSelection(outcomeId, m.marketId, m.outcome.odds)
                }
                marketType={m.marketType}
                disabled={race?.status !== RaceStatuses.OPEN}
                validCompetitorCount={validCompetitorCount}
                label={
                  !isWinPlace || showLabels
                    ? m.marketType === RaceMarketTypeTypes.Place
                      ? "Place"
                      : sameRaceMultiLabelMap[m.outcome.type]
                    : null
                }
                oddChangeType={oddChangeType}
                customSelections={customSelections}
                promotionIsAvailable={m.promotionIsAvailable}
                {...m}
              />
            );
          })
        )}
      </div>
    </div>
  );
};
