import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";
import { baseAutomation } from "hooks/firestore/v2/models/baseAutomation";
import { timestamp } from "hooks/firestore/v2/models/timestamp";

export const currentAutomationProgression = baseAutomation.extend({
  progressionId: z.string(),
  expiresAt: timestamp.optional(),
  name: z.string(),
  entities: z.array(
    z.object({
      entityId: z.string(),
      info: z.string(),
      status: z.string(),
      type: z.string(),
      rewardInfo: z.object({
        fiatCashRewarded: z.number(),
        promoCashRewarded: z.number(),
        projectedFiatCashReward: z.number(),
        projectedPromoCashReward: z.number(),
      }),
    }),
  ),
});

export type CurrentAutomationProgression = zInfer<
  typeof currentAutomationProgression
>;
