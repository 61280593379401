import creditCardType from "credit-card-type";
import type { EventCounts } from "hooks/firestore/v2/betting/useEventCounts";

const cardNumberValidationMap: {
  [key: string]: {
    type: "alphanumeric" | "numeric";
    required: boolean;
    min: number;
    max: number;
  };
} = {
  ACT: {
    type: "alphanumeric",
    required: true,
    min: 10,
    max: 10,
  },
  NSW: {
    type: "numeric",
    required: true,
    min: 10,
    max: 10,
  },
  NT: {
    type: "numeric",
    required: true,
    min: 6,
    max: 8,
  },
  QLD: {
    type: "alphanumeric",
    required: true,
    min: 10,
    max: 10,
  },
  SA: {
    type: "alphanumeric",
    required: true,
    min: 9,
    max: 9,
  },
  TAS: {
    type: "alphanumeric",
    required: true,
    min: 9,
    max: 9,
  },
  VIC: {
    type: "alphanumeric",
    required: true,
    min: 8,
    max: 8,
  },
  WA: {
    type: "alphanumeric",
    required: true,
    min: 8,
    max: 10,
  },
};

export const formatReadingTime = (minutes) => {
  const cups = Math.round(minutes / 5);
  if (cups > 5) {
    return `${new Array(Math.round(cups / Math.E))
      .fill("🍱")
      .join("")} ${minutes} min read`;
  } else {
    return `${new Array(cups || 1).fill("☕").join("")} ${minutes} min read`;
  }
};

export const getDisplayName = (WrappedComponent) => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

export const parseHash = (hash) => {
  if (!hash) {
    return [];
  }

  return hash.replace("#", "").split(",") || [];
};

export const buildHash = (hashArray) => {
  if (!hashArray.length) {
    return "#";
  }

  return `#${hashArray.join(",")}`;
};

export const isValidSin = (sin) => {
  const multiplyEveryOtherBy2 = (val, index) => val * ((index % 2) + 1);
  const sumValues = (acc, val) => acc + parseInt(val);

  if (typeof sin === "number") {
    sin = sin.toString();
  }

  if (sin.length !== 9) {
    return false;
  }

  // convert to an array & pop off the check digit
  sin = sin.split("");
  const check = parseInt(sin.pop());
  const sum =
    (sin
      .map(multiplyEveryOtherBy2)
      //To individual digits
      .join("")
      .split("")
      .reduce(sumValues, 0) *
      9) %
    10;
  return check === sum;
};

export const convertToNumber = (x) => parseFloat(String(x || 0)) || 0;

export const numStr = (str: string | number): string => {
  return (str + "").replace(/[^0-9\.]/g, "");
};

export const getArticleTimeToRead = (post) => {
  let result = 0;
  if (post !== null && typeof post === "object") {
    for (const key in post) {
      if (post[key]) {
        const value = post[key];
        if (key === "timeToRead") {
          return Number(value);
        }
        if (typeof value === "object") {
          result += getArticleTimeToRead(value);
        }
      }
    }
  }
  return result;
};

export const verifyAustralianLicenceNumber = (
  state: string,
  cardNumber: string,
) => {
  const validationRules = cardNumberValidationMap[state];

  if (!validationRules) return true;

  const { min, max, type, required } = validationRules;

  if (!required) return true;

  let errorMessage: string;

  if (type === "alphanumeric") {
    errorMessage = `Card number should be ${
      min !== max ? `between ${min} and ${max}` : max
    } alphanumeric characters`;
  } else {
    errorMessage = `Card number should be a valid ${min}${
      min !== max ? ` to ${max}` : ""
    } digit number`;
  }

  if (!cardNumber) return errorMessage;

  if (cardNumber.length < min || cardNumber.length > max) {
    return errorMessage;
  }

  if (type === "numeric" && isNaN(Number(cardNumber))) {
    return errorMessage;
  }

  if (type === "alphanumeric") {
    const regex = new RegExp(/^[a-z0-9]+$/i);
    if (!regex.test(cardNumber)) {
      return errorMessage;
    }
  }

  return true;
};

export const getCardNumberMaxLengthByState = (state: string) => {
  const validationRules = cardNumberValidationMap[state];

  if (!validationRules) return 0;

  const { max } = validationRules;

  return max;
};

export const getCardNumberTypeByState = (state: string) => {
  const validationRules = cardNumberValidationMap[state];

  if (!validationRules) return "alphanumeric";

  const { type } = validationRules;

  return type;
};

export const sanitizeNumber = (number: string | number) =>
  number.toString().trim().replace(" ", "");

export const getCreditCardType = (cardNumber: string | number) => {
  const potentialCardTypes = creditCardType(sanitizeNumber(cardNumber));

  if (potentialCardTypes.length === 1) {
    const firstResult = potentialCardTypes.shift();

    return firstResult?.type || "unknown";
  }

  return "unknown";
};

export const isValidPhoneNumber = async (value: string) => {
  const phoneUtil = await import("react-phone-number-input");
  return phoneUtil.isValidPhoneNumber(value);
};

export const getRelativeUrl = (url: string) => {
  if (url.startsWith("http")) {
    const urlObj = new URL(url);
    return urlObj.pathname + urlObj.search + urlObj.hash;
  }
  return url;
};

export const getTotalCountForSports = (
  eventCounts: EventCounts | undefined,
  slugs: string[],
  eventTypesFilter: string[],
) => {
  return eventCounts
    ? Object.entries(eventCounts)
        .filter(([key]) => eventTypesFilter.includes(key))
        .reduce((count, [_key, value]) => {
          return (
            count + slugs.reduce((acc2, slug) => acc2 + (value[slug] || 0), 0)
          );
        }, 0)
    : 0;
};
