import { useFirestore } from "hooks/firestore/v2/useFirestore";
import { publicProfile } from "hooks/firestore/v2/models/publicProfile";
import type { Always } from "types/utilities";

const usePublicProfile = (userId: string) => {
  const { data, isLoading, error } = useFirestore(
    `/publicUserProfiles/${userId}`,
    publicProfile,
  );

  return {
    profile: data,
    isLoading,
    error,
  };
};

type PublicProfile = Always<ReturnType<typeof usePublicProfile>["profile"]>;

export { usePublicProfile };
export type { PublicProfile };
