import { groupBy, isEmpty, mapValues } from "lodash";
import React, { useMemo, useState } from "react";
import { RaceIcon } from "../RaceIcon/RaceIcon";
import { sortRaces } from "utilities/racingUtilities";
import useRestoreScrollPosition from "hooks/useRestoreScrollPosition";
import type { RacingRouteParams } from "sections/Betting/Racing/hooks/useRacingRoute";
import { Box } from "components/Box";
import { MoreInfo } from "components/MoreInfo";
import {
  RaceMeetingEventSEOSchema,
  RaceRow,
  RowTemplate,
} from "sections/Betting/Racing/components/";
import { selectedTitlesToSportMapping } from "sections/Betting/Race/hooks/useRacingEvents";
import {
  type RaceMeetingType,
  useRaceMeetings,
} from "sections/Betting/Race/hooks/useRaceMeetings";
import type { RaceEventType } from "sections/Betting/Race/hooks/RacingTypes";
import { PageMessage } from "components/PageMessage";
import { ButtonGroup } from "components/ButtonGroup";
import { Button } from "components/Button";
import { useIsMobile } from "hooks";
import { ReactComponent as OddsUnavailable } from "components/assets/icon-empty-odds.svg";
import { RacesByDaySkeleton } from "sections/Betting/Racing/components/RacesByDay/RacesByDaySkeleton";

import * as styles from "./RacesByDay.module.scss";

const sportLabels = {
  HORSE_RACING: "Thoroughbred Racing",
  GREYHOUNDS: "Greyhound Racing",
  HARNESS_RACING: "Harness Racing",
};

const sportRanking = {
  HORSE_RACING: 1,
  GREYHOUNDS: 2,
  HARNESS_RACING: 3,
};

const regionRanking = {
  ANZ: 1,
  ROW: 2,
};

const mapRaceMeetingToRace = (
  raceMeeting: RaceMeetingType,
): Partial<RaceEventType> => {
  const {
    sport,
    region,
    venue,
    country,
    state,
    trackType,
    currentRace,
    id: meetingId,
  } = raceMeeting;

  return {
    ...currentRace,
    sport,
    region,
    venue,
    country,
    state,
    trackType,
    meetingId,
  } as Partial<RaceEventType>;
};

const SportTypeBox = ({
  region,
  sport,
}: {
  region: Record<string, RaceMeetingType[]>;
  sport: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Box
      className={styles.box}
      title={sportLabels[sport]}
      clampClassName={styles.clamp}
      clamp={
        <MoreInfo
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      }
      icon={<RaceIcon sport={sport} className={styles.sportIcon} />}
      key={sportLabels[sport]}
    >
      {isExpanded &&
        Object.entries(region || {})
          .sort(([regionA], [regionB]) =>
            regionRanking[regionA] > regionRanking[regionB] ? 1 : -1,
          )
          .map(([_, raceMeetings]) =>
            // map a meeting into a race event
            raceMeetings
              .map(mapRaceMeetingToRace)
              .sort(sortRaces)
              .map((race) => {
                return (
                  <RaceRow
                    {...(race as any)}
                    key={race.id}
                    template={RowTemplate.DAY}
                  />
                );
              }),
          )}
    </Box>
  );
};

export default ({
  route,
  setRouteValue,
}: {
  route: Partial<RacingRouteParams>;
  setRouteValue: (arg: Partial<RacingRouteParams>) => void;
}) => {
  const isMobile = useIsMobile();
  const [raceMeetings, loading] = useRaceMeetings(route);
  const mappedTitles = selectedTitlesToSportMapping(route.sports || []);

  useRestoreScrollPosition(loading);

  const groupedRaces = useMemo(() => {
    return mapValues(
      groupBy(
        Object.values(raceMeetings || {}).filter((x) =>
          route.sports ? mappedTitles?.includes(x.sport) : true,
        ),
        (raceMeeting) => raceMeeting.sport,
      ),
      (sport) => groupBy(sport, (raceMeeting) => raceMeeting.region),
    );
  }, [raceMeetings, route.sports, mappedTitles]);

  if (loading) {
    return <RacesByDaySkeleton />;
  }

  return (
    <>
      {isEmpty(groupedRaces) && !loading && (
        <div className={styles.unavailable}>
          <PageMessage
            title="No Races Found"
            image={<OddsUnavailable />}
            subTitle="There are currently no races which match your filters"
          >
            <ButtonGroup>
              <Button
                variant={`primary`}
                size={isMobile ? `md` : `default`}
                onClick={() => setRouteValue({ tab: "next-to-jump" })}
              >
                Next to Jump
              </Button>
            </ButtonGroup>
          </PageMessage>
        </div>
      )}

      {Object.entries(groupedRaces || {})
        .sort(([sportA], [sportB]) =>
          sportRanking[sportA] > sportRanking[sportB] ? 1 : -1,
        )
        .map(([sport, region]) => (
          <SportTypeBox sport={sport} region={region} key={sport} />
        ))}

      <RaceMeetingEventSEOSchema
        races={raceMeetings?.map(mapRaceMeetingToRace)}
      />
    </>
  );
};
