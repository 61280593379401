// extracted by mini-css-extract-plugin
export var alert = "BetslipFooterBulkActionBar-module--alert--a9a59";
export var alignCenter = "BetslipFooterBulkActionBar-module--alignCenter--ad495";
export var blue = "#0eb3f2";
export var bulkActionBar = "BetslipFooterBulkActionBar-module--bulkActionBar--ec8be";
export var emphasis = "BetslipFooterBulkActionBar-module--emphasis--249db";
export var flex = "BetslipFooterBulkActionBar-module--flex--cd621";
export var flex1 = "BetslipFooterBulkActionBar-module--flex1--ffadf";
export var green = "#57d33b";
export var greenLink = "BetslipFooterBulkActionBar-module--green-link--7cb69";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var roundBtn = "BetslipFooterBulkActionBar-module--roundBtn--5fd22";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";