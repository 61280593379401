import React, { useContext, useEffect } from "react";
import { Tab, TabContent, Tabs, TabsList } from "components/Tabs";
import { RaceContext } from "sections/Betting/Race";
import {
  DeductionsInformation,
  MyBets,
  RaceResult,
  SameRaceMulti,
  Top4,
  WinPlace,
} from "sections/Betting/Race/components";
import type { TabType } from "sections/Betting/Racing/hooks/useRaceRoute";
import { useRaceTabVisibility } from "sections/Betting/Race/hooks/useRaceTabVisibility";
import { TabPromoIcon } from "components/PromoIcon/TabPromoIcon";
import { BettingTabs } from "hooks";
import RaceTabsSkeleton from "sections/Betting/Race/components/RaceTabs/RaceTabsSkeleton";
import { ExoticTabs } from "./ExoticTabs";
import { Badge } from "components/Badge";
import { ExoticDividends } from "../RaceResult/ExoticDividends";
import { useIsSRMAvailable } from "sections/Betting/Race/hooks/useIsSRMAvailable";

type RaceTabConfig = {
  show: boolean;
  value: string;
  label: React.ReactNode;
  tabType?: BettingTabs;
  count?: number;
  eventHasPromoBanner: boolean;
};

const RaceTab = ({ tab, eventHasPromoBanner = false }: RaceTabConfig) => {
  const { campaigns } = useContext(RaceContext);

  const Badge = tab.tabType ? (
    <TabPromoIcon
      tab={tab.tabType}
      campaigns={campaigns}
      eventHasPromoBanner={eventHasPromoBanner}
    />
  ) : null;

  return (
    <Tab value={tab.value} badge={tab?.count ? tab?.count?.toString() : null}>
      {tab.label}
      {Badge}
    </Tab>
  );
};

const RaceTabs = () => {
  const {
    race,
    setRouteValue,
    route,
    myBets,
    raceMeetingLoading,
    loading,
    forceSp,
    setForceSp,
  } = useContext(RaceContext);

  const [_, srmUnavailableMessage] = useIsSRMAvailable();

  const {
    showTop4,
    showSRM,
    showWinPlace,
    showDeductions,
    showExotics,
    raceIsActive,
    defaultRaceTab,
  } = useRaceTabVisibility();

  const tabConfig: TabConfig[] = [
    {
      show: showTop4,
      value: "top4",
      label: "Top 4",
      tabType: BettingTabs.RACING_SINGLES,
    },
    {
      show: showSRM,
      value: "same-race-multi",
      label: "Same Race Multi",
      tabType: BettingTabs.RACING_SEM, // used for calculating if the promo icon should be shown
    },
    {
      show: Boolean(showExotics),
      value: "exotics",
      label: (
        <>
          Exotics
          <Badge text="New" variant="success" />
        </>
      ),
      tabType: BettingTabs.RACING_EXOTICS, // used for calculating if the promo icon should be shown
    },
    {
      show: showWinPlace,
      value: "win-place",
      label: "Win/Place",
      tabType: BettingTabs.RACING_SINGLES, // used for calculating if the promo icon should be shown
    },
    {
      show: !raceIsActive,
      value: "results",
      label: "Results",
      tabType: undefined,
    },
    {
      show: Boolean(showDeductions),
      value: "deductions",
      label: "Deductions",
      tabType: undefined,
    },
    {
      show: myBets?.length > 0,
      value: "my-bets",
      label: "My Bets",
      tabType: undefined,
      count: myBets?.length > 0 ? myBets?.length : undefined,
    },
  ];

  useEffect(() => {
    if (!showExotics && route?.tab === "exotics" && !loading) {
      // exotics are volatile and can be pulled at any time. If this changes whilst
      // the user is already on that tab, kick them back to the defualt
      setRouteValue({ tab: defaultRaceTab });
    }
  }, [showExotics, loading]);

  useEffect(() => {
    if (srmUnavailableMessage && route?.tab === "same-race-multi") {
      setRouteValue({ tab: "top4" });
    }
  }, [srmUnavailableMessage]);

  if (raceMeetingLoading) {
    return <RaceTabsSkeleton />;
  }

  return (
    <Tabs
      defaultValue={defaultRaceTab}
      value={route?.tab}
      onValueChange={(value) => {
        const tabType = value as TabType;

        // if we are switching to the SRM tab then we need to toggle off the
        // forceSp flag as that will prevent the SRM markets from showing
        if (tabType === "same-race-multi" && forceSp) {
          setForceSp(false);
        }

        setRouteValue({ tab: tabType });
      }}
    >
      <TabsList>
        {tabConfig.map(
          (tab) =>
            tab.show && (
              <RaceTab
                tab={tab}
                key={tab.value}
                eventHasPromoBanner={!!race?.showPromoBanner}
              />
            ),
        )}
      </TabsList>
      <TabContent value="top4">
        <Top4 />
      </TabContent>
      <TabContent value="same-race-multi">
        <SameRaceMulti />
      </TabContent>
      <TabContent value="win-place">
        <WinPlace />
      </TabContent>
      <TabContent value="results">
        <RaceResult />
        <ExoticDividends />

        <Top4 showResulted={true} />
      </TabContent>
      <TabContent value="deductions">
        <Top4 onlyScratchings />
        <DeductionsInformation />
      </TabContent>
      <TabContent value="exotics">
        <ExoticTabs />
      </TabContent>
      <TabContent value="my-bets">
        <MyBets />
      </TabContent>
    </Tabs>
  );
};

export default RaceTabs;
