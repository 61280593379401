import { useMemo } from "react";
import { useDisplayAtLeast, useSelector, useDisplayMode } from "hooks";
import { useHeaderOffset } from "hooks/useHeaderOffset";
import useWindowSize from "hooks/events/useWindowSize";

const HEIGHTS = {
  TABS: {
    BETSLIP: 60,
    HUD: 59,
  },
  BETSLIP_FOOTER: {
    SMALL: 75,
    LARGE: 125,
  },
  MOBILE_NAV_ON_TABLET: 70,
};

export enum SlipType {
  Bet = "bet",
  HUD = "hud",
}

interface SlipHeightOptions {
  slipType: SlipType;
  betslipFooter?: boolean;
  betsFooter?: boolean;
  betslipIsReviewingMessages?: boolean;
}

const useSlipHeight = ({
  slipType,
  betslipFooter = false,
  betsFooter = false,
  betslipIsReviewingMessages = false,
}: SlipHeightOptions) => {
  const { height: fullHeight } = useWindowSize();
  const isMinDesktop = useDisplayAtLeast("desktop");
  const displayMode = useDisplayMode();

  const topOffsetWithoutNav = useHeaderOffset();
  const topOffsetWithNav =
    topOffsetWithoutNav +
    useSelector((state) => state.utilities.ui.navigationHeaderHeight);

  const headerOffset = useMemo(
    () => (isMinDesktop ? topOffsetWithNav : topOffsetWithoutNav),
    [isMinDesktop, topOffsetWithoutNav, topOffsetWithNav],
  );

  return useMemo(() => {
    const tabsHeight =
      slipType === SlipType.Bet ? HEIGHTS.TABS.BETSLIP : HEIGHTS.TABS.HUD;

    // - when we are in desktop mode, betslip should be 100% minus header + tabs
    // - when we are in mobile mode, we are not showing header, so we need to account for that
    // - when media player is opened it's accounted for in headerOffset
    const baseHeight = (fullHeight || 0) - tabsHeight - (headerOffset || 0);

    // For betslip
    if (slipType === SlipType.Bet) {
      // when we are on bets & entries tab OR reviewing messages, we display small footer
      // we need to reduce betslip height by additional number of pixels
      if (betsFooter || betslipIsReviewingMessages) {
        return baseHeight - HEIGHTS.BETSLIP_FOOTER.SMALL;
      }
      // when we are showing full sized footer, we need to reduce betslip size as well
      if (betslipFooter) {
        // betslip footer is larger than bets footer
        return baseHeight - HEIGHTS.BETSLIP_FOOTER.LARGE;
      }
      return baseHeight;
    }

    // For HUD (pickslip or search)
    return baseHeight;
  }, [
    fullHeight,
    betsFooter,
    betslipFooter,
    isMinDesktop,
    betslipIsReviewingMessages,
    headerOffset,
    slipType,
    displayMode,
  ]);
};

export { useSlipHeight };
