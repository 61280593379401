import React from "react";
import type { SubOutcome } from "sections/Entries/types";
import { Silks } from "sections/Betting/Race/components/Silks";
import SportOutcomeIcon from "sections/Betting/Event/OutcomeIcon";
import cx from "classnames";

import * as styles from "./OutcomeIcon.module.scss";

const OutcomeIcon = ({
  outcome,
  silksUrl,
  className,
}: {
  outcome: SubOutcome;
  silksUrl?: string;
  className?: string;
}) => {
  if (silksUrl) {
    const competitorNumber =
      outcome?.competitor?.number || Number(outcome?.attributes?.runnerNumber);

    return (
      <Silks
        competitorNumber={competitorNumber}
        imageHeight={20}
        silksUrl={silksUrl}
        skeletonSize="xs"
        className={styles.silkSpacing}
        fallbackClassName={styles.silkSpacing}
      />
    );
  }

  return (
    <SportOutcomeIcon
      competitor={outcome?.competitor}
      outcome={outcome as any}
      isSelected={false}
      size={`20px`}
      className={cx(styles.outcomeIcon, className)}
    />
  );
};

export { OutcomeIcon };
