import React, { useContext } from "react";

import cx from "classnames";
import {
  type RaceCompetitorType,
  RaceMarketTypeTypes,
  type RaceOutcomeType,
} from "sections/Betting/Race/hooks/RacingTypes";

import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import * as styles from "./WinPlace.module.scss";
import { Competitor } from "../Competitor";
import { RaceMessage } from "sections/Betting/Race/components/RaceMessage";
import { RaceFilters } from "sections/Betting/Race/components/RaceFilters";
import SPMessage from "sections/Betting/Race/components/SPMessage/SPMessage";
import { useSortedCompetitors } from "sections/Betting/Race/hooks/useSortedCompetitors";
import { RaceTips } from "sections/Betting/Race/components/RaceTips";
import { CampaignPromotions } from "sections/Betting/CampaignPromotions";
import { BettingTabs } from "hooks";
import { RaceContext } from "sections/Betting/Race/Race";
import CompetitorSkeleton from "sections/Betting/Race/components/Competitor/CompetitorSkeleton";
import { RaceCustomMarkets } from "components/RaceCustomMarkets";

export type WinPlaceCompetitorType = RaceCompetitorType &
  Partial<RaceOutcomeType>;

const marketsRequired = [
  RaceMarketTypeTypes.Win, // win
  RaceMarketTypeTypes.Place, // place
];

export const WinPlace = ({
  onlyScratchings = false,
}: RouteComponentProps<{ onlyScratchings?: boolean }>) => {
  const { race, loading, campaigns } = useContext(RaceContext);
  const { mappedCompetitors, isSP, validCompetitorCount, hasActiveMarkets } =
    useSortedCompetitors({
      marketsRequired,
    });

  const filteredCompetitors = mappedCompetitors.filter((x) =>
    onlyScratchings ? x.scratched : true,
  );

  return (
    <>
      <CampaignPromotions
        tab={BettingTabs.RACING_SINGLES}
        campaigns={campaigns || []}
      />
      <RaceFilters
        showForceSpToggle={hasActiveMarkets && race?.status === "OPEN"}
        isSP={isSP}
      />
      <RaceTips />
      <RaceCustomMarkets />
      <div className={cx(styles.content)}>
        {loading ? (
          <CompetitorSkeleton displayType="Win" />
        ) : (
          <>
            {filteredCompetitors.map((competitor) => (
              <Competitor
                key={competitor.id}
                competitor={competitor}
                validCompetitorCount={validCompetitorCount}
                isWinPlace={true}
                showPlaceOnMultiOutcome={true}
              />
            ))}
          </>
        )}
        {isSP && <SPMessage className={styles.alert} />}
        <RaceMessage className={styles.alert} />
      </div>
    </>
  );
};
