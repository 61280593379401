import React, { useEffect } from "react";
import type { RouteComponentProps } from "@gatsbyjs/reach-router";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import {
  getCardNumberMaxLengthByState,
  getCardNumberTypeByState,
  verifyAustralianLicenceNumber,
} from "utilities/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "components/Typography";
import { Link } from "components/Link";
import { Form } from "components/Form";
import { Input } from "components/Input";
import { NativeSelect } from "components/NativeSelect";
import { DateInput } from "components/DateInput";
import { Checkbox } from "components/Checkbox";
import { Button } from "components/Button";
import { useAccountActions } from "hooks/firestore/user/useAccounts";
import { VerificationDocumentType } from "hooks";
import { navigate } from "library";
import { userVerificationSchema } from "./schemas";
import { useProfile } from "hooks/firestore/v2/user/useProfile";

import * as styles from "./AustralianDriverLicense.module.scss";

const schema = userVerificationSchema.shape({
  state: Yup.string().required("State is required"),
  number: Yup.string().required("A valid driver's licence number is required"),
  cardNumber: Yup.string().test(
    "cardNumber",
    "A valid card number is required",
    (cardNumber, { parent: { state }, createError }) => {
      const validation = verifyAustralianLicenceNumber(state, cardNumber);

      return typeof validation === "string"
        ? createError({ message: validation })
        : validation;
    },
  ),
});

type FormValues = Yup.InferType<typeof schema>;

type AustralianDriverLicenceProps = {
  prefix?: string;
} & RouteComponentProps;

const AustralianDriverLicence = ({
  prefix = "/account",
}: AustralianDriverLicenceProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const { profile } = useProfile();

  useEffect(() => {
    if (profile) {
      reset({
        firstName: profile.firstName,
        lastName: profile.lastName,
        dateOfBirth: profile.dateOfBirth,
        state: profile.state,
      });
    }
  }, [profile]);

  const { doSubmitVerification } = useAccountActions();

  const consent = watch("consent", false);

  return (
    <>
      <Form
        onSubmit={handleSubmit(async ({ consent, ...fields }: FormValues) => {
          const isSuccess = await doSubmitVerification({
            terms: consent,
            type: VerificationDocumentType.AUSTRALIAN_DRIVERS_LICENCE,
            fields,
          });

          if (isSuccess) {
            navigate("/account/verification");
          }
        })}
        fixedWidth
      >
        <Typography variant={`h4`} noSpacing>
          Australian Drivers Licence
        </Typography>
        <Typography variant={`body`}>
          Please enter your identity information, exactly as it appears on your
          Australian Drivers Licence.
        </Typography>
        <Typography variant={`body`}>
          Don’t have a Drivers Licence? Select from a list of{" "}
          <Link to={`${prefix}/verification`}>Verification options</Link>.
        </Typography>
        <Input
          {...register("number")}
          label={`Licence Number`}
          errorMessage={errors?.number?.message}
          maxLength={10}
        />

        <Input
          {...register("cardNumber")}
          label={
            <>
              <div>Card Number</div>{" "}
              <div className={styles.cardNumber}>
                Click{" "}
                <Link
                  to="https://www.usi.gov.au/about/forms-id/drivers-licence"
                  target="_blank"
                >
                  here
                </Link>{" "}
                for help identifying your card number
              </div>
            </>
          }
          errorMessage={errors?.cardNumber?.message}
          maxLength={getCardNumberMaxLengthByState(watch("state"))}
          inputMode={
            getCardNumberTypeByState(watch("state")) === "numeric"
              ? "numeric"
              : "text"
          }
        />
        <NativeSelect
          options={[
            { value: "ACT", label: "Australian Capital Territory" },
            { value: "NSW", label: "New South Wales" },
            { value: "NT", label: "Northern Territory" },
            { value: "QLD", label: "Queensland" },
            { value: "SA", label: "South Australia" },
            { value: "TAS", label: "Tasmania" },
            { value: "VIC", label: "Victoria" },
            { value: "WA", label: "Western Australia" },
          ]}
          label={`State`}
          {...register("state")}
          errorMessage={errors?.state?.message}
        />
        <Input
          {...register("firstName")}
          label={`First Name`}
          errorMessage={errors?.firstName?.message}
        />
        <Input
          {...register("lastName")}
          label={`Last Name`}
          errorMessage={errors?.lastName?.message}
        />
        <Controller
          control={control}
          name={"dateOfBirth"}
          render={({ field: { ref: _, ...field } }) => (
            <DateInput
              id={"dateOfBirth"}
              label="Date of Birth"
              {...field}
              errorMessage={errors.dateOfBirth?.message}
              hideLabel
            />
          )}
        />
        <Controller
          control={control}
          render={({ field: { onChange, value: _, ...field } }) => (
            <Checkbox
              id={`consent`}
              label={`I consent to Picklebet checking the information above with the
            issuer of Official Record Holder.`}
              checked={consent}
              onCheckedChange={(checked) => onChange?.(checked === true)}
              hasError={!!errors?.consent?.message}
              {...field}
            />
          )}
          name={`consent`}
        />
        <Button type={`submit`} loading={isSubmitting}>
          Verify Licence
        </Button>
      </Form>
    </>
  );
};

export { AustralianDriverLicence };
