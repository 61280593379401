import React, { type ReactNode, useEffect } from "react";
import { List, ListItem } from "components/List";

import * as styles from "sections/Betting/Betslip/components/QuickPreferences/QuickPreferences.module.scss";
import type { AcceptOddsPreferences } from "hooks/firestore/v2/user/usePreferences";

export type OptionsProps = {
  options: {
    value: AcceptOddsPreferences;
    display: ReactNode;
  }[];
  selectedOption: AcceptOddsPreferences;
  setSelectedOption: (option: AcceptOddsPreferences) => void;
};

const OptionIcon = ({ isActive }: { isActive: boolean }) => {
  return (
    <div className={styles.optionIcon}>
      <div data-active={isActive} className={styles.radio} />
    </div>
  );
};

const Options = ({
  options,
  setSelectedOption,
  selectedOption,
}: OptionsProps) => {
  useEffect(() => {
    // enable first option by default once available
    if (options.length > 1 && !selectedOption) {
      setSelectedOption(options[0].value);
    }
  }, [options]);

  return (
    <List>
      {options.map((option, index) => {
        return (
          <ListItem
            key={`change-setting-option-${index}`}
            onClick={() => setSelectedOption(option.value)}
            label={
              <div className={`${styles.flex} ${styles.alignCenter}`}>
                <OptionIcon isActive={selectedOption === option.value} />
                {option.display}
              </div>
            }
          ></ListItem>
        );
      })}
    </List>
  );
};

export { Options };
