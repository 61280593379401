import { useEffect, useState } from "react";
import { VERSION } from "../appConfig";
import { getDeviceId } from "../store/getDeviceId";
import { migrateProfile } from "../utilities/User/Actions";
import type { Profile } from "./firestore/user/types";
import {
  restoreSession,
  showProfileCompletionModal,
  updateSession,
} from "../utilities/Auth/authSlice";
import {
  useDispatch,
  useSelector,
  useIsLoggedIn,
  usePermission,
} from "./index";
import { useProfile } from "./firestore/v2/user/useProfile";
import { isWebView } from "../utilities/display";
import { WEB_TO_APP_MESSAGE_TYPES } from "../utilities/AppWebMessagingBridge/Constants";
import { sendAppMessage } from "../utilities/AppWebMessagingBridge/Actions";
import { useIsClient } from "usehooks-ts";
import * as Sentry from "@sentry/gatsby";

const identifyAnalytics = (profile: Profile, dispatch) => {
  if (typeof window === "undefined") {
    // only enable analytics in browser
    return;
  }

  // Segment - We keep both events as sentry and full story and both still active in the webview.
  if (window?.analytics?.initialized) {
    // https://segment.com/docs/connections/spec/best-practices-identify/#anonymous-history-is-lost - Fix for Mixpanel
    window.analytics.identify(profile.userId, {
      username: profile.username,
      persistentDeviceId: getDeviceId(),
      clientVersion: VERSION,
    });
  }

  // add user context to sentry
  Sentry.setUser({ id: profile.userId });

  if (isWebView()) {
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_ANALYTICS_IDENTIFY, {
      userId: profile.userId,
      username: profile.username,
      persistentDeviceId: getDeviceId(),
      clientVersion: VERSION,
    });
  }

  // We need to migrate profile if migration version is out of date
  if (!profile.migration || profile.migration < 24) {
    dispatch(migrateProfile());
  }
};

const resetAnalytics = () => {
  if (typeof window === "undefined") {
    return;
  }

  // Reset segment
  if (window.analytics) {
    window.analytics.reset();
  }

  // reset Sentry user context
  Sentry.setUser(null);

  if (isWebView()) {
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_ANALYTICS_RESET, {});
  }

  if (window.mixpanel) {
    // Reset user identity for mixpanel
    //Segment is currently configured to use mixpanel with Connection Mode = 'Device Mode'. Segment loads the MP js library
    // and events are send directly to mix panel. So we need to explicitly reset the mixpanel user aswell as the segment user.
    //This could be removed if we switched the connection mode to Cloud. Unsure of the other impacts of this.
    window.mixpanel.reset();
  }
};

const useAuthState = (): [Profile | undefined, boolean] => {
  const isLoggedIn = useIsLoggedIn();
  const isClient = useIsClient();
  const { profile, isLoading } = useProfile();
  const dispatch = useDispatch();

  const isUpdatingSession = useSelector(
    (state: any) => state.auth.updatingSession,
  );

  const [restoredSession, setRestoredSession] = useState(null);
  const submitBetPermission = usePermission("submitBet");

  useEffect(() => {
    if (!restoredSession) {
      return;
    }

    if (submitBetPermission === "INCOMPLETE_PROFILE") {
      dispatch(showProfileCompletionModal());
    }

    dispatch(restoreSession({ ...restoredSession }));
    setRestoredSession(null);
  }, [restoredSession]);

  useEffect(() => {
    if (profile && profile?.userId && !isUpdatingSession) {
      identifyAnalytics(profile, dispatch);
    } else {
      resetAnalytics();
    }
  }, [profile]);

  useEffect(() => {
    if (isUpdatingSession || !isClient) {
      return;
    }

    if (isLoggedIn) {
      dispatch(updateSession({ isGuest: false }));
    } else {
      dispatch(updateSession({ isGuest: true }));
    }
  }, [isClient]);

  useEffect(() => {
    if (isLoading || !profile || isUpdatingSession) {
      return;
    }
    dispatch(updateSession({ isGuest: false }));
  }, [profile?.updatedAt, isLoading]);

  return [profile, isLoading];
};

export default useAuthState;
