import React from "react";
import { Alert } from "./Alert";
import { usePermission, useDispatch } from "hooks";
import { Clamp } from "components/Clamp";
import { ReactComponent as LocationBlock } from "assets/icons/style-two-pin-off-map.svg";
import { ReactComponent as ClockIcon } from "assets/icons/time-clock-circle.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock-6.svg";
import { openChat } from "utilities/HelpCenter/helpCenterSlice";
import { Link } from "components/Link";
import { PermissionReason } from "utilities/Auth/authSlice";

import * as styles from "./UnauthorizedAlerts.module.scss";

const CooldownAlert = () => {
  return (
    <Alert
      icon={<ClockIcon />}
      variant={`danger`}
      clamp={
        <Clamp asChild variant={`danger`}>
          <Link to={`/preferences/cooldown`}>Learn More</Link>
        </Clamp>
      }
    >
      Your account is in Cooldown
    </Alert>
  );
};

const UnauthorizedPickemsAlerts = () => {
  const submitPickems = usePermission("submitPickems");

  if (submitPickems === "COOLDOWN") {
    return <CooldownAlert />;
  }

  return null;
};

const UnauthorizedAlerts = () => {
  const dispatch = useDispatch();
  const submitBet = usePermission("submitBet");
  const submitFreeRoster = usePermission("submitFreeRoster");
  const pickemsAvailable = submitFreeRoster === PermissionReason.GRANTED;

  const locationUnsupported = [
    "PHYSICAL_LOCATION_UNSUPPORTED",
    "RESIDENTIAL_LOCATION_UNSUPPORTED",
  ].includes(submitBet);

  return (
    <>
      {submitBet === "BLOCKED" && (
        <Alert
          icon={<LocationBlock />}
          variant={`danger`}
          clamp={
            <Clamp
              className={styles.clickable}
              onClick={() => dispatch(openChat())}
              variant={`danger`}
            >
              Contact Support
            </Clamp>
          }
        >
          Your account is under review
        </Alert>
      )}

      {submitBet === "COOLDOWN" && <CooldownAlert />}

      {locationUnsupported && (
        <Alert
          icon={<LocationBlock />}
          variant={`danger`}
          clamp={
            pickemsAvailable ? (
              <Clamp asChild variant={`danger`}>
                <Link to={`/pickems`}>Try Pick'ems</Link>
              </Clamp>
            ) : null
          }
        >
          Betting is not yet available in your location
        </Alert>
      )}

      {submitBet === "ACCOUNT_REOPEN_REQUIRED" && (
        <Alert
          icon={<LockIcon />}
          variant={`danger`}
          clamp={
            <Clamp asChild variant={`danger`}>
              <Link to={`/account/reopen`}>Reopen</Link>
            </Clamp>
          }
        >
          Your account requires reopening
        </Alert>
      )}

      {submitBet === "VERIFICATION_STATUS_UNSUPPORTED" && (
        <Alert
          icon={<LockIcon />}
          variant={`danger`}
          clamp={
            <Clamp asChild variant={`danger`}>
              <Link to={`/account/verification`}>Verify</Link>
            </Clamp>
          }
        >
          Your account requires verification
        </Alert>
      )}
    </>
  );
};

export { UnauthorizedAlerts, UnauthorizedPickemsAlerts };
