import type { Timestamp } from "firebase/firestore";
import type { RaceRegionTypes, RaceSportTypes } from "./useRacingEvents";
import type { CampaignsType } from "hooks/firestore/v2/models/bettingEvent";
import type { Event } from "hooks/firestore/v2/betting";

export enum RaceStatuses {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
  INTERIM = "INTERIM",
  PROTEST = "PROTEST",
  FINAL = "FINAL",
  DONE = "DONE",
  JUMPED = "JUMPED",
  ABANDONED = "ABANDONED",
}

export type ExoticDividend = Record<
  number,
  {
    dividend: number;
    outcomeIds: string[];
    runners: number[];
  }
>;

export type RaceEventType = {
  id: string;
  distance: string;
  meetingDate: string;
  meetingId: string;
  name: string;
  number: number;
  scheduledStartTime: Date;
  status: RaceStatuses;
  venue: string;
  silksUrl: string;
  results: Record<string, string>;
  comment?: string;
  sport: RaceSportTypes;
  region: RaceRegionTypes;
  promotionIsAvailable: boolean;
  country: string;
  state?: string;
  trackType?: string;
  semAvailableAtTs?: Timestamp;
  filters?: string[];
  exoticResults: Record<ExoticMarketType, ExoticDividend>;
  visible?: boolean;
  promotionCampaigns?: Array<CampaignsType>;
  hasPromotionalMarkets: boolean;
  showPromoBanner: boolean;
  expectedResults: Record<string, number>;
};

export type FormAttributesTypes =
  | "speedMapPosition"
  | "s123Career"
  | "s123Distance"
  | "s123Course"
  | "s123CourseDistance"
  | "s123Good"
  | "s123Fast"
  | "s123Slow"
  | "s123Heavy"
  | "s123Dirt"
  | "last6Runs"
  | "jockeyClaim"
  | "careerPrizeMoney"
  | "color"
  | "sire"
  | "dam"
  | "gears"
  | "rating";

export type RaceCompetitorType = {
  id: string;
  number: number;
  name: string;
  jockey: string;
  trainer: string;
  weight: string;
  age: string;
  comment: string;
  startingPosition: string;
  formAttributes: Record<FormAttributesTypes, string>;
  sex: "G|M|F";
  scratchedAt?: Date;
  scratched?: boolean;
  winDeduction?: string;
  placeDeduction?: string;
  deductions?: Record<RaceMarketTypeTypes, number>;
};

export type RaceOutcomeType = {
  id: string;
  active: boolean;
  competitorId?: string;
  name: string;
  odds: string;
  openingOdds?: string;
  result: "WIN" | "LOSE" | "VOID" | "UNDECIDED";
  type: RaceOutcomeTypeTypes | string;
  recentOdds?: number[];
  attributes?: {
    runnerPosition: string;
    runnerNumber: string;
  };
};

export enum RaceOutcomeTypeTypes {
  Win = "RUNNER_WIN",
  Top2 = "RUNNER_TOP_2",
  Top3 = "RUNNER_TOP_3",
  Top4 = "RUNNER_TOP_4",
}

export const exoticMarketTypes = [
  "QUINELLA",
  "EXACTA",
  "TRIFECTA",
  "FIRST_4",
] as const;
export type ExoticMarketType = (typeof exoticMarketTypes)[number];

export enum RaceMarketTypeTypes {
  Win = "RACING_WINNER",
  WinSP = "RACING_WINNER_SP",

  Place = "RACING_PLACE",
  PlaceSP = "RACING_PLACE_SP",

  Top2 = "RACING_TOP_2",
  Top3 = "RACING_TOP_3",
  Top4 = "RACING_TOP_4",
  Custom = "CUSTOM",
}

export type RaceMarketsType = {
  id: string;
  bettingType: "Prematch";
  name: string;
  marketType: RaceMarketTypeTypes | ExoticMarketType;
  campaignOnly: boolean;
  promotional: boolean;
  campaignIds?: string[];
  outcomes: Record<string, RaceOutcomeType>;
  status: "ACTIVE" | "DEACTIVATED";
  attributes?: Partial<
    Record<"custom" | "multiable" | "promotionCashDisabled", string>
  > & { exotic: boolean };
  nextBetStop: Date;
  promotionIsAvailable: boolean;
  promotionCampaigns: Event["promotionCampaigns"];
  hasPromotionalMarkets: boolean;
  showPromoBanner: boolean;
  active: boolean;
};
