import { useState, useCallback } from "react";
import { useIsomorphicLayoutEffect } from "usehooks-ts";
import { useSelector, useDispatch } from "hooks";
import debounce from "lodash/debounce";
import { setWindowSize } from "utilities/UI/uiSlice";

const useGlobalWindowSize = () => {
  const dispatch = useDispatch();
  const windowSize = useSelector((state) => state.utilities.ui.windowSize);

  const handleResize = useCallback(
    debounce(() => {
      dispatch(
        setWindowSize({
          height: window.innerHeight,
          width: window.innerWidth,
        }),
      );
    }, 200),
    [],
  );

  useIsomorphicLayoutEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const useWindowSize = () => {
  const windowSize = useSelector((state) => state.utilities.ui.windowSize);
  return windowSize;
};

export { useGlobalWindowSize };

export default useWindowSize;
