import React from "react";
import { useProfile } from "hooks/firestore/v2/user/useProfile";
import { PageMessage } from "components/PageMessage";
import { SupportLink } from "components/SupportLink";
import { ReactComponent as PendingIcon } from "components/assets/warning-pending.svg";
import { Typography } from "components/Typography";
import NextBettingButtons from "components/layout/NextBettingButtons/NextBettingButtons";

import * as styles from "../Verification.module.scss";

type PendingReviewProps = {
  children: React.ReactNode;
};

const PendingReview = ({ children }: PendingReviewProps) => {
  const { profile } = useProfile();
  const isPendingReview = profile?.verificationStatus === "PENDING_REVIEW";

  if (isPendingReview) {
    return (
      <PageMessage
        title={`Verification Pending`}
        image={<PendingIcon />}
        subTitle={
          <>
            <Typography
              className={styles.pageMessageText}
              variant={`body`}
              noSpacing
            >
              Verification pending review by{" "}
              <SupportLink hideChevron options={{ articleId: "115000814991" }}>
                Support{" "}
              </SupportLink>
            </Typography>
            <Typography
              className={styles.pageMessageText}
              variant={`body`}
              noSpacing
            >
              Jump into a contest, or place a bet, while you wait.
            </Typography>
          </>
        }
      >
        <NextBettingButtons />
      </PageMessage>
    );
  }

  return <>{children}</>;
};

export { PendingReview };
