import { useEffect, useState } from "react";
import { isFuture, isPast, parseISO } from "date-fns";
import { useFirestore } from "hooks/firestore/v2/useFirestore";
import {
  statusMessage,
  type StatusMessage as StatusMessageModel,
} from "hooks/firestore/v2/models/statusMessage";

import type { Always } from "types/utilities";
import { useInterval } from "usehooks-ts";

const filterStatusMessages = (
  statusMessages: StatusMessageModel[] | undefined,
) => {
  if (!statusMessages) return;

  const filteredMessages = statusMessages.filter((message) => {
    const startDate = parseISO(message.scheduledStartTime);
    const endDate = message?.scheduledExpiryTime
      ? parseISO(message.scheduledExpiryTime)
      : null;

    if (isFuture(startDate)) {
      return false;
    }

    if (endDate && isPast(endDate)) {
      return false;
    }

    return true;
  });

  if (filteredMessages.length === 0) return;

  return filteredMessages;
};

const useStatusMessages = () => {
  const { data, isLoading, error } = useFirestore(
    "/statusMessages",
    statusMessage.array(),
    {
      keyPath: "id",
    },
  );

  const [statusMessages, setStatusMessages] = useState<typeof data>();

  useInterval(() => {
    if (!data) return;

    setStatusMessages(filterStatusMessages(data));
  }, 10000); 

  useEffect(() => {
    // when server data changes we want to re-calculate the state
    setStatusMessages(filterStatusMessages(data));
  }, [data]);

  return { statusMessages, isLoading, error };
};

type StatusMessage = Always<
  ReturnType<typeof useStatusMessages>["statusMessages"]
>[0];

export { useStatusMessages, type StatusMessage };
