import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";

export const statusMessage = z.object({
  id: z.string(),
  description: z.string(),
  type: z.enum(["INFO", "WARNING", "DANGER", "SUCCESS"]),
  moreInfoUrl: z.string().optional(),
  scheduledStartTime: z.string(),
  scheduledExpiryTime: z.string().nullish(),
});

export type StatusMessage = zInfer<typeof statusMessage>;
