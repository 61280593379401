import React, { type ReactNode } from "react";
import cx from "classnames";
import { TabFilter, HUDPanel } from "library";
import type { IContentItem } from "types/ContentTypes";
import type { MessageType } from "library";
import { ScrollArea } from "components/ScrollArea";

import * as styles from "./HUDScrollPanel.module.scss";
import {
  SlipType,
  useSlipHeight,
} from "sections/Betting/Betslip/hooks/useSlipHeight";

type HudScrollPanelProps = {
  title: string | JSX.Element;
  count?: number;
  className?: string;
  tabs?: IContentItem[];
  contentHeader?: JSX.Element;
  contentNotifications?: ReactNode | ReactNode[];
  contentBody?: ReactNode;
  contentFooter?: ReactNode;
  messageType?: MessageType;
  onTabSelect?: (id: string) => void;
  canClose?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

export const HUDScrollPanel = ({
  title,
  count,
  onClose,
  className,
  canClose,
  contentHeader,
  tabs,
  onTabSelect,
  contentNotifications,
  contentBody,
  contentFooter,
  children,
}: HudScrollPanelProps) => {
  const slipHeight = useSlipHeight({ slipType: SlipType.HUD });

  const headerElems = (
    <div>
      {contentHeader}
      {tabs && tabs.length > 0 && (
        <TabFilter
          className={styles.tabs}
          onSelect={(selected) => onTabSelect && onTabSelect(selected)}
          tabs={tabs}
        />
      )}
    </div>
  );

  const bodyElems = (
    <div className="hud-scroll-body" style={{ minHeight: slipHeight }}>
      {children}
      {contentBody}
    </div>
  );

  const footerElems = contentFooter && (
    <div className="hud-scroll-footer">{contentFooter}</div>
  );

  return (
    <HUDPanel
      title={title}
      count={count}
      className={cx(styles.HUDScrollPanel, className)}
      onClose={onClose}
      contentHeader={headerElems}
      canClose={canClose}
    >
      <div className={styles.hudScrollContainer} style={{ height: slipHeight }}>
        {contentNotifications ? (
          <div className="hud-notifications">{contentNotifications}</div>
        ) : null}
        <ScrollArea className="hud-scroll-area">{bodyElems}</ScrollArea>
        {footerElems}
      </div>
    </HUDPanel>
  );
};
