// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var customImage = "Share-module--customImage--74c3a";
export var emphasis = "Share-module--emphasis--8943f";
export var green = "#57d33b";
export var greenLink = "Share-module--green-link--64183";
export var grey = "var(--tone-mid)";
export var layout = "Share-module--layout--f8dca";
export var loading = "Share-module--loading--1bf61";
export var notchGap = "32px";
export var orange = "#f8662b";
export var pageMessage = "Share-module--pageMessage--77b30";
export var red = "#ff2741";
export var title = "Share-module--title--66289";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";