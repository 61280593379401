import * as Avatar from "@radix-ui/react-avatar";
import cx from "classnames";
import { Skeleton, type SkeletonProps } from "components/Skeleton";
import React, { useMemo, useState } from "react";

import * as styles from "./Silks.module.scss";

type SilksProps = {
  silksUrl?: string;
  imageHeight: number;
  competitorNumber: number;
  fallbackClassName?: string;
  skeletonSize?: SkeletonProps["size"];
  className?: string;
};

type RadixImageLoadingStatus = Parameters<
  NonNullable<Avatar.AvatarImageProps["onLoadingStatusChange"]>
>[0];

export const Silks = ({
  silksUrl,
  competitorNumber,
  imageHeight,
  fallbackClassName,
  skeletonSize = "sm",
  className = "",
}: SilksProps) => {
  const [imageFetchingStatus, setImageFetchingStatus] =
    useState<RadixImageLoadingStatus>("idle");

  const silkStyle = useMemo(
    () => ({
      width: `${imageHeight}px`,
      height: `${imageHeight}px`,
      backgroundImage: `url(${silksUrl})`,
      backgroundSize: `cover`,
      backgroundPosition: `${(competitorNumber - 1) * -imageHeight}px 0px`,
      backgroundRepeat: `no-repeat`,
    }),
    [silksUrl, competitorNumber],
  );

  return (
    <Avatar.Root title={`Competitor number: ${competitorNumber}`}>
      <Avatar.Image
        src={silksUrl}
        asChild
        onLoadingStatusChange={(status) => setImageFetchingStatus(status)}
      >
        <div style={silkStyle} />
      </Avatar.Image>

      {imageFetchingStatus === "loading" ? (
        <Skeleton
          size={skeletonSize}
          mode="light"
          shape="circle"
          className={cx(styles.skeletonMargin, className)}
        />
      ) : (
        <Avatar.Fallback className={cx(styles.fallback, fallbackClassName)}>
          {competitorNumber}
        </Avatar.Fallback>
      )}
    </Avatar.Root>
  );
};
