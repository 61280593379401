import { signInWithCustomToken, signOut } from "firebase/auth";

import moment from "moment/moment";
import { getDeviceId } from "store/getDeviceId";
import { auth } from "store/getFirebase";
import lastSession, { type ILastSession } from "utilities/lastSession";
import { fetchWithAmznWaf as protectedFetch } from "utilities/api/fetchWithAmznWaf";

const QUEUE_ERROR = "QUEUE_ERROR";

type ValidationError = {
  message: string;
  messageTemplate: string;
  propertyPath: string;
};

const headers = {
  "Content-Type": "application/json",
  "X-DeviceId": getDeviceId(),
};

export async function login(
  email: string,
  password: string,
  register = false,
  confirmPassword = null,
  refreshFirebaseToken = true,
) {
  const path = register ? "/users" : "/auth";
  try {
    const response = await protectedFetch(process.env.GATSBY_API_URI + path, {
      method: "POST",
      mode: "cors",
      headers,
      body: register
        ? JSON.stringify({
            email,
            password,
            confirmPassword,
            deviceId: getDeviceId(),
          })
        : JSON.stringify({ email, password, deviceId: getDeviceId() }),
    });

    const json = await response.json();

    // handle errors
    if (!response.ok) {
      const errors = json.errors.reduce((errors, e) => {
        if (!e.propertyPath) {
          return errors;
        }
        return { ...errors, [e.propertyPath]: e.message };
      }, {});

      const messageTemplate = json.errors
        .map((e) => e.messageTemplate)
        .join(", ");

      throw Object.assign(
        {},
        {
          message: json.errors.map((e) => e.message).join(", "),
          messageTemplate,
          errors,
        },
      );
    }

    if (refreshFirebaseToken) {
      const userCredentials = await signInWithCustomToken(
        auth,
        json.firebaseToken.token,
      );
      console.log(
        `Obtained firebase credentials for user: ${userCredentials?.user?.uid}. Is anonymous: ${userCredentials?.user?.isAnonymous}`,
      );
    }

    // store last user id used to simplify login form
    lastSession.set({
      uid: json.firebaseToken.uid,
      email,
    } as ILastSession);

    // return token, firebase token and permissions
    return json;
  } catch (error) {
    // deal with fetch errors
    if (
      error instanceof TypeError ||
      (error.code && error.code === "auth/invalid-custom-token")
    ) {
      throw Object.assign(
        {},
        {
          message: "Infrastructure error. Please try again later.",
          errors: {},
        },
      );
    }

    throw error;
  }
}

export async function requestPasswordReset(email, dateOfBirth) {
  const response = await fetch(
    process.env.GATSBY_API_URI + "/auth/reset-password",
    {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify({ email, dateOfBirth }),
    },
  );

  if (response.status === 200) {
    return response.json();
  }

  if (response.status === 204) {
    return null;
  }

  const json = await response.json();

  throw Object.assign(
    {},
    {
      type: QUEUE_ERROR,
      created: moment().toDate(),
      id: json.messageId || null,
      body: json,
    },
  );
}

export async function revokeSession(token) {
  const response = await fetch(
    process.env.GATSBY_API_URI + "/auth/revoke-session",
    {
      method: "POST",
      mode: "cors",
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    },
  );

  if (response.status === 200) {
    return;
  }

  if (response.status === 204) {
    return;
  }

  if (response.status === 401) {
    //ignore this as probably means session is invalid anyway
    return;
  }

  const json = await response.json();

  throw Object.assign(
    {},
    {
      type: QUEUE_ERROR,
      created: moment().toDate(),
      id: json.messageId || null,
      body: json,
    },
  );
}

export async function emailUnsubscribe(email, userId) {
  const response = await fetch(
    process.env.GATSBY_API_URI + `/users/mixpanelUnsubscribe`,
    {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify({
        email: email,
        userId: userId,
      }),
    },
  );

  if (response.ok) {
    return {
      success: true,
    };
  }

  return {
    success: false,
  };
}

export async function resetPassword(
  token: string,
  newPassword: { newPassword: string },
) {
  const response = await protectedFetch(
    process.env.GATSBY_API_URI + `/auth/reset-password/${token}`,
    {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify(newPassword),
    },
  );

  if (response.status === 200) {
    return response.json();
  }

  if (response.status === 204) {
    return null;
  }

  const json = await response.json();

  throw Object.assign(
    {},
    {
      type: QUEUE_ERROR,
      created: moment().toDate(),
      id: json.messageId || null,
      body: json,
    },
  );
}

export const logout = () => {
  return signOut(auth);
};

export async function validateUser<T>(dataToValidate: T) {
  const response = await fetch(
    process.env.GATSBY_API_URI + `/users/validations`,
    {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify(dataToValidate),
    },
  );

  if (response.status === 200) {
    return response.json();
  }

  if (response.status === 204) {
    return null;
  }
}

export const validateProfile = async (
  email: string,
  password: string,
  profile: any,
): Promise<ValidationError[]> => {
  const response = await fetch(
    `${process.env.GATSBY_API_URI}/customers/validations`,
    {
      method: "POST",
      mode: "cors",
      headers,
      body: JSON.stringify({
        email,
        password,
        confirmPassword: password,
        profile,
      }),
    },
  );

  if (response.status === 200) {
    return [];
  }

  const json = await response.json();
  const allowedKeys = [
    email ? "email" : null,
    password ? "password" : null,
    ...Object.keys(profile),
  ].filter(Boolean);

  return (json?.errors || []).filter(
    (error) =>
      allowedKeys.includes(error.propertyPath) ||
      allowedKeys.includes(error.propertyPath.replace("profile.", "")),
  );
};

export const signUp = async (
  email: string,
  password: string,
  profile: any,
  deviceId: string,
) => {
  const response = await fetch(`${process.env.GATSBY_API_URI}/customers`, {
    method: "POST",
    mode: "cors",
    headers,
    body: JSON.stringify({
      email,
      password,
      confirmPassword: password,
      deviceId,
      profile,
    }),
  });

  const json = await response.json();

  // handle errors
  if (!response.ok) {
    const errors = json.errors.reduce((errors, e) => {
      if (!e.propertyPath) {
        return errors;
      }
      return { ...errors, [e.propertyPath]: e.message };
    }, {});

    const messageTemplate = json.errors
      .map((e) => e.messageTemplate)
      .join(", ");
    throw Object.assign(
      {},
      {
        message: json.errors.map((e) => e.message).join(", "),
        messageTemplate,
        errors,
      },
    );
  }

  const userCredentials = await signInWithCustomToken(
    auth,
    json.firebaseToken.token,
  );
  console.log(
    `Obtained firebase credentials for user: ${userCredentials?.user?.uid}. Is anonymous: ${userCredentials?.user?.isAnonymous}`,
  );

  // store last user id used to simplify login form
  lastSession.set({
    uid: json.firebaseToken.uid,
    email,
  } as ILastSession);

  return json;
};

export async function getSession(token) {
  if (typeof fetch === "undefined") {
    return null;
  }
  const headers = token
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    : {
        "Content-Type": "application/json",
      };
  const response = await fetch(process.env.GATSBY_API_URI + "/auth", {
    method: "GET",
    mode: "cors",
    headers,
  });
  const body = await response.json();
  if (response.ok) {
    return body;
  } else {
    console.log("Error getting session data:", body);
    throw body;
  }
}

export default {
  login,
  requestPasswordReset,
  resetPassword,
  logout,
  validateUser,
  emailUnsubscribe,
};
