import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";
import { currency } from "./currency";

/*
    Automation is what runs both tokens and triggers on backend.
    They are very different things on the frontend side of things but share the same data shape.
    This model holds all possible variations and token and automation models then pick and choose what they need.
 */

const userDrivenAttributes = z.object({
  // Deposit
  minimumAmount: z.number().optional(),
  provider: z
    .enum([
      "SKRILL",
      "MERCHANT_WARRIOR",
      "MERCHANT_WARRIOR_PAY_ID",
      "MERCHANT_WARRIOR_APPLE_PAY",
      "MERCHANT_WARRIOR_GOOGLE_PAY",
      "MANUAL",
      "WITHDRAWAL_BOUNCE_BACK",
      "PAYMENT_IQ",
      "POLI",
    ])
    .optional(),
  currency: currency.optional(),
  numOfDeposits: z.number().optional(),
  cumulativeAmount: z.number().optional(),
  firstDeposit: z.boolean().optional(),

  // Non SEM Bet Stake

  minimumSelections: z.number().optional(),
  maximumSelections: z.number().optional(),
  minimumSelectionOdds: z.number().optional(),

  // SEM Bet Stake
  minimumOutcomes: z.number().optional(),
  maximumOutcomes: z.number().optional(),

  // Shared
  minimumExpectedResults: z
    .array(
      z.object({
        minimum: z.number(),
        resultType: z.string(),
      }),
    )
    .optional(),
  numOfBetsRequired: z.number().optional(),
  minimumStake: z.number().optional(),
  maximumStake: z.number().optional(),
  marketIds: z.array(z.string()).optional(),
  allMarketIdsMustMatch: z.boolean().optional(),
  marketTypeNames: z.array(z.string()).optional(),
  allMarketTypeNamesMustMatch: z.boolean().optional(),
  eventIds: z.array(z.string()).optional(),
  allEventIdsMustMatch: z.boolean().optional(),
  meetingIds: z.array(z.string()).optional(),
  allMeetingIdsMustMatch: z.boolean().optional(),
  seasonIds: z.array(z.string()).optional(),
  allSeasonIdsMustMatch: z.boolean().optional(),
  tournamentIds: z.array(z.string()).optional(),
  allTournamentIdsMustMatch: z.boolean().optional(),
  stakeSource: z.enum(["AVAILABLE", "PROMOTION_USER"]).optional(),
  minimumOdds: z.number().optional(),
  currencies: z.array(currency).optional(),
  sports: z.array(z.string()).optional(),
  allSportsMustMatch: z.boolean().optional(),
  timesAllowedToCompletePerEvent: z.number().optional(),
  timesAllowedToCompletePerEventFilter: z.array(z.string()).optional(),
  excludedMarketIds: z.array(z.string()).optional(),
  excludedMarketTypeNames: z.array(z.string()).optional(),
  excludedEventIds: z.array(z.string()).optional(),
  excludedMeetingIds: z.array(z.string()).optional(),
  excludedSeasonIds: z.array(z.string()).optional(),
  excludedTournamentIds: z.array(z.string()).optional(),
  excludedSports: z.array(z.string()).optional(),
  excludedEventTypes: z
    .array(z.enum(["SEASON", "TOURNAMENT", "MATCH", "RACE"]))
    .optional(),
  excludePromotionalMarkets: z.boolean().optional(),
});

const automationComponent = z.object({
  type: z.enum([
    "BETTING_EVENT_RESULTED",
    "GIVE_CASH",
    "ODDS_BOOST",
    "GIVE_PROMOTION",
    "DEPOSIT_MATCH",
    "STAKE_REFUND",
    "WINNINGS_BOOST",
    "GIVE_TOKEN",
    "GIVE_CAMPAIGN",
    "DEPOSIT",
    "NON_SEM_BET_STAKE",
    "SEM_BET_STAKE",
    "COMBO_MULTI_BET_STAKE",
    "NON_SEM_BET_SETTLE",
    "NON_SEM_BET_FINALISE",
    "COMBO_MULTI_BET_SETTLE",
    "COMBO_MULTI_BET_FINALISE",
    "SEM_BET_SETTLE",
    "BET_VOID",
    "BET_ROLLBACK",
    "WITHDRAW",
    "SIGNUP",
  ]),
  info: z.string(),
  projectedRewardInfo: z
    .object({
      max: z.number().nullable().optional(),
      percent: z.number().nullable().optional(),
      isPromo: z.boolean().nullable().optional(),
    })
    .optional()
    .nullable(),
  status: z.string().optional().nullable(),
  attributeInfos: z.record(z.string(), z.string()).optional().nullable(),
  userDrivenAttributes: userDrivenAttributes.optional().nullable(),
});

export const baseAutomation = z.object({
  name: z.string(),
  automationId: z.string(),
  campaign: z.object({
    bettingTabs: z.array(
      z.enum([
        "SPORTS_SINGLES",
        "SPORTS_SEM",
        "RACING_SINGLES",
        "RACING_SEM",
        "RACING_EXOTICS",
        "RACING_TRIFECTA",
        "RACING_FIRST_4",
        "RACING_EXACTA",
        "RACING_QUINELLA",
      ]),
    ),
    termsOfUse: z.string().nullish(),
    name: z.string(),
    openToAllEligibleUsers: z.boolean(),
    description: z.string().optional(),
    provider: z.enum(["INTERNAL", "BRAZE"]),
    status: z.enum(["ACTIVE", "COMPLETED"]),
  }),
  requirements: z.array(automationComponent),
  actions: z.array(automationComponent),
  requirementInfos: z.array(z.string()).optional(),
});

export type BaseAutomation = zInfer<typeof baseAutomation>;
