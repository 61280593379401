import { useFirestore } from "../useFirestore";
import { campaign } from "hooks/firestore/v2/models/campaign";
import { useMemo } from "react";

const useCampaigns = () => {
  const {
    isLoading,
    data: codes,
    error,
  } = useFirestore(`/users/{userId}/campaigns`, campaign.array(), {
    keyPath: "id",
  });

  const filteredCodes = useMemo(
    () => (codes || []).filter((code) => code.active).map((code) => code.id),
    [codes, isLoading],
  );

  return { codes: filteredCodes, error, isLoading };
};

export { useCampaigns };
