// extracted by mini-css-extract-plugin
export var away = "Outcome-module--away--b0825";
export var blue = "#0eb3f2";
export var centerAlign = "Outcome-module--centerAlign--8cbed";
export var competitor = "Outcome-module--competitor--7d190";
export var container = "Outcome-module--container--8cfa7";
export var detail = "Outcome-module--detail--c9136";
export var disabled = "Outcome-module--disabled--9c9c0";
export var draw = "Outcome-module--draw--41671";
export var emphasis = "Outcome-module--emphasis--a92fc";
export var fullWidthCompetitor = "Outcome-module--fullWidthCompetitor--63f8d";
export var fullWidthContainer = "Outcome-module--fullWidthContainer--447ab";
export var fullWidthDetail = "Outcome-module--fullWidthDetail--d036f";
export var fullWidthName = "Outcome-module--fullWidthName--e4442";
export var fullWidthOdds = "Outcome-module--fullWidthOdds--17851";
export var fullWidthOutcome = "Outcome-module--fullWidthOutcome--04d72";
export var green = "#57d33b";
export var greenLink = "Outcome-module--green-link--eed14";
export var grey = "var(--tone-mid)";
export var home = "Outcome-module--home--59805";
export var isSelected = "Outcome-module--isSelected--8d2d7";
export var leftAlign = "Outcome-module--leftAlign--020c5";
export var lost = "Outcome-module--lost--b6635";
export var name = "Outcome-module--name--0e040";
export var notchGap = "32px";
export var odds = "Outcome-module--odds--a2707";
export var orange = "#f8662b";
export var outcome = "Outcome-module--outcome--3be81";
export var outright = "Outcome-module--outright--505f3";
export var punted = "Outcome-module--punted--688bb";
export var red = "#ff2741";
export var selected = "Outcome-module--selected--f7837";
export var selectedAgainst = "Outcome-module--selectedAgainst--248a3";
export var suspended = "Outcome-module--suspended--4c136";
export var unclickable = "Outcome-module--unclickable--f10b1";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var won = "Outcome-module--won--d8d14";
export var yellow = "#ffb100";