import React from "react";
import cx from "classnames";
import type { Event as EventType } from "hooks/firestore/betting/useBetting";
import { Icon, Link } from "library";
import { TitleIcon } from "components/TitleIcon";
import type { Title } from "hooks/graphql/useTitles";

import * as styles from "./Event.module.scss";

type OutrightProps = {
  outright: EventType;
  title: Title;
};

export const Outright = ({ outright, title }: OutrightProps) => {
  return (
    <Link
      to={`${outright.hub === "sports" ? "/sports" : ""}/betting/outright/${
        outright.eventId
      }`}
    >
      <div className={cx(styles.row, styles.outright)}>
        <div className={styles.info}>
          <div className={styles.icon}>
            <TitleIcon title={title} svg />
          </div>
          <div className={styles.description}>
            <div className={styles.game}>{title?.name}</div>
            <div className={styles.season}>
              {outright.attributes?.tournamentName ||
                outright.attributes?.seasonName ||
                outright.eventName ||
                ""}
            </div>
            <div className={styles.round}>
              {outright.attributes?.roundName?.replace("_", " ")}
              {outright.attributes?.matchMode &&
                " " + outright.attributes?.matchMode}
            </div>
          </div>
        </div>
        <div>
          {outright.activeMarketCount > 0 && (
            <div className={styles.matchLink}>
              {outright.activeMarketCount}
              <Icon type="chevronRight" inline />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};
