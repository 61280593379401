import { useMemo } from "react";
import { useFirestore } from "../useFirestore";
import { accountBalance } from "hooks/firestore/v2/models/accountBalance";
import { useFiatCurrency } from "hooks/firestore/v2/user/useFiatCurrency";
import { constructCurrency } from "common";

type Balance = {
  balanceInCents: number;
};

type Accounts = Record<
  string,
  {
    available: Balance;
    promo: Balance;
    holding: Balance;
  }
>;

const useAccountBalances = () => {
  const {
    isLoading,
    data: accounts,
    error,
  } = useFirestore(`/users/{userId}/accountBalances`, accountBalance.array(), {
    keyPath: "currency",
  });

  const cleanAccounts = useMemo(
    () =>
      (accounts || []).reduce<Accounts>((result, account) => {
        const currency = account.currency.toLowerCase();
        result[currency] = {
          available: {
            balanceInCents: account.AVAILABLE,
          },
          promo: {
            balanceInCents: account.PROMOTION_USER ?? 0,
          },
          holding: {
            balanceInCents: account.HOLDING,
          },
        };

        return result;
      }, {}),
    [accounts, isLoading],
  );

  return { accounts: cleanAccounts, error, isLoading };
};

type Account = Accounts[keyof Accounts];

const useCashAvailable = () => {
  const { accounts, isLoading } = useAccountBalances();
  const fiatCurrency = useFiatCurrency();

  const account =
    accounts && fiatCurrency
      ? accounts[fiatCurrency.toLowerCase()]
      : { available: { balanceInCents: 0 }, promo: { balanceInCents: 0 } };

  const available = useMemo(
    () => account?.available?.balanceInCents ?? 0,
    [account],
  );
  const promo = useMemo(() => account?.promo?.balanceInCents ?? 0, [account]);

  return {
    currency: fiatCurrency,
    available,
    promo,
    isLoading,
  };
};

const usePrettyCashAvailable = () => {
  const { currency, available, promo, isLoading } = useCashAvailable();

  const availableFormatted = useMemo(
    () =>
      constructCurrency(available, {
        currencyType: currency,
        showDecimals: true,
        showCurrencySymbol: true,
      }),
    [available, currency],
  );

  const promoFormatted = useMemo(
    () =>
      constructCurrency(promo, {
        currencyType: currency,
        showDecimals: true,
        showCurrencySymbol: true,
      }),
    [promo, currency],
  );

  return {
    available: availableFormatted,
    promo: promoFormatted,
    isLoading,
    currency,
  };
};

export { useAccountBalances, useCashAvailable, usePrettyCashAvailable };
export type { Account };
