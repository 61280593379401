// THESE SHOULD SYNC UP WITH '{projectHome}/apps/src/utility/AppMessageBridge/index.ts'

// These do not change!!!
export const PRIMARY_MESSAGE_TYPES = {
  APP_TO_WEB_MESSAGE: "APP_TO_WEB_MESSAGE",
  WEB_TO_APP_MESSAGE: "WEB_TO_APP_MESSAGE",
};

// WEB TO APP MESSAGES
export enum WEB_TO_APP_MESSAGE_TYPES {
  APP_MESSAGE_PLAY_STREAM = "APP_MESSAGE_PLAY_STREAM",
  APP_MESSAGE_ANALYTICS_EVENT = "APP_MESSAGE_ANALYTICS_EVENT",
  APP_MESSAGE_START_APPLE_PAY = "APP_MESSAGE_START_APPLE_PAY",
  APP_MESSAGE_FINISH_APPLE_PAY = "APP_MESSAGE_FINISH_APPLE_PAY",
  APP_MESSAGE_GOOGLE_PAY = "APP_MESSAGE_GOOGLE_PAY",
  APP_MESSAGE_RELOAD = "APP_MESSAGE_RELOAD",
  APP_SHARE_CONTENT = "APP_SHARE_CONTENT",
  APP_MESSAGE_ANALYTICS_IDENTIFY = "APP_MESSAGE_ANALYTICS_IDENTIFY",
  APP_MESSAGE_ANALYTICS_RESET = "APP_MESSAGE_ANALYTICS_RESET",
  // tell the app that it is ready to receive messages
  APP_MESSAGE_WEB_IS_READY = "APP_MESSAGE_WEB_IS_READY",
  APP_MESSAGE_OPEN_EXTERNALLY = "APP_MESSAGE_OPEN_EXTERNALLY",
  APP_MESSAGE_LOG_CONTENT_CARD_IMPRESSION = "APP_MESSAGE_LOG_CONTENT_CARD_IMPRESSION",
  APP_MESSAGE_LOG_CONTENT_CARD_CLICK = "APP_MESSAGE_LOG_CONTENT_CARD_CLICK",
  APP_MESSAGE_MW3DS_CHALLENGE = "APP_MESSAGE_MW3DS_CHALLENGE",
  APP_MESSAGE_MW3DS_FINGERPRINT = "APP_MESSAGE_MW3DS_FINGERPRINT",
}

// APP TO WEB MESSAGES
export enum APP_TO_WEB_MESSAGE_TYPES {
  APP_TO_WEB_MESSAGE_NAVIGATE = "APP_TO_WEB_MESSAGE_NAVIGATE",
  APP_TO_WEB_MESSAGE_BACK = "APP_TO_WEB_MESSAGE_BACK",
  APP_TO_WEB_MESSAGE_SET_ATTRIBUTION = "APP_TO_WEB_MESSAGE_SET_ATTRIBUTION",
  APP_TO_WEB_MESSAGE_APPLE_PAY_TOKEN = "APP_TO_WEB_MESSAGE_APPLE_PAY_TOKEN",
  APP_TO_WEB_MESSAGE_APPLE_PAY_AVAILABLE = "APP_TO_WEB_MESSAGE_APPLE_PAY_AVAILABLE",
  APP_TO_WEB_MESSAGE_GOOGLE_PAY_AVAILABLE = "APP_TO_WEB_MESSAGE_GOOGLE_PAY_AVAILABLE",
  APP_TO_WEB_MESSAGE_GOOGLE_PAY_TOKEN = "APP_TO_WEB_MESSAGE_GOOGLE_PAY_TOKEN", // deprecated: remains to support existing apps prior to v 13.0.x
  APP_TO_WEB_MESSAGE_GOOGLE_PAY_TOKEN_V2 = "APP_TO_WEB_MESSAGE_GOOGLE_PAY_TOKEN_V2",
  APP_TO_WEB_MESSAGE_SENTRY_LOG = "APP_TO_WEB_MESSAGE_SENTRY_LOG",
  APP_TO_WEB_MESSAGE_CUSTOM_SLIDEUP_IAM = "APP_TO_WEB_MESSAGE_CUSTOM_SLIDEUP_IAM",
  APP_TO_WEB_MESSAGE_BRAZE_CONTENT_CARDS = "APP_TO_WEB_MESSAGE_BRAZE_CONTENT_CARDS",
  APP_TO_WEB_MESSAGE_MW3DS_CHALLENGE_READY = "APP_TO_WEB_MESSAGE_MW3DS_CHALLENGE_READY",
  APP_TO_WEB_MESSAGE_MW3DS_FINGERPRINT_READY = "APP_TO_WEB_MESSAGE_MW3DS_FINGERPRINT_READY",
}
