import React, { useEffect, useState } from "react";
import { navigate, PageLoader } from "library";
import {
  type VerificationOutcome,
  useOnTransitionedVerificationStatus,
} from "hooks/firestore/user/useOnTransitionedVerificationStatus";
import { setModal } from "utilities/UI/uiSlice";
import { useDispatch } from "hooks";
import { trackAttemptedVerification } from "utilities/Auth/authSlice";
import { useProfile } from "hooks/firestore/v2/user/useProfile";

const Verification = () => {
  const [attemptedVerification, setAttemptedVerification] =
    useState<VerificationOutcome>("unknown");
  const { profile } = useProfile();

  const dispatch = useDispatch();

  useOnTransitionedVerificationStatus((outcome) => {
    const payload = {
      returningNSER: profile?.returningNSER,
      country: profile?.country,
      state: profile?.state,
    };

    if (outcome === "passed") {
      dispatch(
        trackAttemptedVerification({
          result: "success",
          ...payload,
        }),
      );
      setAttemptedVerification("passed");
    } else {
      dispatch(
        trackAttemptedVerification({
          result: "failure",
          ...payload,
        }),
      );
      setAttemptedVerification("failed");
    }
  });

  useEffect(() => {
    (async () => {
      if (attemptedVerification === "failed") {
        // once we've attempted verification, navigate to the verification page to show a final status
        await navigate("/account/verification");
        setTimeout(() => dispatch(setModal(null)), 1000);
      } else if (attemptedVerification === "passed") {
        await navigate("/wallet/deposit");
        setTimeout(() => dispatch(setModal(null)), 1000);
      }
    })();
  }, [attemptedVerification]);

  return (
    <>
      <PageLoader
        text={
          attemptedVerification === "unknown"
            ? `Verifying your identity...`
            : attemptedVerification === "failed"
              ? `Verification failed.`
              : `Verified!`
        }
      />
    </>
  );
};

export { Verification };
