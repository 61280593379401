export const API_STRINGS = {
  // Notifications
  "insufficient-entry-funds": `You have insufficient funds to pay the entry fee, deposit to play!`,
  "insufficient-withdrawal-funds": `You have insufficient funds in your withdrawable balance to complete the withdrawal.`,
  "invalid-withdrawal-amount": `The amount you are trying to withdraw is invalid.`,
  "user-verification-required": `Your identity must first be verified in order to withdraw funds.
    Please contact support.`,
  "contest-not-found": `We were unable to find the specified contest in the database.`,
  "contest-not-pending": `You can only perform this action on a contest that hasn't started yet!`,
  "roster-exceeds-rosters-per-user": `You cannot submit any more rosters to this contest.`,
  "roster-exceeds-rosters-per-contest": `This contest is full, please try another one.`,
  "invalid-bet-amount": `You have exceeded your maximum bet amount.`,
  "unsupported-currency": `Your residence currently prohibits entry into contests of this currency type`,
  "threeds-enrollment-failed": `Your card is not enrolled in 3DS`,

  // Betting Types
  WIN_LOSS: `Head to Head`,
  WIN_DRAW_LOSS: `Head to Head with Draw`,
  FIRST_BLOOD: `First Blood`,
};

export function getString(key) {
  let string = key;

  if (API_STRINGS[key]) {
    string = API_STRINGS[key];
  }

  return string;
}

export default getString;
