// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Silks-module--emphasis--abcbd";
export var fallback = "Silks-module--fallback--688d6";
export var green = "#57d33b";
export var greenLink = "Silks-module--green-link--9810e";
export var grey = "var(--tone-mid)";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var skeletonMargin = "Silks-module--skeletonMargin--7befd";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";