import { useCanViewPromotions, useIsLoggedIn, useIsMobile } from "hooks";

import { FireTransforms } from "utilities/FireTransforms";
import { useHub } from "hooks/graphql/useHubs";
import { usePageContext } from "hooks/usePageContext";
import { useMemo } from "react";
import { useBrazeContentCards } from "store/Braze";
import { useIsClient } from "usehooks-ts";

export const useBanners = () => {
  const isLoggedIn = useIsLoggedIn();

  const isClient = useIsClient();

  const isMobile = useIsMobile();
  const canViewPromotions = useCanViewPromotions();
  const banners = useBrazeContentCards();
  const { hub: hubSlug } = usePageContext();
  const hub = useHub(hubSlug);
  const name = hub?.name ?? "Pickems";

  return useMemo(() => {
    if (!isClient) {
      return [];
    }
    return banners /*?*/
      .filter(({ extras }) => {
        try {
          const {
            isPromotion,
            esportsPage,
            racingPage,
            sportsPage,
            pickemsPage,
            showLoggedIn,
            showLoggedOut,
          } = extras;

          const isPromotionAsBool =
            FireTransforms.parseBool(isPromotion) ?? true;
          const showOnEsportsPage =
            FireTransforms.parseBool(esportsPage) ?? true;
          const showOnRacingPage = FireTransforms.parseBool(racingPage) ?? true;
          const showOnSportsPage = FireTransforms.parseBool(sportsPage) ?? true;
          const showOnPickemsPage =
            FireTransforms.parseBool(pickemsPage) ?? true;
          const showOnlyWhenLoggedIn =
            FireTransforms.parseBool(showLoggedIn) ?? true;
          const showOnlyWhenLoggedOut =
            FireTransforms.parseBool(showLoggedOut) ?? true;

          if (isPromotionAsBool && !canViewPromotions) return false;

          if (
            (showOnlyWhenLoggedOut && !showOnlyWhenLoggedIn) ||
            (!showOnlyWhenLoggedOut && showOnlyWhenLoggedIn)
          ) {
            if (showOnlyWhenLoggedIn && !isLoggedIn) return false;
            if (showOnlyWhenLoggedOut && isLoggedIn) return false;
          }

          if (
            (name === "Esports" && !showOnEsportsPage) ||
            (name === "Racing" && !showOnRacingPage) ||
            (name === "Sports" && !showOnSportsPage) ||
            (name === "Pickems" && !showOnPickemsPage)
          ) {
            return false;
          }

          return true;
        } catch (e) {
          console.error(e);
          return false;
        }
      })
      .sort((a, b) => {
        const aSortNumber = parseInt(a.extras.order ?? "0");
        const bSortNumber = parseInt(b.extras.order ?? "0");

        if (aSortNumber === bSortNumber) return 0;
        return aSortNumber > bSortNumber ? 1 : -1;
      });
  }, [banners, canViewPromotions, isLoggedIn, name, isMobile, isClient]);
};
