import cx from "classnames";
import type {
  Competitor as CompetitorType,
  Outcome as OutcomeType,
} from "hooks/firestore/betting/useBetting";
import { Icon } from "library";
import React from "react";
import { Silks } from "sections/Betting/Race/components";
import { CompetitorAvatar } from "../components/CompetitorAvatar";
import * as styles from "./OutcomeIcon.module.scss";

export const iconTypes = {
  DRAW: "subtract",
  OVER: "iconHigher",
  UNDER: "iconLower",
  YES: "iconYes",
  NO: "iconNo",
  ODD: "iconOdd",
  EVEN: "iconEven",
};

type OutcomeIconProps = {
  outcome?: OutcomeType;
  competitor?: CompetitorType;
  isSelected?: boolean;
  size?: string | number;
  className?: string;
};

const OutcomeIcon = ({
  outcome,
  competitor,
  size = "30px",
  className: classNameProp,
  isSelected = false,
}: OutcomeIconProps) => {
  if (!outcome) {
    return null;
  }

  // Special rule for outcomes where the type is DRAW
  // https://puntaa.atlassian.net/browse/PKB-4346
  if (outcome?.type === "DRAW") {
    return (
      <Icon
        type={iconTypes[outcome?.type]}
        outerClassName={cx(
          {
            [styles.icon]: true,
          },
          classNameProp,
        )}
        style={{
          minWidth: size, // So if the market text is too long the icon is not squished
          width: size,
          height: size,
        }}
        inline={true}
      />
    );
  }

  if (
    competitor?.type === "RACER" &&
    competitor?.silksUrl &&
    competitor?.number
  ) {
    return (
      <Silks
        competitorNumber={competitor.number}
        imageHeight={30}
        silksUrl={competitor.silksUrl}
      />
    );
  }

  const iconType = outcome?.type
    ? iconTypes[outcome?.type as keyof typeof iconTypes]
    : undefined;

  return iconType ? (
    <Icon
      type={iconType}
      outerClassName={cx(
        {
          [styles.icon]: true,
        },
        classNameProp,
      )}
      style={{
        minWidth: size, // So if the market text is too long the icon is not squished
        width: size,
        height: size,
      }}
      inline={true}
    />
  ) : (
    // default if icon type does not exist.

    <CompetitorAvatar
      className={cx(styles.avatar, classNameProp)}
      competitor={competitor}
    />
  );
};

export default OutcomeIcon;
