import type { ZodType } from "zod";
import { getFirestore } from "store/getFirebase";
import {
  collection,
  CollectionReference,
  doc,
  type DocumentData,
  DocumentReference,
  onSnapshot as onFirestoreSnapshot,
  type Query,
  query,
  type QueryConstraint,
  type QuerySnapshot,
} from "firebase/firestore";
import { captureMessage } from "@sentry/gatsby";
import type { SWRSubscription, SWRSubscriptionOptions } from "swr/subscription";

const createRef = (path: string) => {
  const db = getFirestore();
  const isDoc = isDocument(path);
  const segments = pathToSegments(path);

  if (isDoc) {
    return doc(db, segments[0], ...segments.slice(1));
  }

  return collection(db, segments[0], ...segments.slice(1));
};

const pathToSegments = (path: string) => path.split("/").filter(Boolean);

const isDocument = (path: string) => pathToSegments(path).length % 2 === 0;

const processDocument =
  (model: ZodType, options: Options, path: string) => (doc: DocumentData) => {
    const data = options?.keyPath
      ? {
          [options.keyPath]: doc.id,
          ...doc.data(),
        }
      : doc.data();

    if (!doc.exists()) {
      return [null, null];
    }

    const parsed = model.safeParse(data);

    if (!parsed.success) {
      // capture failed data for debugging
      captureInvalidType(data, parsed.error, path);
      console.error(
        `Invalid data received from firestore. Path: ${path}`,
        data,
        parsed.error,
      );
      return [null, data];
    }

    return [null, parsed.data];
  };

const processCollection =
  (model: ZodType, options: Options, path: string) =>
  (snapshot: QuerySnapshot) => {
    if (snapshot.size === 0) {
      // if node is empty return null result
      return [null, null];
    }

    const data = snapshot.docs.map((doc) =>
      options?.keyPath
        ? {
            [options.keyPath]: doc.id,
            ...doc.data(),
          }
        : doc.data(),
    );

    const parsed = model.safeParse(data);

    if (!parsed.success) {
      // capture failed data for debugging
      captureInvalidType(data, parsed.error, path);
      console.error(
        `Invalid data received from firestore. Path: ${path}`,
        data,
        parsed.error,
      );
      return [null, data];
    }

    return [null, parsed.data];
  };

const onSnapshot = (
  ref: DocumentReference | CollectionReference | Query,
  model: ZodType,
  next: SWRSubscriptionOptions["next"],
  options: Options,
  path: string,
) => {
  if (ref instanceof DocumentReference) {
    return onFirestoreSnapshot(
      ref,
      (document: DocumentData) => {
        next(...processDocument(model, options, path)(document));
      },
      (error) => {
        console.error("ERROR", error);
        next(error);
      },
    );
  }

  return onFirestoreSnapshot(
    ref,
    (snapshot: QuerySnapshot) => {
      next(...processCollection(model, options, path)(snapshot));
    },
    (error) => {
      console.error("ERROR", error);
      next(error);
    },
  );
};

const captureInvalidType = (data: any, errors: any, path: string) => {
  captureMessage(`Invalid data type received from firestore. Path ${path}`, {
    level: "error",
    extra: {
      data,
      errors,
    },
  });
};

export type Options = {
  query?: QueryConstraint[];
  keyPath?: string; // specifies path to documents key
};

const createSubscribe =
  <T>(
    model: ZodType<T>,
    options: Options = {},
  ): SWRSubscription<string, T, Error> =>
  (key: any, { next }) => {
    const keyWithoutQuery = typeof key === "string" ? key : key.key;

    if (!keyWithoutQuery) {
      return next(null);
    }

    const ref = createRef(keyWithoutQuery);
    const refWithQuery =
      options.query && ref instanceof CollectionReference
        ? query(ref, ...options.query)
        : ref;

    const unsubscribe = onSnapshot(
      refWithQuery,
      model,
      next,
      options,
      keyWithoutQuery,
    );

    return () => {
      unsubscribe();
    };
  };

export { createSubscribe };
