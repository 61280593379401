import { useIsVerified } from "hooks/firestore/user/useIsVerified";
import { useHasDepositPermissions } from "hooks/useHasDepositPermissions";
import { useSelector } from "hooks/useSelector";
import { selectMustOfferDeposit } from "sections/Betting/Betslip/betslipSlice";
import { useIsQuickDepositAvailable } from "hooks/firestore/v2/user/useCreditCards";
import { useFiatCurrency } from "hooks//firestore/v2/user/useFiatCurrency";
import { useCashAvailable } from "hooks/firestore/v2/user/useAccountBalances";

export const useCanBetslipDeposit = () => {
  const isQuickDepositAvailable = useIsQuickDepositAvailable();
  const mustOfferDepositCheck = useSelector(selectMustOfferDeposit);
  const verified = useIsVerified();
  const fiatCurrency = useFiatCurrency();
  const { available, promo } = useCashAvailable();
  const canDepositPermission = useHasDepositPermissions();

  const offerDeposit =
    mustOfferDepositCheck(available) && verified && canDepositPermission;

  const offerQuickDeposit =
    isQuickDepositAvailable && fiatCurrency === "AUD" && offerDeposit;

  return {
    offerDeposit,
    offerQuickDeposit,
    balances: {
      availableBalance: available,
      availablePromoBalance: promo,
      currency: fiatCurrency,
    },
  };
};
