import React from "react";
import type { GatsbyLinkProps } from "gatsby";
import { navigate as GatsbyNavigate, Link as GatsbyLink } from "gatsby";
import { dispatchAfter, dispatchNow } from "store";
import { requestStarted, requestFinished } from "store/loadingList";
import { setMenu } from "utilities/UI/uiSlice";
import { useDispatch } from "hooks";
import useLocale from "hooks/useLocale";
import { useIsClient } from "usehooks-ts";
import { hasLocale } from "utilities/locale";

export const navigateBack = () => {
  const key = "navigate-link-" + location.href;
  dispatchNow(requestStarted("navigate-link", key));
  if (window.location.href.includes("/contest/")) {
    navigate("/pickems/");
  } else {
    GatsbyNavigate(-1);
  }
  dispatchAfter(requestFinished(key), 300);
  return false;
};

export function navigate(
  to = "/",
  noLang = false,
  options: { replace?: boolean } = {},
) {
  let prefix = "";

  if (
    typeof window !== "undefined" &&
    !noLang &&
    !hasLocale(to, window.___currentLocale)
  ) {
    prefix =
      window.___currentLocale === window.___defaultLocale
        ? ""
        : `/${window.___currentLocale}`;
  }

  dispatchNow(setMenu("none"));

  return GatsbyNavigate(
    `${prefix}${to.startsWith("/") ? "" : "/"}${to}`,
    options,
  );
}

type LinkProps = GatsbyLinkProps<any> & {
  nolang?: boolean;
  to?: string;
};

export const Link = ({
  to,
  className,
  children,
  nolang = false,
  onClick,
  ...rest
}: LinkProps) => {
  const dispatch = useDispatch();
  const external = to && !to.startsWith("/");
  const currentLocale = useLocale();
  const isClient = useIsClient();

  if (external) {
    return (
      <a href={to} className={className} target={`_blank`} {...rest}>
        {children}
      </a>
    );
  }

  if (isClient && !nolang && !hasLocale(to, currentLocale)) {
    const prefix =
      currentLocale !== window.___defaultLocale ? `/${currentLocale}` : "";
    to = `${prefix}${to}`;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <GatsbyLink
      className={className}
      to={to}
      onClick={(e) => {
        dispatch(setMenu("none"));

        if (typeof onClick === "function") {
          onClick(e);
        }
      }}
      {...rest}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
