// --
// Get User Data
// Async function run on state change to preload user data.

import localObject from "utilities/localObject";

// MOVING FORWARD - the KEY_PERSISTENT_DEVICE_ID needs to stay the same so we don't affect existing values
const KEY_PERSISTENT_DEVICE_ID = "pb-dvc";
// max length is now 36 (FOR FIREBASE - see xcode logs, nothing else complains) - we used to have KEY_PERSISTENT_DEVICE_ID in the value below, but it was too long
const DEVICE_ID_PREFIX = "pb";

/**
 * Get existing deviceId or generate a new one if required.
 *
 * We use our own persistent device id. The built-in mixpanel device Id is cleared on
 * reset and new one generated. We want to retain the device id
 * across sessions, so we can track when multiple users are sharing the
 * same device. This is indicative of a fraudulent user.
 *
 * NOTE: This is also sent through to Segment, and consequently FireBase to Google Analytics when Track and Identify events are sent.
 * Firebase was logging a warning that the deviceId used is longer than 36 characters in Xcode,
 * so new device Ids created will be pb-[date]-[random] instead of pb-dvc-[date]-[random]
 */
export function getDeviceId(): string {
  let [deviceId] = localObject.getValue<string>(KEY_PERSISTENT_DEVICE_ID);
  if (!deviceId) {
    deviceId =
      // max length is now 36 - so we use DEVICE_ID_PREFIX instead
      DEVICE_ID_PREFIX +
      "_" +
      new Date().getTime() +
      "_" +
      (Math.random() + "").substring(2);
    localObject.setValue(KEY_PERSISTENT_DEVICE_ID, deviceId);
  }

  return deviceId;
}
