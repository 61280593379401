import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";

export const eventCount = z.object({
  type: z.string(),
  prematch: z.number().optional(),
  live: z.number().optional(),
});

export type EventCount = zInfer<typeof eventCount>;
