import React from "react";
import * as styles from "./Promotions.module.scss";
import {
  type PromotionsType,
  type BettingTabs as BettingTabsType,
  BettingTabs,
  type CampaignsType,
  ExoticTabs,
  useIsLoggedIn,
} from "hooks";
import { Skeleton } from "components/Skeleton";
import cx from "classnames";
import { Promotion } from "./Promotion";
import { useFiatCurrency } from "hooks/firestore/v2/user/useFiatCurrency";

export const defaultHelpLink =
  "https://help.picklebet.com/hc/en-us/articles/5665601747471-Special-Promotion-Terms";

const getAssociatedTabs = (tab: BettingTabsType) => {
  // RACING_EXOTICS should show the campaign on all exotic tabs
  if (tab === BettingTabs.RACING_EXOTICS) {
    return ExoticTabs;
  }

  // if the current tab is an exotic, we want the star to show on the main RACING_EXOTICS tab too
  if (ExoticTabs.includes(tab)) {
    return [BettingTabs.RACING_EXOTICS];
  }

  return [];
};

export const shouldShowCampaignOnTab = (
  tab: BettingTabsType,
  campaignBettingTabs?: BettingTabsType[],
  checkAssociatedTabs = true,
) => {
  // If campaignBettingTabs or the tab itself is not defined or empty, show on all tabs
  if (!tab || !campaignBettingTabs || campaignBettingTabs.length === 0) {
    return true;
  }

  // If the current tab is explicitly included in campaignBettingTabs, show the campaign
  if (campaignBettingTabs.includes(tab)) {
    return true;
  }

  // maybe it should show when another tab is active (sub tab or parent)?
  const associatedTabs: BettingTabsType[] = checkAssociatedTabs
    ? getAssociatedTabs(tab)
    : [];
  if (associatedTabs.length) {
    return associatedTabs
      .map((associatedTab) =>
        shouldShowCampaignOnTab(
          associatedTab,
          campaignBettingTabs,
          false, // we don't have more than 1 layer, so flip to stop checking
        ),
      )
      .some((shouldShow) => shouldShow);
  }

  // mustn't apply then
  return false;
};

export type PromotionWithoutCurrency = Omit<PromotionsType, "currency">;

export const CampaignPromotions = ({
  campaigns,
  className,
  tab,
  loading = false,
}: {
  campaigns: CampaignsType[];
  className?: string;
  tab?: BettingTabsType;
  loading?: boolean;
}) => {
  const isLoggedIn = useIsLoggedIn();
  const usersCurrency = useFiatCurrency();

  if (loading) {
    return <Skeleton edge="hard" className={cx(styles.skeleton, className)} />;
  }

  if (!campaigns || campaigns.length === 0) {
    return null;
  }

  if (!isLoggedIn) return null;

  // Filter out campaigns that don't contain at least one promotion for the user's currency
  const filteredCampaigns = campaigns.filter((campaign) => {
    if (Object.keys(campaign?.promotions || {}).length === 0) {
      return true;
    }
    const promotions = Object.entries(campaign?.promotions || {}).filter(
      ([_, promotion]) => promotion?.currency === usersCurrency,
    );

    return promotions.length > 0;
  });

  return (
    <div className={className}>
      {filteredCampaigns?.map((campaign) => {
        const promotions = Object.entries(campaign?.promotions || {}).filter(
          ([_, promotion]) => promotion?.currency === usersCurrency,
        );

        if (!shouldShowCampaignOnTab(tab, campaign?.bettingTabs)) {
          return null;
        }

        if (promotions.length === 0) {
          return (
            <Promotion
              key={campaign?.id}
              promotion={{
                name: campaign?.name,
                description: campaign?.description,
                termsOfUse: campaign?.termsOfUse,
              }}
            />
          );
        }

        return promotions?.map(([promotionsId, promotion]) => (
          <Promotion promotion={promotion} key={promotionsId} />
        ));
      })}
    </div>
  );
};
