import cx from "classnames";
import * as Avatar from "@radix-ui/react-avatar";
import appConfig from "appConfig";
import type { Competitor } from "hooks/firestore/betting/useBetting";
import React from "react";
import { isAppleWebView } from "utilities/display";
import { buildCloudinaryTransformation } from "utilities/sharedBettingUtilities";

import * as styles from "./CompetitorAvatar.module.scss";

type CompetitorAvatarProps = {
  competitor?: Competitor;
  className?: string;
};

export const CompetitorAvatar = ({
  competitor,
  className,
}: CompetitorAvatarProps) => {
  if (!competitor) return null;

  let iconUrl = buildCloudinaryTransformation(competitor, 48);

  // show flag if no icon
  if (!competitor.iconUri && competitor.nationality) {
    iconUrl = `${
      isAppleWebView() ? appConfig.DOMAIN : ""
    }/flags/${competitor.nationality.toLowerCase()}.png`;
  }

  const name = competitor.abbreviation || competitor.name;

  return (
    <Avatar.Root
      className={cx(styles.avatar, className)}
      title={competitor.name}
    >
      <Avatar.Image src={iconUrl} alt={competitor.name} />
      <Avatar.Fallback
        className={cx(styles.avatar, styles.fallback, className)}
      >
        {name.substring(0, 3).toUpperCase()}
      </Avatar.Fallback>
    </Avatar.Root>
  );
};
