import { useEffect, useRef, useCallback } from "react";
import useRawSWRSubscriptions, {
  type SWRSubscriptionResponse,
} from "swr/subscription";
import type { Key, SWRHook, Middleware } from "swr";
import type { infer as zInfer, ZodType } from "zod";
import { createSubscribe, type Options } from "hooks/firestore/v2/subscribe";
import type { QueryConstraint } from "firebase/firestore";
import { useSelector } from "hooks/useSelector";

const loading: Middleware =
  (useSWRNext: SWRHook) => (key, subscriber, config) => {
    if (!subscriber) {
      return useSWRNext(key, config);
    }

    const swr = useSWRNext(key, subscriber, config);

    // when data is undefined or key has changed, we are loading
    // if there is no key, consider it finished loading
    const isLoading = key ? typeof swr.data === "undefined" : false;

    return Object.assign({}, swr, {
      data: swr.data,
      isLoading,
    });
  };

const laggyValue: Middleware =
  (useSWRNext: SWRHook) => (key, subscriber, config) => {
    // save previous value as ref and compare it to the new value
    const laggyDataRef = useRef<typeof swr.data>();

    // Actual SWR hook.
    const swr = useSWRNext(key, subscriber, config);

    useEffect(() => {
      // Update ref if data is not undefined.
      if (swr.data !== undefined) {
        laggyDataRef.current = swr.data;
      }
    }, [swr.data]);

    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined;
    }, []);

    const dataOrLaggyData =
      swr.data === undefined && Boolean(key) ? laggyDataRef.current : swr.data;

    const isLagging =
      swr.data === undefined && laggyDataRef.current !== undefined;

    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    });
  };

const makeKey = (
  key: Key,
  constraints: QueryConstraint[] | undefined,
  userId: string,
) => {
  if (typeof key !== "string") return;

  // key has userId, but we have no session, quit early
  if (key.includes("{userId}") && !userId) return;

  key = key.replace("{userId}", userId);

  if (!constraints) {
    return key;
  }

  return {
    key,
    constraints: JSON.stringify(constraints),
  };
};

const useFirestore = <T>(key: Key, model: ZodType<T>, options?: Options) => {
  const userId = useSelector((state) => state.auth.userId);

  return useRawSWRSubscriptions(
    makeKey(key, options?.query, userId),
    createSubscribe(model, options),
    {
      use: [loading, laggyValue],
    },
  ) as SWRSubscriptionResponse<zInfer<typeof model>> & {
    isLoading: boolean;
  };
};

export { useFirestore };
