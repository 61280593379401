import { useEffect, useState } from "react";
import { usePreviousValue } from "./usePreviousValue";

export type OddsChangeDirection = "up" | "down";

/**
 * A hook that tracks the odds and returns which direction they changed when
 * they do
 *
 * @returns "up", "down" or undefined
 */
export const useOddsChangeDirection = (
  odds: number,
  animationLength = 3000,
) => {
  const previousOdds = usePreviousValue(odds);

  const [oddsChangeDirection, setOddsChangeDirection] = useState<
    OddsChangeDirection | undefined
  >();

  useEffect(() => {
    if (!previousOdds || previousOdds === odds) {
      return;
    }

    setOddsChangeDirection(previousOdds < odds ? "up" : "down");

    // clear the odds change direction so the component knows when to stop
    // displaying the change
    const timeout = setTimeout(
      () => setOddsChangeDirection(undefined),
      animationLength,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [odds]);

  return oddsChangeDirection;
};
