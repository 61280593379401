import { useEffect, useMemo, useState } from "react";
import { getFirestore } from "store/getFirebase";
import { type CampaignsType, useCollectionData, useDocumentData } from "hooks";
import {
  collection,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type SnapshotOptions,
  type DocumentData,
  doc,
  query,
  where,
} from "firebase/firestore";
import { useRaceMeeting } from "./useRaceMeeting";
import { usePermissions } from "hooks/useHasPermission";

import { parseDate } from "utilities/dateUtilities";
import {
  marketFilter,
  shouldShowPromoBanner,
} from "utilities/sharedBettingUtilities";
import type {
  RaceEventType,
  RaceCompetitorType,
  RaceMarketsType,
} from "./RacingTypes";

import { useRaceOrder } from "./useRaceOrder";
import { useLocalStorage } from "usehooks-ts";
import { useRaceDetails } from "sections/Betting/Race/hooks/useRaceDetails";
import { useEventEntries } from "hooks/firestore/betting/useEntries";
import { useRaceTimezone } from "sections/Betting/Race/hooks/useRaceTimezone";
import { useToggleRace } from "sections/Betting/Race/hooks/useToggleRace";
import { useRaceRoute } from "sections/Betting/Racing/hooks";
import type { ShortFormState } from "sections/Betting/Race/components/ShortForm/ShortForm";
import type { RaceRouteParams } from "sections/Betting/Racing/hooks/useRaceRoute";
import { useCampaigns } from "hooks/firestore/v2/user/useCampaigns";
import {
  useTokens,
  type GroupedTokens,
} from "hooks/firestore/v2/user/useTokens";
import type { Permissions } from "utilities/Auth/authSlice";

export const createRacingEventsConverter = (
  userCampaigns: string[],
  userTokens: GroupedTokens,
  permissions: Permissions,
): FirestoreDataConverter<RaceEventType> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): RaceEventType => {
      const data = snapshot.data(options);

      const promotionCampaigns = data.promotionCampaigns?.filter(
        (campaign: CampaignsType) =>
          shouldShowPromoBanner(
            campaign,
            userCampaigns,
            userTokens,
            permissions,
          ),
      );

      const canViewPromo = permissions["viewPromotion"] === "GRANTED";

      return {
        id: snapshot.id,
        ...data,
        scheduledStartTime: parseDate(data.scheduledStartTime),
        country: data.venueCountry,
        state: data.venueState,
        trackType: data.meetingTrackType,
        promotionCampaigns: canViewPromo ? promotionCampaigns : [],
        showPromoBanner:
          canViewPromo &&
          (data.hasPromotionalMarkets || promotionCampaigns?.length > 0),
      } as RaceEventType;
    },
  };
};

export const raceCompetitorsConverter: FirestoreDataConverter<RaceCompetitorType> =
  {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): RaceCompetitorType => {
      const data = snapshot.data(options);

      return {
        id: snapshot.id,
        ...data,
        scratchedAt: parseDate(data.scratchedAt),
        startingPosition: data.hcpDraw ?? data.startingPosition,
      } as RaceCompetitorType;
    },
  };

export const createRaceMarketsConverter = (
  userCampaigns: string[],
  userTokens: GroupedTokens,
  permissions: Permissions,
): FirestoreDataConverter<RaceMarketsType> => {
  return {
    // we are not saving in firestore no need to transform
    toFirestore: (data: any): DocumentData => data,
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): RaceMarketsType => {
      const data = snapshot.data(options);

      const promotionCampaigns = data.promotionCampaigns?.filter(
        (campaign: RaceMarketsType["promotionCampaigns"][number]) =>
          shouldShowPromoBanner(
            campaign,
            userCampaigns,
            userTokens,
            permissions,
          ),
      );

      const canViewPromo = permissions["viewPromotion"] === "GRANTED";

      return {
        ...data,
        promotionCampaigns: canViewPromo ? promotionCampaigns : [],
        showPromoBanner:
          canViewPromo &&
          (data.hasPromotionalMarkets || promotionCampaigns?.length > 0),
      } as RaceMarketsType;
    },
  };
};

export const useRaceMarketsAndCompetitors = (raceId?: string) => {
  const { codes: userCampaigns } = useCampaigns();
  const permissions = usePermissions();
  const { tokens } = useTokens();

  const racingEventsConverter = useMemo(
    () => createRacingEventsConverter(userCampaigns, tokens, permissions),
    [userCampaigns, permissions],
  );

  const raceMarketsConverter = useMemo(
    () => createRaceMarketsConverter(userCampaigns, tokens, permissions),
    [userCampaigns, permissions],
  );

  const raceEventRef = useMemo(() => {
    if (!raceId) return null;

    return doc(getFirestore(), "racingEvents", raceId).withConverter(
      racingEventsConverter,
    );
  }, [raceId, userCampaigns]);

  const competitorsRef = useMemo(() => {
    if (!raceId || !raceEventRef) return null;

    return collection(raceEventRef, "competitors").withConverter(
      raceCompetitorsConverter,
    );
  }, [raceId, userCampaigns]);

  const marketsRef = useMemo(() => {
    if (!raceId || !raceEventRef) return null;

    return collection(raceEventRef, "markets").withConverter(
      raceMarketsConverter,
    );
  }, [raceId, userCampaigns]);

  const [race, raceLoading] = useDocumentData(raceEventRef);

  const [competitors, competitorsLoading, competitorsError] = useCollectionData(
    competitorsRef ? query(competitorsRef, where("visible", "==", true)) : null,
    competitorsRef?.path,
  );

  const [markets, marketsLoading, marketsError] = useCollectionData(
    marketsRef,
    marketsRef?.path,
  );

  const campaigns = race?.promotionCampaigns;

  return {
    race,
    competitors,
    markets: markets?.filter((market) =>
      marketFilter({ event: race, market, userCampaigns, permissions }),
    ),
    raceLoading: raceLoading || competitorsLoading || marketsLoading,
    errors: { ...competitorsError, ...marketsError },
    campaigns,
  };
};

export type PillTypes = "speedmap" | "comments" | "flucs";
export type OpenShortFormState = Exclude<ShortFormState, "closed">;

export const useRace = (meetingId: string, raceId: string) => {
  const { route, setRouteValue } = useRaceRoute();
  const [raceMeeting, races, raceMeetingLoading] = useRaceMeeting(meetingId);
  const [selectedPills, setSelectedPills] = useLocalStorage<Array<PillTypes>>(
    "selectedRaceFilters",
    [],
  );

  // a boolean flag that if true will allow betting on starting price odds, even
  // if fixed odds are available - PKB-8030
  const [forceSp, setForceSp] = useState(false);

  const { raceOrder, setRaceOrder, orderOptions } = useRaceOrder(raceMeeting);

  const raceDetails = useRaceMarketsAndCompetitors(raceId);
  const loading = raceMeetingLoading || raceDetails?.raceLoading;

  const extraRaceDetails = useRaceDetails({
    ...raceDetails,
    selectedPills,
    setSelectedPills,
    loading,
  });

  const [myBets, myBetsLoading] = useEventEntries(
    route.raceId,
    raceDetails?.race?.status,
  );

  const raceTimezone = useRaceTimezone({
    state: raceMeeting?.state,
    country: raceMeeting?.country,
  });

  const toggleRace = useToggleRace({
    ...raceDetails,
    raceMeeting,
    raceTimezone,
  });

  useUpdateRaceId({
    routeRaceId: route?.raceId,
    races,
    setRouteValue,
    raceMeetingLoading,
  });

  return {
    ...raceDetails,
    ...extraRaceDetails,
    races,
    raceMeeting,
    raceMeetingLoading,
    raceOrder,
    setRaceOrder,
    orderOptions,
    selectedPills,
    setSelectedPills,
    myBets,
    myBetsLoading,
    toggleRace,
    raceTimezone,
    route,
    setRouteValue,
    loading,
    forceSp,
    setForceSp,
  };
};

// backwards compatibility for raceId. Safe to remove post June 2024
const useUpdateRaceId = ({
  routeRaceId,
  races,
  setRouteValue,
  raceMeetingLoading,
}: {
  routeRaceId?: string;
  races?: RaceEventType[];
  setRouteValue: (arg: Partial<RaceRouteParams>) => void;
  raceMeetingLoading: boolean;
}) => {
  useEffect(() => {
    const updateRaceId = () => {
      if (
        !isNaN(parseFloat(routeRaceId)) &&
        isFinite(Number(routeRaceId)) &&
        races &&
        !raceMeetingLoading
      ) {
        const currentRaceId = races.find(
          (race) => race.number === Number(routeRaceId),
        )?.id;
        if (currentRaceId) {
          setRouteValue({ raceId: currentRaceId });
        }
      }
    };

    updateRaceId();
  }, [routeRaceId, races, setRouteValue, raceMeetingLoading]);
};
