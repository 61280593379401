import React from "react";
import { useProfile } from "hooks/firestore/v2/user/useProfile";
import { PageMessage } from "components/PageMessage";
import { SupportLink } from "components/SupportLink";
import { ReactComponent as RestrictedIcon } from "components/assets/warning-map.svg";

type RegionRestrictedProps = {
  children: React.ReactNode;
};

const RegionRestricted = ({ children }: RegionRestrictedProps) => {
  const { profile } = useProfile();

  // UNVERIFIED means we will not attempt to verify this user
  const isRegionRestricted = profile?.verificationStatus === "UNVERIFIED";

  if (isRegionRestricted) {
    return (
      <PageMessage
        title="Verification not required"
        image={<RestrictedIcon />}
        subTitle="Verification is only required for Users in regions we offer Betting."
      >
        <SupportLink
          hideChevron
          options={{ articleId: "115000814311" }}
          type={`button`}
        >
          Learn More
        </SupportLink>
      </PageMessage>
    );
  }

  return <>{children}</>;
};

export { RegionRestricted };
