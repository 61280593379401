import React from "react";
import { Provider } from "react-redux";
import { getStore } from "./getStore";

export function wrapWithProvider(element: React.ReactNode) {
  const store = getStore();

  return <Provider store={store}>{element}</Provider>;
}

export default wrapWithProvider;
