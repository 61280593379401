// extracted by mini-css-extract-plugin
export var blue = "#0eb3f2";
export var emphasis = "Race-module--emphasis--bafe6";
export var green = "#57d33b";
export var greenLink = "Race-module--green-link--63fae";
export var grey = "var(--tone-mid)";
export var layout = "Race-module--layout--f407e";
export var notchGap = "32px";
export var orange = "#f8662b";
export var red = "#ff2741";
export var tabs = "Race-module--tabs--d8fdb";
export var widthDesktop = "1024px";
export var widthDesktopXl = "1280px";
export var widthExpanded = "1536px";
export var widthMobile = "640px";
export var widthTablet = "768px";
export var yellow = "#ffb100";