import { getString } from "./apiStrings";
import type { Toast } from "hooks/ui/useToast";
import { QueueError, ResultError } from "utilities/message";

const QUEUE_MISSING_ERROR = "Application error when queuing message.";
const RESULT_MISSING_ERROR = "Application error when processing message.";

const parseMessageError = (error: QueueError | ResultError) => {
  const hasError = error.errors && error.errors.length > 0;

  if (hasError) {
    return error.errors.map((e) => e.message).join(", ");
  }

  const mappedError = error.code ? getString(error.code) : false;

  if (mappedError !== error.code) {
    // make sure that we don't include missing values
    return mappedError;
  }

  if (error.message) {
    return error.message;
  }

  if (error instanceof QueueError) {
    return QUEUE_MISSING_ERROR;
  }

  return RESULT_MISSING_ERROR;
};

/**
 * Check if the error object has validation errors
 * @param body
 */
export const hasErrors = ({ body }) =>
  (body && body.errors && body.errors.length > 0) || false;

/**
 * Extract validation errors from the error object
 * @param body
 */
export const extractMessages = ({ body }) => {
  return body.errors.reduce((messages, error) => {
    if (!messages[error.propertyPath]) {
      messages[error.propertyPath] = [];
    }
    messages[error.propertyPath].push(error.message);
    return messages;
  }, {});
};

/**
 * Extracts a single flat message per field
 * @param body
 * @return {*}
 */
export const extractMessagesFlat = ({ body }) => {
  return body.errors.reduce((messages, error) => {
    messages[error.propertyPath || error.messageTemplate] = error.message;
    return messages;
  }, {});
};

export function parseError(error: any): string {
  if (error instanceof QueueError || error instanceof ResultError) {
    return parseMessageError(error);
  }

  if (error instanceof Error) {
    return error.message;
  }

  console.warn(`Infrastructure error.`, error);
  return (
    error.message || "An error occurred. Please try again or come back later."
  );
}

export const convertErrorToToast = (error: any, options: Toast = {}): Toast => {
  return {
    description: parseError(error),
    variant: "danger",
    ...options,
  };
};
