import React, { useMemo, useState } from "react";
import { useIsLoggedIn, useIsMobile } from "hooks";
import { ExpandChevron } from "library";
import type { Title } from "hooks/firestore/betting/types";

import * as styles from "./RaceMeetingDropDown.module.scss";
import { HeadingClamp } from "../../Racing/components/HeadingClamp";

import { Typography } from "components/Typography";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover/Popover";
import { Box } from "components/Box";
import { EventStatus, type StatusEnum } from "components/layout/EventStatus";
import { RaceIcon } from "sections/Betting/Racing/components";
import { Caret } from "components/Caret";
import { Link } from "components/Link";
import { getRaceUrl } from "utilities/racingUtilities";
import { isToday } from "date-fns";
import cx from "classnames";
import { ReactComponent as TickIcon } from "components/assets/tick.svg";
import type { RaceMeetingType } from "sections/Betting/Race/hooks/useRaceMeetings";
import { useMinimalRaceMeetings } from "sections/Betting/Race/hooks/useMinimalRaceMeetings";
import type {
  RaceRegionTypes,
  RaceSportTypes,
} from "sections/Betting/Race/hooks/useRacingEvents";
import RaceMeetingDropDownSkeleton from "sections/Betting/components/RaceMeetingDropDown/RaceMeetingDropDownSkeleton";
import { ReactComponent as SmallPromoIcon } from "components/assets/icon-star-small.svg";
import { MaxHeightScrollArea } from "components/ScrollArea/MaxHeightScrollArea";
import { CountryAndState } from "sections/Betting/components/CountryAndState";
import { RaceWeather } from "sections/Betting/components/RaceWeather";

// No# of elements * height of each element in rem * 1rem to 10px
const RACE_MEETING_DROPDOWN_MAX_HEIGHT = 6 * 5.1 * 10;

export const sports = [
  {
    name: "HORSE_RACING",
    icon: "horseRacing",
    slug: "horse-racing",
  },
  {
    name: "GREYHOUNDS",
    icon: "greyhounds",
    slug: "greyhounds",
  },
] as Title[];

export const regions = [
  {
    name: "ANZ",
    icon: "australia",
    slug: "anz",
  },
  {
    name: "ROW",
    icon: "world",
    slug: "row",
  },
] as Title[];

export type OptionRow = {
  name: string;
  currentRaceNumber: number;
  currentRace: string;
  currentRaceScheduledStartTime: Date;
  raceMeetingId: string;
  region: RaceRegionTypes;
  sport: RaceSportTypes;
  country: string;
  state: string;
  raceMeetingScheduledStartTime: Date;
};

const RaceMeetingDropDownRow = ({
  raceMeeting,
  linkTo,
  selected,
  onClick,
}: {
  raceMeeting: RaceMeetingType;
  linkTo: "race" | "meeting";
  selected?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  const isLoggedIn = useIsLoggedIn();

  return (
    <Link
      to={getRaceUrl(linkTo, raceMeeting?.id, raceMeeting?.currentRace?.id)}
      key={raceMeeting?.id}
      className={styles.dropDownRow}
      onClick={onClick}
    >
      <div className={styles.indicator}>
        {selected ? (
          <div className={styles.tick}>
            <TickIcon />
          </div>
        ) : (
          <Caret variant="right" className={styles.caret} />
        )}
      </div>
      <div
        className={cx(styles.dropDownVenueName, {
          [styles.selected]: selected,
        })}
      >
        {`${raceMeeting?.venue}, R${raceMeeting?.currentRace?.number}`}
      </div>
      <div className={styles.dropDownCountry}>
        {raceMeeting?.country}
        {isLoggedIn && raceMeeting?.currentRace?.showPromoBanner && (
          <div>
            <SmallPromoIcon className={cx(styles.tabPromoIcon)} />
          </div>
        )}
      </div>
      <div className={styles.dropDownStatus}>
        <EventStatus
          // TODO: convert to a common enum, currently used in multiple places and not a part of betslip refactor
          status={raceMeeting.currentRace?.status as unknown as StatusEnum}
          scheduledStartTime={raceMeeting?.currentRace?.scheduledStartTime}
          shape="rounded"
        />
      </div>
    </Link>
  );
};

const RaceMeetingDropDownBase = ({ raceMeeting }: RaceMeetingDropDownProps) => {
  const {
    currentRace,
    scheduledStartTime,
    sport,
    venue,
    country,
    state,
    trackCondition,
    trackRating,
    weather,
    region,
    linkTo,
    id,
  } = raceMeeting;

  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);

  const isRaceToday = useMemo(
    () => isToday(scheduledStartTime),
    [scheduledStartTime],
  );

  const [raceMeetings] = useMinimalRaceMeetings(
    currentRace?.scheduledStartTime,
    region,
    isRaceToday,
  );

  const filteredMeetings = raceMeetings
    ?.filter((x) => x.sport === sport)
    .sort((a, b) => {
      const key = isToday
        ? "currentRaceScheduledStartTime"
        : "raceMeetingScheduledStartTime";
      return a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0;
    });

  return (
    <Popover open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
      <PopoverTrigger className={styles.raceMeeting}>
        <HeadingClamp direction="left">
          <RaceIcon sport={sport} className={styles.icon} />
        </HeadingClamp>

        <div className={styles.raceVenue}>
          <div>
            <div className={styles.topRow}>
              <Typography variant={`h2`} noSpacing className={styles.venue}>
                {venue}
              </Typography>
              {filteredMeetings?.length > 1 && (
                <ExpandChevron
                  expanded={false}
                  direction={"down"}
                  onClick={null}
                  className={styles.chevron}
                />
              )}
            </div>
            <Typography variant={`body`} noSpacing>
              <CountryAndState countryCode={country} stateCode={state} />
            </Typography>
          </div>
        </div>
        {weather && (
          <HeadingClamp direction="right">
            <RaceWeather
              weather={weather}
              trackCondition={trackCondition}
              trackRating={trackRating}
            />
          </HeadingClamp>
        )}
      </PopoverTrigger>
      <PopoverContent
        className={styles.popover}
        align={isMobile ? "center" : "start"}
      >
        <Box hideHeader className={styles.popoverBox}>
          <MaxHeightScrollArea
            onScroll={(e) => e.stopPropagation()}
            maxHeight={RACE_MEETING_DROPDOWN_MAX_HEIGHT}
          >
            {filteredMeetings?.map((raceMeeting) => (
              <RaceMeetingDropDownRow
                raceMeeting={raceMeeting}
                key={raceMeeting.id}
                linkTo={linkTo}
                selected={id === raceMeeting.id}
                onClick={() => setIsOpen(false)}
              />
            ))}
          </MaxHeightScrollArea>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

type RaceMeetingDropDownProps = {
  raceMeeting: RaceMeetingType & {
    linkTo: "race" | "meeting";
  };
};

export const RaceMeetingDropDown = ({
  raceMeeting,
}: RaceMeetingDropDownProps) => {
  if (!raceMeeting) return <RaceMeetingDropDownSkeleton />;

  return <RaceMeetingDropDownBase raceMeeting={raceMeeting} />;
};
