import {
  type Dispatch,
  type SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { RaceContext } from "sections/Betting/Race";
import * as styles from "./RaceFilters.module.scss";
import React from "react";
import { Pill } from "components/Pill";
import { ReactComponent as IconFlucs } from "sections/Betting/Race/components/assets/icon-flucs.svg";
import { ReactComponent as IconComments } from "components/assets/icon-comments.svg";
import { ReactComponent as IconSpeedmap } from "sections/Betting/Race/components/assets/icon-speedmap.svg";
import {
  type OrderColumn,
  OrderType,
} from "sections/Betting/Race/hooks/useRaceOrder";
import { Box } from "components/Box";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/Popover/Popover";
import cx from "classnames";
import { Caret } from "components/Caret";
import type { PillTypes } from "sections/Betting/Race/hooks/useRace";
import { RaceFiltersSkeleton } from "sections/Betting/Race/components/RaceFilters/RaceFiltersSkeleton";
import { Toggle } from "components/Toggle";

export const SortPill = () => {
  const [pressed, setPressed] = useState(false);
  const { raceOrder, setRaceOrder, orderOptions } = useContext(RaceContext);

  const onClick = (orderColumn: OrderColumn) => {
    // if already selected, toggle the order type
    if (raceOrder.orderColumn === orderColumn) {
      setRaceOrder({
        orderType:
          raceOrder.orderType === OrderType.asc
            ? OrderType.desc
            : OrderType.asc,
        orderColumn,
      });
    } else {
      setRaceOrder({ orderType: raceOrder.orderType, orderColumn });
    }
  };

  return (
    <Popover open={pressed} onOpenChange={(isOpen) => setPressed(isOpen)}>
      <PopoverTrigger className={styles.sort} asChild>
        <Pill onPressedChange={setPressed} pressed={pressed}>
          Sort{" "}
          <Caret
            variant="down"
            className={cx(styles.caret, { [styles.active]: pressed })}
          />
        </Pill>
      </PopoverTrigger>
      <PopoverContent align={"end"} className={styles.sortPopover}>
        <Box className={styles.sortBox} hideHeader>
          <div>
            {orderOptions?.map((option) => (
              <div
                key={option.value}
                className={cx(styles.sortRowOption, {
                  [styles.active]: raceOrder.orderColumn === option.value,
                })}
                onClick={() => onClick(option.value)}
              >
                <Caret
                  className={cx(styles.caret, {
                    [styles.active]: raceOrder.orderColumn === option.value,
                  })}
                  variant={
                    raceOrder.orderType === OrderType.asc ? "up" : "down"
                  }
                />{" "}
                {option.label}
              </div>
            ))}
          </div>
        </Box>
      </PopoverContent>
    </Popover>
  );
};

const ForceSpToggle = ({
  forceSp,
  setForceSp,
  disabled,
}: {
  forceSp: boolean;
  setForceSp: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
}) => {
  return (
    <Toggle
      label="SP"
      labelRight
      noSpacing
      checked={forceSp}
      onCheckedChange={setForceSp}
      trackClass={styles.forceSpToggleTrack}
      labelClass={styles.forceSpToggleLabel}
      title="Enable betting on Starting Prices"
    />
  );
};

type RaceFiltersProps = {
  showForceSpToggle?: boolean;
  isSP?: boolean;
};

export const RaceFilters = ({
  showForceSpToggle = false,
  isSP,
}: RaceFiltersProps) => {
  const {
    selectedPills,
    setSelectedPills,
    hasRaceComment,
    hasSpeedMap,
    hasFlucs,
    loading,
    forceSp,
    setForceSp,
    race,
  } = useContext(RaceContext);

  useEffect(() => {
    // if a race moves to any status that isn't OPEN and forceSp is current
    // toggled ON, we want to switch it off and show the actual odds
    if (race?.status !== "OPEN" && forceSp === true) {
      setForceSp(false);
    }
  }, [race?.status]);

  const toggleFromPills = (pill: string, pressed: boolean) => {
    if (pressed) {
      setSelectedPills([...selectedPills, pill as PillTypes]);
    } else {
      setSelectedPills(selectedPills.filter((x) => x !== pill));
    }
  };

  if (loading) {
    return <RaceFiltersSkeleton />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.pills}>
        {hasRaceComment ? (
          <Pill
            onPressedChange={(pressed) => toggleFromPills("comments", pressed)}
            pressed={selectedPills.includes("comments")}
          >
            <IconComments />
            Tips
          </Pill>
        ) : null}
        {hasSpeedMap ? (
          <Pill
            onPressedChange={(pressed) => toggleFromPills("speedmap", pressed)}
            pressed={selectedPills.includes("speedmap")}
          >
            <IconSpeedmap />
            Speed Map
          </Pill>
        ) : null}
        {hasFlucs && (
          <Pill
            onPressedChange={(pressed) => toggleFromPills("flucs", pressed)}
            pressed={selectedPills.includes("flucs")}
          >
            <IconFlucs />
            Flucs
          </Pill>
        )}
      </div>
      <div className={styles.rightContainer}>
        {showForceSpToggle && (
          <ForceSpToggle
            forceSp={isSP ? true : forceSp}
            setForceSp={setForceSp}
            disabled={isSP}
          />
        )}

        <SortPill />
      </div>
    </div>
  );
};
