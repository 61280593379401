import { useEffect } from "react";
import { useUser } from "hooks/firestore/v2/user/useUser";
import { useSelector, useDispatch } from "hooks";
import type { FirestoreError } from "firebase/firestore";
import { logout } from "utilities/Auth/authSlice";

const useProfile = () => {
  const { user, isLoading, error } = useUser();
  const userId = useSelector((state: any) => state.auth?.userId);
  const isSigningOut = useSelector((state: any) => state.auth?.signingOut);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isSigningOut) {
      checkSession(error, userId, () => {
        dispatch(logout());
      });
    }
  }, [user, error]);

  return {
    profile: user?.profile,
    isLoading,
  };
};

type Profile = Exclude<ReturnType<typeof useProfile>["profile"], undefined>;

const useProfileAddress = () => {
  const { profile, isLoading } = useProfile();

  const address = profile
    ? [
        profile.unit,
        profile.unit ? "/" : "",
        profile.streetNumber,
        " ",
        profile.street,
        ", ",
        profile.state,
        ", ",
        profile.postCode,
        ", ",
        profile.country,
      ].join("")
    : "";

  return {
    address,
    isLoading,
  } as const;
};

const checkSession = (
  error: FirestoreError,
  userId: string,
  callback: () => void,
) => {
  if (!error || !userId) {
    return;
  }

  switch (error?.code) {
    case "permission-denied":
    case "unauthenticated":
      console.warn(
        `Got '${error.code}' error from firebase. Assuming firebase token is invalid so logging user '${userId}' out. Message:  ${error?.message}`,
      );

      callback && callback();
      break;
    default:
      console.warn(
        `Unknown error while fetching profile`,
        error?.code,
        error?.message,
      );
  }
};

export { useProfile, type Profile, useProfileAddress };
