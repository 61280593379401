import type { CurrencyType } from "./BetTypes";
import { constructCurrency } from "../common";
import type { NullableMaybe, Nullable } from "./utilities";

export type PromotionPurpose =
  | "DEFAULT_REFERRAL"
  | "REFERRAL"
  | "PROMOTION"
  | "DEFAULT_DEPOSIT_BONUS"
  | "DEPOSIT_BONUS"
  | "PER_CLAIM_DEPOSIT_BONUS";
export type PromotionClaimPayStatus =
  | "PENDING"
  | "APPROVED"
  | "PAID"
  | "DECLINED"
  | "FAILED"
  | "REPLACED";

// API: com.skrilla.api.promotions.model.PromotionClaimView
export type PromotionClaim = {
  amount: number;
  code?: string;
  currency: string;
  name?: string;
  paidStatus?: PromotionClaimPayStatus;
  purpose?: PromotionPurpose;
  updatedAt?: Date;
  expireAt?: Date;
  matchPercentage?: number;
};

export type PromotionCode = {
  active: boolean;
  claimerAmount: number;
  code: string;
  currency: string;
  ownerAmount: number;
};

/**
 * Returns a formatted string of the bonus amount
 * @param depositAmount Amount use is going to deposit in dollars
 * @param pendingDepositClaim The current claim
 */
export function calculateDepositBonusAmount(
  depositAmount?: number,
  pendingDepositClaim?: PromotionClaim,
): Nullable<string> {
  if (!pendingDepositClaim) {
    return null;
  }

  const matchPercentage = pendingDepositClaim.matchPercentage || 100;

  // As well as taking the percentage this also converts to cents
  const bonusAmount = depositAmount
    ? depositAmount * matchPercentage
    : pendingDepositClaim.amount;

  const maxAmount = pendingDepositClaim.amount;

  const result = constructCurrency(Math.min(bonusAmount, maxAmount), {
    currencyType: pendingDepositClaim.currency,
  });

  return result;
}

export const maxDepositAmount = (
  pendingDepositClaim?: PromotionClaim,
  showDecimals = true,
) => {
  return constructCurrency(pendingDepositClaim?.amount, {
    currencyType: pendingDepositClaim?.currency,
    showDecimals,
  });
};

export function isDepositBonus(claim?: PromotionClaim): boolean {
  return (
    claim?.purpose === "DEFAULT_DEPOSIT_BONUS" ||
    claim?.purpose === "DEPOSIT_BONUS" ||
    claim?.purpose === "PER_CLAIM_DEPOSIT_BONUS"
  );
}

export function extractPendingDepositBonusClaim(
  claims?: Record<string, PromotionClaim> | PromotionClaim[],
): NullableMaybe<PromotionClaim> {
  return claims
    ? Object.values(claims).find(
        (c) =>
          isDepositBonus(c) &&
          c.paidStatus === "PENDING" &&
          (!c.expireAt || c.expireAt > new Date()),
      )
    : null;
}
