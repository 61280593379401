import {
  type AnyAction,
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
  isAnyOf,
  type Reducer,
  type PayloadAction,
  type ThunkDispatch,
} from "@reduxjs/toolkit";
import { parseISO } from "date-fns";
import * as UserAPI from "utilities/api/picklebet/auth";
import localObject from "utilities/localObject";
import {
  setModal,
  setCaptchaPuzzle,
  closeCaptchaPuzzle,
} from "utilities/UI/uiSlice";
import { checkSelectionsAreStillValid } from "sections/Betting/Betslip/betslipSlice";
import { toast } from "hooks/ui/useToast";
import { BET_STOP_MODAL_ID_LOGOUT } from "sections/Auth/BetStopModalLogout";
import { PROFILE_COMPLETION_MODAL } from "components/ProfileCompletionModal/ProfileCompletionModal";
import type { RootState } from "store/createStore";

const SESSION_CHECK_DURATION = 43200000;

export enum AuthSource {
  DEFAULT = "DEFAULT",
  API = "API",
}

export type Permission =
  | "viewContest"
  | "viewPromotion"
  | "viewBettingMarkets"
  | "submitEsportsBet"
  | "viewEsportsMarkets"
  | "submitSportsBet"
  | "viewSportsMarkets"
  | "submitRacingBet"
  | "viewRacingMarkets"
  | "submitBet"
  | "submitLiveBet"
  | "viewLiveMarkets"
  | "submitPlaceBet"
  | "submitTop4Bet"
  | "submitInternationalRacingBet"
  | "submitSRMBet"
  | "deposit_AUD"
  | "withdraw_AUD"
  | "submitPaidRoster"
  | "submitFreeRoster"
  | "claimDepositBonus"
  | "deposit"
  | "withdraw"
  | "reopenAccount"
  | "submitComboMultiBet"
  | "submitPromoComboMultiBet"
  | "submitRacingExoticBet"
  | "submitPromoRacingExoticBet"
  | "cancelWithdrawal"
  | "submitSGMBet"
  | "submitPickems"
  | "user";

export enum PermissionReason {
  GRANTED = "GRANTED",
  ACCOUNT_REOPEN_REQUIRED = "ACCOUNT_REOPEN_REQUIRED",
  PHYSICAL_LOCATION_UNSUPPORTED = "PHYSICAL_LOCATION_UNSUPPORTED",
  VERIFICATION_STATUS_UNSUPPORTED = "VERIFICATION_STATUS_UNSUPPORTED",
  RESIDENTIAL_LOCATION_UNSUPPORTED = "RESIDENTIAL_LOCATION_UNSUPPORTED",
  COOLDOWN = "COOLDOWN",
  CC_UNVERIFIED_MW_DEP = "CC_UNVERIFIED_MW_DEP",
  BLOCKED = "BLOCKED",
  CC_LOCATION_ERROR = "CC_LOCATION_ERROR",
  CC_COOLDOWN = "CC_COOLDOWN",
  VERIFICATION_STATUS_COOLDOWN = "VERIFICATION_STATUS_COOLDOWN",
  INCOMPLETE_PROFILE = "INCOMPLETE_PROFILE",

  default = "DENIED",
}

// region Types
export type Permissions = Partial<Record<Permission, PermissionReason>>;

export type AuthState = {
  sessionId: string;
  userId: string;
  permissions: Permissions;
  token: string;
  firebase: {
    url: string;
    token: string;
    expires: string;
    uid: string;
  };
  loggedIn: boolean;
  expiresAt: number;
  lastUpdatedAt: number;
  nextUpdateAt: number;
  signingOut: boolean;
  updatingSession: boolean;
  source: AuthSource;
  zendeskMessengerToken: string;
  attributes: {
    eventFilters: string[];
  };
};

const initialState: AuthState = {
  sessionId: null,
  userId: null,
  permissions: {},
  token: null,
  firebase: {
    url: null,
    token: null,
    expires: null,
    uid: null,
  },
  loggedIn: false,
  expiresAt: null,
  lastUpdatedAt: null,
  nextUpdateAt: null,
  signingOut: false,
  updatingSession: false,
  source: AuthSource.DEFAULT,
  zendeskMessengerToken: null,
  attributes: {
    eventFilters: [],
  },
};
// endregion

// region Thunks
export const login = createAsyncThunk(
  "auth/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const userResponse = await UserAPI.login(email.trim(), password);
      dispatch(trackLogin({ email, outcome: "success" }));

      return userResponse;
    } catch (error) {
      if (error?.messageTemplate === "user.closed.nserMatch") {
        dispatch(setModal(BET_STOP_MODAL_ID_LOGOUT));
      }
      if (error?.messageTemplate === "waf-token-rejected") {
        const captchaResponse = await dispatch(
          verifyCaptcha(email.trim(), password),
        );

        if ((captchaResponse as any).message) {
          return rejectWithValue(captchaResponse);
        }
      }

      dispatch(trackLogin({ email, outcome: "failure" }));
      return rejectWithValue(error);
    }
  },
);

export const verifyCaptcha = (email: string, password: string) => {
  const WAF_API_KEY = process.env.GATSBY_WAF_API_KEY;

  return (
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => RootState,
  ) => {
    return new Promise((resolve) => {
      dispatch(setCaptchaPuzzle({ required: true, visible: false }));

      // allow the modal to render before injecting the puzzle
      setTimeout(() => {
        const AwsCaptcha = (window as any)?.AwsWafCaptcha;

        // https://docs.aws.amazon.com/waf/latest/developerguide/waf-js-captcha-api-render.html
        AwsCaptcha?.renderCaptcha(
          document.getElementById("captcha-container"),
          {
            apiKey: WAF_API_KEY,
            onLoad: () => {
              // ensure AWS has not called onLoad outside of the verifyCaptcha
              // login flow.
              if (getState()?.utilities.ui?.captcha.required) {
                dispatch(setCaptchaPuzzle({ required: true, visible: true }));
              }
            },
            onSuccess: async () => {
              dispatch(closeCaptchaPuzzle());
              resolve(dispatch(login({ email, password })));
              dispatch(setModal(null));
            },
            onPuzzleIncorrect: () => {
              resolve({
                messageTemplate: "captcha.puzzle.failed",
                message:
                  "The CAPTCHA was entered incorrectly. Please try again.",
              });
              dispatch(closeCaptchaPuzzle());
            },
          },
        );
      }, 300);
    });
  };
};

export const logout = createAsyncThunk(
  "auth/logout",
  async (callback: (() => void) | undefined, { getState, dispatch }) => {
    const { token } = (getState() as any)?.auth;

    // 1. revoke api token
    try {
      await UserAPI.revokeSession(token);
    } catch {
      // sometimes token is just expired
    }

    // 1.1 While we wait for permissions we set the auth source to Default
    dispatch(setAuthSource(AuthSource.DEFAULT));

    // 2. sign out firebase
    await UserAPI.logout();

    // 3. Get "guest" session
    const newPermissions = await dispatch(updateSession({ isGuest: true }));

    // 4. Perform callback
    callback && callback();

    return newPermissions;
  },
);

export const requestPasswordResetToken = createAsyncThunk(
  "auth/requestPasswordResetToken",
  async (
    { email, dateOfBirth }: { email: string; dateOfBirth: string },
    { rejectWithValue },
  ) => {
    try {
      return await UserAPI.requestPasswordReset(email, dateOfBirth);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "auth/requestPasswordResetToken",
  async (
    { code, password }: { code: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      return await UserAPI.resetPassword(code, { newPassword: password });
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateSession = createAsyncThunk(
  "auth/update",
  async (
    { isGuest }: { isGuest: boolean } = { isGuest: false },
    { getState, dispatch },
  ) => {
    const { token } = (getState() as any)?.auth;

    if (isGuest) {
      return await UserAPI.getSession(null);
    }

    try {
      return await UserAPI.getSession(token);
    } catch (error) {
      if (
        error?.errors?.some((it) => it.messageTemplate === "session.expired")
      ) {
        // session has expired
        dispatch(
          logout(() => {
            toast({
              description: "Your session has expired, please log in again.",
            });
          }),
        );
      }

      if (error?.errors?.some((it) => it.messageTemplate === "user.closed")) {
        dispatch(
          logout(() => {
            toast({
              description: "Your account has been closed.",
            });
          }),
        );
      }

      if (
        error?.errors?.some(
          (it) => it.messageTemplate === "user.closed.nserMatch",
        )
      ) {
        dispatch(setModal(BET_STOP_MODAL_ID_LOGOUT));
      }

      return { ...initialState };
    }
  },
);

export const signUp = createAsyncThunk(
  "auth/signup",
  async (
    {
      email,
      password,
      profile,
      deviceId,
    }: {
      email: string;
      password: string;
      profile: any;
      deviceId: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = (await UserAPI.signUp(
        email,
        password,
        profile,
        deviceId,
      )) as any;

      const now = new Date().getTime();
      dispatch(
        restoreSession({
          sessionId: response.id,
          userId: response.firebaseToken.uid,
          permissions: response.permissions,
          token: response.token,
          firebase: response.firebaseToken,
          loggedIn: true,
          expiresAt: parseISO(response.expiresAt).getTime(),
          lastUpdatedAt: now,
          nextUpdateAt: now + SESSION_CHECK_DURATION,
        }),
      );
      dispatch(trackSignUp({ email, ...profile }));
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const showLoginModal = () => (dispatch) => {
  dispatch(setModal("signIn"));
};

export const showProfileCompletionModal = () => (dispatch) => {
  dispatch(setModal(PROFILE_COMPLETION_MODAL));
};

// endregion

const [persistedState]: any = localObject.getValue("auth", {});
// region Slice
const authSlice = createSlice({
  name: "auth",
  initialState: persistedState
    ? { ...initialState, ...persistedState }
    : initialState,
  reducers: {
    restoreSession: (state: AuthState, action) => {
      return {
        ...state,
        ...action.payload,
        permissions: {
          ...state.permissions,
          ...action.payload.permissions,
        },
      };
    },
    setAuthSource: (state: AuthState, action: PayloadAction<AuthSource>) => {
      state.source = action.payload;
    },
    // tracking actions
    trackLogin: (state: AuthState, _: PayloadAction<any>) => state,
    trackSignUp: (state: AuthState, _: PayloadAction<any>) => state,
    trackAttemptedVerification: (state: AuthState, _: PayloadAction<any>) =>
      state,
    trackCompletedDetails: (state: AuthState) => state,
    trackCompletedAddress: (state: AuthState) => state,
    trackRequestToken: (state: AuthState, _: PayloadAction<any>) => state,
    trackResetPassword: (state: AuthState, _: PayloadAction<any>) => state,
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(login.fulfilled, (state: AuthState, action) => {
      const now = new Date().getTime();

      return {
        sessionId: action.payload.id,
        userId: action.payload.firebaseToken.uid,
        permissions: action.payload.permissions,
        token: action.payload.token,
        firebase: action.payload.firebaseToken,
        loggedIn: true,
        expiresAt: parseISO(action.payload.expiresAt).getTime(),
        lastUpdatedAt: now,
        nextUpdateAt: now + SESSION_CHECK_DURATION,
        source: AuthSource.API,
        zendeskMessengerToken:
          action.payload?.attributes?.zendeskMessengerToken,
        attributes: action.payload?.attributes,
      };
    });

    builder.addCase(logout.pending, (state: AuthState) => {
      state.signingOut = true;
    });

    builder.addCase(logout.fulfilled, (_, action) => {
      const { permissions = null, attributes = null } =
        action.payload.payload || {};

      return {
        ...initialState,
        ...(permissions ? { permissions } : {}),
        ...(attributes ? { attributes } : {}),
        source: AuthSource.API,
      };
    });

    builder.addCase(updateSession.pending, (state: AuthState) => {
      state.updatingSession = true;
    });

    builder.addCase(updateSession.fulfilled, (state, action) => {
      const now = new Date().getTime();

      return {
        ...state,
        sessionId: action.payload?.id,
        userId: action.payload?.firebaseToken?.uid,
        permissions: {
          ...state.permissions,
          ...action.payload?.permissions,
        },
        firebase: action.payload?.firebaseToken,
        loggedIn: !!action.payload?.userId,
        expiresAt: action.payload?.expiresAt
          ? parseISO(action.payload?.expiresAt).getTime()
          : null,
        lastUpdatedAt: now,
        nextUpdateAt: now + SESSION_CHECK_DURATION,
        updatingSession: false,
        source: AuthSource.API,
        zendeskMessengerToken:
          action.payload?.attributes?.zendeskMessengerToken,
        attributes: action.payload?.attributes || initialState.attributes,
      };
    });
  },
});
// endregion

// region Local Storage Middleware
export const persistAuth = createListenerMiddleware();

persistAuth.startListening({
  matcher: isAnyOf(
    login.fulfilled,
    updateSession.fulfilled,
    logout.fulfilled,
    authSlice.actions.restoreSession,
  ),
  effect: (action: AnyAction, { getState, dispatch }) => {
    // store session in a local storage
    localObject.setValue("auth", (getState() as any).auth);

    dispatch(
      checkSelectionsAreStillValid((getState() as any)?.auth?.permissions),
    );
  },
});

// endregion

export const {
  restoreSession,
  setAuthSource,
  trackSignUp,
  trackLogin,
  trackCompletedDetails,
  trackCompletedAddress,
  trackRequestToken,
  trackResetPassword,
  trackAttemptedVerification,
} = authSlice.actions;

export default authSlice.reducer as Reducer<AuthState, AnyAction>;
