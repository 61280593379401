import { usePageContext } from "hooks/usePageContext";
import { getCurrencyForRegion } from "common/regionCurrencies";
import { useProfile } from "hooks/firestore/v2/user/useProfile";

export const useDefaultCurrency = () => {
  const pageContext = usePageContext();
  return getCurrencyForRegion(pageContext?.regionCode);
};

const useFiatCurrency = () => {
  const { profile } = useProfile();
  const defaultCurrency = useDefaultCurrency();

  return profile?.fiatCurrency || defaultCurrency;
};

export { useFiatCurrency };
