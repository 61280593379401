import { useFirestore } from "hooks/firestore/v2/useFirestore";
import {
  notification,
  type Notification,
} from "hooks/firestore/v2//models/notification";
import { limit, orderBy } from "firebase/firestore";
import { useMemo } from "react";
import { PickemsTransforms } from "selectors";
import { getDefaultBettingPath } from "utilities/sharedBettingUtilities";
import { usePreferences } from "hooks/firestore/v2/user/usePreferences";

export const getLink = (
  objectType: Notification["externalReferenceType"],
  objectId: Notification["externalReferenceId"],
  type: Notification["type"],
  defaultHub: string,
): string => {
  if (objectType === "PICKEMS_CONTEST") {
    return PickemsTransforms.getPlayLink(objectId);
  }

  const bettingPath = getDefaultBettingPath(defaultHub);

  switch (type) {
    case "WELCOME_BETTING":
      return bettingPath;
    case "WELCOME_FANTASY":
    case "WELCOME":
      return "/pickems";
    case "VERIFICATION_SUCCESS":
      return "/wallet/deposit";
    case "VERIFICATION_REMINDER":
      return "/account/verification";
    case "DEPOSIT":
    case "ADMIN_DEPOSIT":
    case "DEPOSIT_FAILED":
    case "DEPOSIT_REFUND":
    case "WITHDRAWAL_PROCESSED":
    case "WITHDRAWAL_DECLINED":
    case "WITHDRAWAL_PARTIALLY_DECLINED":
    case "SKRILL_WITHDRAWAL_CANCELLED":
    case "PROMOTION_PAID":
      return `/wallet/statement`;
    case "PROMOTION_CLAIM_FAILED":
      return "/bonus-offers";
    case "BET_CANCELLED":
    case "BET_WON":
    case "PROMOTION_BET_STAKE_REFUND":
      return "/entries/historical";
    case "BET_ROLLBACK":
    case "BET_LEG_VOIDED":
    case "BET_LEG_UNVOIDED":
      return "/entries/live";
    case "VERIFY_EMAIL":
      return "/account/verification";
    case "DEPOSIT_LIMIT_REMINDER":
    case "DEPOSIT_LIMIT_RAISED":
    case "DEPOSIT_LIMIT_RAISE_REQUEST_RECEIVED":
      return "/preferences/responsible-gambling";
    case "EMAIL_UNSUBSCRIBED":
      return "/account/notifications";
    case "CREDIT_CARD_VERIFICATION_REQUIRED":
      return `/wallet/withdraw`;
    default:
      return bettingPath;
  }
};

const useNotifications = (page = 1, perPage: number) => {
  const { preferences } = usePreferences();

  const { data, isLoading } = useFirestore(
    `/users/{userId}/notifications`,
    notification.array(),
    {
      keyPath: "id",
      query: [orderBy("createdAt", "desc"), limit(perPage * page || perPage)],
    },
  );

  const notifications = useMemo(() => {
    if (!data) return [];

    return data.map((notification) => ({
      date: notification.createdAt.toDate(),
      externalType: notification.externalReferenceType,
      externalId: notification.externalReferenceId,
      id: notification.id,
      isRead: Boolean(notification.dismissed),
      link: getLink(
        notification.externalReferenceType,
        notification.externalReferenceId,
        notification.type,
        preferences?.defaultHub?.toLowerCase(),
      ),
      message: notification.message,
      type: notification.type,
    }));
  }, [data]);

  return {
    notifications,
    isLoading,
  };
};

const useNotificationsCount = () => {
  const { notifications } = useNotifications(1, 999);

  return notifications.filter(({ isRead }) => !isRead).length;
};

type Notifications = Exclude<
  ReturnType<typeof useNotifications>["notifications"],
  undefined
>;

export { useNotifications, type Notifications, useNotificationsCount };
