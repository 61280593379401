import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";

export const creditCard = z.object({
  id: z.string(),
  active: z.boolean(),
  cardType: z.enum(["CREDIT_CARD", "APPLE_PAY", "GOOGLE_PAY"]),
  createdAt: z.string().nullish(),
  expiryMonth: z.string(),
  expiryYear: z.string(),
  name: z.string(),
  numberFirst: z.string(),
  numberLast: z.string(),
  successfulDepositCount: z.number().optional(),
  userId: z.string(),
  lastSuccessfulDepositAt: z.string().optional(),
});

export type CreditCard = zInfer<typeof creditCard>;
