import React, { useState } from "react";
import { parse } from "query-string";
import { Step, Stepper } from "components/Stepper";
import { Details } from "./steps/Details";
import { Address } from "./steps/Address";
import { Identity } from "./steps/Identity";
import {
  signUp,
  trackCompletedAddress,
  trackCompletedDetails,
} from "utilities/Auth/authSlice";
import omit from "lodash/omit";
import { useDispatch } from "hooks";
import { setModal } from "utilities/UI/uiSlice";
import { Errors } from "./Errors";
import localObject from "utilities/localObject";
import { ATTRIBUTION_KEY, REFERRAL_KEY } from "appConfig";
import { BET_STOP_MODAL_ID_SIGNUP } from "./BetStopModalSignup";
import { getDeviceId } from "store/getDeviceId";

type Step = "Details" | "Address" | "Identity" | "Verification";

type SignUpFormProps = {
  onComplete?: () => void;
};

type DetailsFormValues = {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  password: string;
  dateOfBirth: string;
};

type AddressFormValues = {
  country: string;
  postCode: string;
  state: string;
  suburb: string;
  street: string;
  streetNumber: string;
};

export type StoredFormValues = {
  details: DetailsFormValues;
  address: AddressFormValues;
};

type SignUpError = {
  message: string;
  type: string;
} | null;

const SignUpForm = ({ onComplete }: SignUpFormProps) => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState<Step>("Details");
  const [storedFormValues, setStoredFormValues] = useState<StoredFormValues>({
    details: {
      email: "",
      firstName: "",
      lastName: "",
      middleName: "",
      password: "",
      dateOfBirth: "",
    },
    address: {
      country: "",
      postCode: "",
      state: "",
      suburb: "",
      street: "",
      streetNumber: "",
    },
  });

  const [signUpError, setSignUpError] = useState<SignUpError>(null);
  const [storedReferralCode] = localObject.getValue(REFERRAL_KEY);

  const promotionCode =
    typeof window === "undefined"
      ? undefined
      : storedReferralCode ?? (parse(location.search)["r"] as string);

  return (
    <Stepper activeStep={currentStep}>
      <Step name={"Details"}>
        <Details
          onComplete={(values: DetailsFormValues) => {
            setStoredFormValues({ ...storedFormValues, details: values });
            dispatch(trackCompletedDetails());
            setCurrentStep("Address");
          }}
        />
      </Step>
      <Step name={"Address"}>
        <Address
          onComplete={(values: AddressFormValues) => {
            setStoredFormValues({ ...storedFormValues, address: values });
            dispatch(trackCompletedAddress());
            setCurrentStep("Identity");
          }}
        />
      </Step>
      <Step name={"Identity"}>
        {signUpError && (
          <Errors type={signUpError.type} message={signUpError.message} />
        )}
        <Identity
          promotionCode={promotionCode}
          storedFormValues={storedFormValues}
          onComplete={async (identityFormValues) => {
            // Read attribution values if they exist
            let [attribution] = localObject.getValue<Record<string, any>>(
              ATTRIBUTION_KEY,
              {
                nominated_source: "unspecified",
              },
            );

            // Ensure attribution has default values if local storage is empty or lacks nominated_source
            attribution = {
              nominated_source: "unspecified",
              ...(attribution ?? {}),
            };

            // update with the defaults
            localObject.setValue(ATTRIBUTION_KEY, attribution);

            // Combine identity form with the rest of the form values
            const response = await dispatch(
              signUp({
                email: storedFormValues.details.email,
                password: storedFormValues.details.password,
                deviceId: getDeviceId(),
                profile: {
                  ...omit(storedFormValues.details, ["password", "email"]),
                  ...storedFormValues.address,
                  ...identityFormValues,
                  attribution,
                },
              }),
            );

            if (response.meta.requestStatus === "fulfilled") {
              if (onComplete) onComplete();
            } else if (response.meta.requestStatus === "rejected") {
              if (response.payload?.messageTemplate === "nserMatch") {
                dispatch(setModal(BET_STOP_MODAL_ID_SIGNUP));
                return;
              }

              setSignUpError({
                message: response.payload.message,
                type: response.payload.messageTemplate,
              });
            } else {
              setCurrentStep("Verification");
            }
          }}
        />
      </Step>
    </Stepper>
  );
};

export { SignUpForm };
