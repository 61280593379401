import React, { useCallback, useEffect, useMemo, useState } from "react";
import Icon from "../Icon";
import cx from "classnames";

import * as styles from "./HelpCenter.module.scss";
import {
  useDispatch,
  useIsMobile,
  useLocationParams,
  useSelector,
} from "hooks";
import {
  closeChat,
  setHelpCenterSearch,
  closeHelpCenter,
  openChat,
  openHelpCenter,
  setUnreadCount,
} from "utilities/HelpCenter/helpCenterSlice";

import { useDebounce, useInterval, useIsClient } from "usehooks-ts";
import { stringify } from "query-string";
import { navigate } from "../Link";
import BadgeCount from "../BadgeCount";
import { ReactComponent as SpeechBubble } from "assets/chat-speech.svg";
import { CreateATicket } from "./CreateATicket";
import { useLocation } from "hooks/useLocation";
import { isWebView } from "utilities/display";
import { PICKLEBET_DOMAINS } from "appConfig";
import {
  WEB_TO_APP_MESSAGE_TYPES,
  sendAppMessage,
} from "utilities/AppWebMessagingBridge";
import { Empty } from "library";
import { setModal } from "utilities/UI/uiSlice";
import { openWithChat } from "utilities/support";

const PROMOTIONAL_LABEL = "promotional";

const eventListenersMap = new WeakMap();

const cleanAndFormatHTML = (html: string) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const elements = doc.querySelectorAll("*");

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    element.removeAttribute("class");
    element.removeAttribute("style");
    element.removeAttribute("size");

    if (element.tagName === "A") {
      try {
        const domain = new URL(element.getAttribute("href"))?.hostname;
        if (domain !== "picklebet.com") {
          element.setAttribute("target", "_blank");
        }
      } catch (e) {
        console.warn(element.getAttribute("href"));
        console.warn(e);
        element.removeAttribute("href");
      }
    }
  }

  return doc.body.innerHTML;
};

const zendeskDomain = process.env.GATSBY_ZENDESK;
const zendeskHelpDomains = ["picklebettest.zendesk.com", "help.picklebet.com"];

const ZENDESK_SEARCH_URL = `https://${zendeskDomain}/api/v2/help_center/articles/embeddable_search.json`;
const ZENDESK_ARTICLE_URL = `https://${zendeskDomain}/hc/en-us/articles/`;
const ZENDESK_ARTICLE_API_URL = `https://${zendeskDomain}/api/v2/help_center/en-us/articles/`;
const ZENDESK_ARTICLE_API_URL_SUFFIX = ".json";

const replacePicklebetDomain = (url: string) =>
  PICKLEBET_DOMAINS.reduce((acc, domain) => acc.replace(domain, ""), url);

export type ZendeskSearchResultItem = {
  author_id: number;
  body: string;
  created_at: string;
  draft: boolean;
  html_url: string;
  id: number;
  label_names: string[];
  promoted: boolean;
  position: number;
  source_locale: string;
  title: string;
  updated_at: string;
  url: string;
  vote_sum: number;
  vote_count: number;
  rank: number;
};

export const HelpCenter = () => {
  const isMobile = useIsMobile();
  const [results, setResults] = useState<ZendeskSearchResultItem[]>([]);
  const [createATicketOpen, setCreateATicketOpen] = useState<boolean>(false);
  const [zendeskHasLoaded, setZendeskHasLoaded] = useState<boolean>(false);
  const location = useLocation();

  const zendeskToken = useSelector(
    (state) => state?.auth?.zendeskMessengerToken,
  );

  const betslipOpen = useSelector(
    (state) => state?.utilities.ui.menu === "right",
  );

  const [selectedResult, setSelectedResult] =
    useState<ZendeskSearchResultItem | null>();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const { chatOpen, labels, search, visible, articleId } = useSelector(
    (state) => state.helpCenter,
  );

  const unreadCount = useSelector((state) => state?.helpCenter?.unreadCount);

  const debouncedSearch = useDebounce(search, 500);

  const isClient = useIsClient();

  useInterval(
    () => {
      if (!isClient) return;
      if (window.zE && !zendeskHasLoaded) {
        setZendeskHasLoaded(true);
      }
    },
    zendeskHasLoaded ? null : 1000,
  );

  const livechat = useLocationParams("livechat");

  useEffect(() => {
    if (zendeskHasLoaded && livechat === "true") {
      if (!chatOpen) {
        openWithChat();
      }
      // remove livechat but retain the other query params
      const url = new URL(`${location.href}`);
      url.searchParams.delete("livechat");
      navigate(decodeURI(`${url.pathname}${url.search}`), false, {
        replace: true,
      });
    }
  }, [zendeskHasLoaded, livechat]);

  useEffect(() => {
    setLoading(true);
  }, [search]);

  const addEventListenerOnce = (
    element: Document,
    eventType: string,
    handler: (e: any) => boolean,
  ) => {
    let listeners = eventListenersMap.get(element);

    if (!listeners) {
      listeners = {};
      eventListenersMap.set(element, listeners);
    }

    if (listeners[eventType]) {
      return;
    }

    element.addEventListener(eventType, handler);
    listeners[eventType] = handler;
  };

  const attachToChatClickEvents = () => {
    const messagingWindow = window.document.querySelector(
      "[title='Messaging window']",
    ) as HTMLIFrameElement;

    if (messagingWindow && messagingWindow.contentDocument) {
      addEventListenerOnce(
        messagingWindow.contentDocument,
        "click",
        handleClick,
      );
    } else {
      setTimeout(() => {
        attachToChatClickEvents();
      }, 500);
    }
  };

  useEffect(() => {
    if (!zendeskHasLoaded) return;

    if (chatOpen) {
      attachToChatClickEvents();
    }
  }, [zendeskToken, chatOpen]);

  const filteredResults = useMemo(() => {
    if (zendeskToken) return results;
    return results.filter((result) => {
      const resultLabels = result.label_names;
      return resultLabels.every((label) => PROMOTIONAL_LABEL !== label);
    });
  }, [results, zendeskToken]);

  const doSearch = async () => {
    try {
      if (search !== "" || labels.length > 0) {
        const query = {
          query: search !== "" ? search : undefined,
          locale: "en-us",
          per_page: "20",
          origin: "web_widget",
          label_names: labels.length > 0 ? labels : undefined,
        };

        if (search === "") {
          delete query.query;
        }

        if (labels.length === 0) {
          delete query.label_names;
        }

        const response = await fetch(
          `${ZENDESK_SEARCH_URL}?${stringify(query, { arrayFormat: "comma" })}`,
          {
            method: "GET",
          },
        );

        const body = await response.json();

        if (body.results.length > 0) {
          setResults(body.results);
          setSelectedResult(null);
        } else {
          setResults([]);
          setSelectedResult(null);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getHelpCenterArticleHtml = async (url: string) => {
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
      });

      const body = await response.json();

      if (body.article) {
        setSelectedResult(body.article);
        dispatch(openHelpCenter());
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const openLinkExternallyOfTheApps = (href: string) => {
    sendAppMessage(WEB_TO_APP_MESSAGE_TYPES.APP_MESSAGE_OPEN_EXTERNALLY, {
      url: href,
    });
  };

  const handleClick = useCallback(
    (e: MouseEvent & any) => {
      const origin = e.target?.closest(`a`) || e.target.closest("button");

      const href = origin?.getAttribute("href");

      if (!origin || !href) {
        return true;
      }

      const openBlank = origin?.getAttribute("target") === "_blank";

      if (href.includes(".pdf")) {
        // Short circuit for .pdfs in Android.tsx and iOS.tsx (Apps).
        if (isWebView()) {
          openLinkExternallyOfTheApps(href);
          return false;
        }

        return false;
      } else if (href.includes("help-center")) {
        dispatch(openHelpCenter());
        e.preventDefault();
        e.stopPropagation();

        return false;
      } else if (href.includes("live-chat")) {
        dispatch(openChat());
        e.preventDefault();
        e.stopPropagation();

        return false;
      } else if (href.includes("submit-a-request")) {
        setCreateATicketOpen(true);
        dispatch(closeChat());
        dispatch(openHelpCenter());
        e.preventDefault();
        e.stopPropagation();

        return false;
      } else if (
        PICKLEBET_DOMAINS.some((domain) => href.includes(domain)) &&
        !openBlank
      ) {
        e.preventDefault();
        e.stopPropagation();
        navigate(replacePicklebetDomain(href));
        if (isMobile) {
          dispatch(closeChat());
          dispatch(closeHelpCenter());
        }
        return false;
      } else if (zendeskHelpDomains.some((domain) => href.includes(domain))) {
        e.preventDefault();
        e.stopPropagation();
        getHelpCenterArticleHtml(
          `${href.replace(
            ZENDESK_ARTICLE_URL,
            ZENDESK_ARTICLE_API_URL,
          )}${ZENDESK_ARTICLE_API_URL_SUFFIX}`,
        );
        dispatch(closeChat());
        dispatch(openHelpCenter());
        return false;
      }
      return true;
    },
    [
      dispatch,
      navigate,
      getHelpCenterArticleHtml,
      openChat,
      openHelpCenter,
      closeHelpCenter,
    ],
  );

  useEffect(() => {
    if (!zendeskHasLoaded) return;
    window.document.addEventListener("click", handleClick);

    return () => window.document.removeEventListener("click", handleClick);
  }, [zendeskHasLoaded, chatOpen, selectedResult, visible, location]);

  useEffect(() => {
    doSearch();
  }, [debouncedSearch]);

  useEffect(() => {
    if (!zendeskHasLoaded) return;
    zE("messenger:on", "close", () => {
      dispatch(closeChat());
      if (!selectedResult) {
        dispatch(closeHelpCenter());
      }
    });

    zE("messenger:on", "unreadMessages", (count: number) => {
      dispatch(setUnreadCount(count));
    });
  }, [zendeskHasLoaded]);

  useEffect(() => {
    if (!zendeskHasLoaded) return;
    try {
      if (zendeskToken) {
        zE("messenger", "loginUser", (callback) => {
          callback(zendeskToken);
        });
      } else if (!zendeskToken) {
        zE("messenger", "logoutUser");
      }
    } catch (e) {
      console.error(e);
    }
  }, [zendeskToken, zendeskHasLoaded]);

  useEffect(() => {
    if (!zendeskHasLoaded) return;
    if (chatOpen) {
      zE("messenger", "open");
    }
    if (!chatOpen) {
      zE("messenger", "close");
    }
  }, [chatOpen, zendeskToken, zendeskHasLoaded]);

  useEffect(() => {
    if (labels.length > 0) {
      doSearch();
    }
  }, [labels]);

  useEffect(() => {
    if (articleId) {
      getHelpCenterArticleHtml(
        `${ZENDESK_ARTICLE_API_URL}${articleId}${ZENDESK_ARTICLE_API_URL_SUFFIX}`,
      );
    }
  }, [articleId]);

  const showPromotionalArticle =
    zendeskToken || !selectedResult?.label_names?.includes(PROMOTIONAL_LABEL);

  const handleSearch = (e: any) => {
    dispatch(setHelpCenterSearch(e.target.value));
  };

  if (!zendeskDomain) {
    console.log("Zendesk is disabled");
    return null;
  }

  return (
    <>
      {createATicketOpen ? (
        <CreateATicket
          visible={createATicketOpen}
          setVisible={setCreateATicketOpen}
        />
      ) : (
        <div className={cx(styles.helpCenter, { [styles.visible]: visible })}>
          {loading && <span className={styles.loader}></span>}
          <div className={styles.header}>
            {selectedResult && (
              <div className={styles.title}>
                <div
                  className={styles.titleLeft}
                  onClick={() => setSelectedResult(null)}
                >
                  <div className={styles.backIcon}>
                    <Icon type="arrow-left" />
                  </div>
                  <div className={cx(styles.titleText, styles.back)}>Back</div>
                </div>
                <div className={styles.titleRight}>
                  {selectedResult.html_url && (
                    <div
                      className={cx(styles.icon, styles.openInNewIcon)}
                      onClick={() => {
                        if (typeof window === "undefined") return;

                        try {
                          window.open(selectedResult.html_url, "_blank");
                        } catch (e: any) {
                          console.error(
                            "Couldn't open article:",
                            e.message ?? "An unexpected error occurred",
                          );
                        }
                      }}
                    >
                      <Icon type="openInNew" />
                    </div>
                  )}
                  <div
                    className={styles.icon}
                    onClick={() => dispatch(closeHelpCenter())}
                  >
                    <Icon type="close" />
                  </div>
                </div>
              </div>
            )}
            {!selectedResult && (
              <>
                <div
                  className={styles.title}
                  onClick={() => dispatch(closeHelpCenter())}
                >
                  <div className={styles.titleText}>Picklebet Help Center</div>
                  <div className={styles.icon}>
                    <Icon type="close" />
                  </div>
                </div>
                <div className={styles.search}>
                  <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Search"
                    onChange={handleSearch}
                    value={search}
                  />
                </div>
              </>
            )}
          </div>
          {selectedResult && (
            <>
              <div className={styles.content}>
                <div className={styles.resultsTitle}>
                  {showPromotionalArticle ? selectedResult.title : ""}
                </div>
                {showPromotionalArticle ? (
                  <div
                    className={styles.result}
                    dangerouslySetInnerHTML={{
                      __html: cleanAndFormatHTML(selectedResult.body),
                    }}
                  ></div>
                ) : (
                  <Empty
                    className={styles.empty}
                    icon={<Icon type="promos" />}
                    message={
                      "Log in to see our latest members only Promotion offers."
                    }
                    buttonText={"Log In"}
                    buttonClick={() => dispatch(setModal("signIn"))}
                  />
                )}
              </div>
            </>
          )}

          {!selectedResult && (
            <div className={styles.content}>
              {filteredResults.length > 0 ? (
                <>
                  <div className={styles.resultsTitle}>Top Suggestions</div>
                  <div className={styles.results}>
                    {results.map((result) => (
                      <div
                        key={result.id}
                        className={styles.result}
                        onClick={() => getHelpCenterArticleHtml(result.url)}
                      >
                        <div>{result.title}</div>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div>
                  {search !== "" && !loading && (
                    <>
                      <div className={styles.resultsTitle}>No Results</div>
                      <div className={styles.result}>
                        We couldn't find any results for your search. <br /> Try
                        searching for something else.
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          <div className={styles.footer} onClick={() => dispatch(openChat())}>
            <div className={styles.liveChat}>Live Chat &nbsp; 💬 </div>
          </div>
        </div>
      )}
      {unreadCount > 0 && !betslipOpen && (
        <div
          className={styles.unreadMessages}
          onClick={() => dispatch(openChat())}
        >
          <SpeechBubble />{" "}
          <BadgeCount count={unreadCount} className={styles.badge} />
        </div>
      )}
    </>
  );
};
