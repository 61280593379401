import { useLocation } from "@gatsbyjs/reach-router";
import queryString from "query-string";
import type { Nullable } from "types/utilities";

export const useLocationParams = (paramName: Nullable<string> = null) => {
  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);

  if (paramName && parsedSearch) {
    return parsedSearch[paramName];
  }

  return parsedSearch;
};

export default useLocationParams;
