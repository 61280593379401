import React, { useState, useEffect } from "react";
import { Button } from "components/Button";
import { toast, useBetslip, useDispatch, useIsLoggedIn } from "hooks";
import { BetslipUIStates } from "sections/Betting/Betslip/betslipSlice";
import { Options } from "./Options";
import { SlideOut } from "../SlideOut";
import { ReactComponent as ChevronDown } from "assets/icons/arrow-down-1.svg";
import { ReactComponent as CheckCircle } from "assets/icons/check-circle-1.svg";

import {
  type AcceptOddsPreferences,
  usePreferences,
} from "hooks/firestore/v2/user/usePreferences";
import { onUpdateBettingPreferences } from "sections/Account/accountSlice";
import { showLoginModal } from "utilities/Auth/authSlice";

import * as styles from "./QuickPreferences.module.scss";
import { AnimatePresence, motion } from "framer-motion";

const AnimateIn = ({
  children,
  delay = 0,
}: { children: React.ReactNode; delay?: number }) => {
  const initialStyle = {
    opacity: 0,
    transform: "translate3d(100%, 0, 0)",
  };
  return (
    <motion.div
      initial={initialStyle}
      animate={{
        ...initialStyle,
        opacity: 1,
        transform: "translate3d(0, 0, 0)",
      }}
      exit={initialStyle}
      transition={{ duration: 0.1, delay }}
    >
      {children}
    </motion.div>
  );
};

const AnimatePop = ({
  children,
  delay = 0,
}: { children: React.ReactNode; delay?: number }) => {
  const initialStyle = {
    opacity: 0,
    scale: 0,
  };
  return (
    <motion.div
      className={styles.flex}
      initial={initialStyle}
      animate={{
        ...initialStyle,
        opacity: 1,
        scale: [0, 1.3, 1],
      }}
      exit={initialStyle}
      transition={{ duration: 0.3, delay }}
    >
      {children}
    </motion.div>
  );
};

const QuickPreferences = () => {
  const dispatch = useDispatch();

  const {
    props: { betslipIsActive },
    actions: { setBetSlipState, handleAcceptBulkOddsUpdate },
  } = useBetslip();

  const { preferences } = usePreferences();
  const isLoggedIn = useIsLoggedIn();

  const [selectedOption, setSelectedOption] = useState<AcceptOddsPreferences>(
    preferences.acceptOdds,
  );

  const [saveComplete, setSaveComplete] = useState(false);

  const availableSettingsOptions: AcceptOddsPreferences[] = [
    "AUTO",
    "AUTO_HIGHER",
    "MANUAL",
  ];

  const getOptionLabel = (option: AcceptOddsPreferences) => {
    switch (option) {
      case "MANUAL":
        return "Manually accept all odds changes";
      case "AUTO":
        return "Auto accept all odds changes";
      case "AUTO_HIGHER":
      default:
        return "Auto accept higher odds changes";
    }
  };

  const availableSettingsOptionsObject = availableSettingsOptions.map(
    (option) => ({
      value: option,
      display: (
        <div className={styles.optionLabel}>{getOptionLabel(option)}</div>
      ),
    }),
  );

  const handleClose = () => {
    dispatch(setBetSlipState(BetslipUIStates.active));
  };

  const isProcessing = false;

  const updateButtonVariant = !isLoggedIn ? "primary" : "info";
  const updateButtonText = !isLoggedIn
    ? "Log In To Update"
    : "Update Betting Preferences";

  const handleUpdateButtonClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (saveComplete) {
      return;
    }

    if (!isLoggedIn) {
      dispatch(showLoginModal());
      return;
    }

    const [response, error] = await dispatch(
      onUpdateBettingPreferences({
        acceptOdds: selectedOption,
        oddsFormat: null,
        defaultHub: null,
        allowCancelWithdrawal: null,
        withToast: false,
      }),
    );

    if (error) {
      toast({
        title: "Error!",
        description: "Unable to update preferences",
        variant: "danger",
      });
      return;
    } else if (response?.status === "SUCCESS") {
      // change the button to tick
      setSaveComplete(true);
    }
  };

  useEffect(() => {
    if (!saveComplete) {
      return;
    }

    // wait 2 seconds, auto close
    setTimeout(() => {
      if (betslipIsActive) {
        return;
      }

      dispatch(setBetSlipState(BetslipUIStates.active));

      // note: "Auto" + update settings = All odds changes in the betslip will be accepted
      //       "Auto higher" + update settings = All odds changes with higher odds will be accepted
      //       "manual" + update settings = no odds changes will be accepted, must click the accept button
      if (selectedOption !== "MANUAL") {
        dispatch(
          handleAcceptBulkOddsUpdate({
            acceptVariant:
              selectedOption === "AUTO_HIGHER" ? "HIGHER_ONLY" : "ALL",
          }),
        );
      }
    }, 800);
  }, [saveComplete, betslipIsActive]);

  return (
    <SlideOut onClose={handleClose}>
      <div className={styles.title}>Update Preferences</div>
      <Options
        options={availableSettingsOptionsObject}
        setSelectedOption={setSelectedOption}
        selectedOption={
          availableSettingsOptions.includes(selectedOption)
            ? selectedOption
            : availableSettingsOptions[0]
        }
      />
      <div className={styles.footer}>
        <Button
          onClick={handleClose}
          variant={`blurred`}
          className={styles.iconButton}
        >
          <ChevronDown />
        </Button>

        <Button
          loading={isProcessing}
          variant={saveComplete ? "primary" : updateButtonVariant}
          className={styles.button}
          onClick={handleUpdateButtonClick}
        >
          <>
            <AnimatePresence mode="wait">
              {saveComplete ? (
                <AnimateIn key="saved">
                  <div className={styles.savedButton}>
                    <div>Saved</div>
                    <div>
                      <AnimatePop delay={0.05}>
                        <CheckCircle width="2rem" height="2rem" />
                      </AnimatePop>
                    </div>
                  </div>
                </AnimateIn>
              ) : (
                <AnimateIn key="buttonText">{updateButtonText}</AnimateIn>
              )}
            </AnimatePresence>
          </>
        </Button>
      </div>
    </SlideOut>
  );
};

export { QuickPreferences };
