import React from "react";
import { Alert } from "components/Alert";
import { SupportLink } from "components/SupportLink";
import type { NullableMaybe } from "types/utilities";

export default ({
  className,
  indicator = "top",
}: {
  className?: string;
  indicator?: NullableMaybe<"top" | "bottom">;
}) => {
  return (
    <Alert
      className={className}
      variant="info"
      indicator={indicator}
      size="small"
    >
      <span>
        SP - indicates a bet can be placed at{" "}
        <SupportLink options={{ articleId: "5351471094799" }} hideChevron>
          Starting Price
        </SupportLink>
        , the final fixed price available at race close
      </span>
    </Alert>
  );
};
