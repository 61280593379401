import { z } from "zod";
import type { infer as zInfer } from "zod/lib/types";

export const depositLimit = z.object({
  dailyLimitDays: z.number(),
  maximumDailyDepositAmount: z.number(),
  minimumDepositAmount: z.number(),
  remainingDailyAmount: z.number(),
  pendingUpdateAt: z.string().nullish(),
  pendingUpdateDailyLimitAmount: z.number().nullish(),
  pendingUpdateDailyLimitDays: z.number().nullish(),
  expiresAt: z.string().nullish(),
  currency: z.string(),
});

export type DepositLimits = zInfer<typeof depositLimit>;
